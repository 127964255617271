import React, { Component, useEffect, useState } from 'react';
import { Route ,  Switch } from 'react-router-dom';
import io from "socket.io-client";
import { useHistory } from 'react-router-dom'
// STYLE
import './App.css';
import axios from 'axios'
import SERVER from './endPoint'

//Screen
import LoginPage from './screens/login'
import Home from './screens/Home'
import MainLandingPage from './screens/MainLandingPage'
import LandingPageKabupaten from './screens/LandingPageKabupaten'
import Diagnosa from './screens/Diagnosa'
import DiagnosaTanpaStatus from './screens/Diagnosa/tanpaStatus'
import DiagnosaDenganStatus from './screens/Diagnosa/denganStatus'
import LandingPageKecamatan from './screens/LandingPageKecamatan'
import LandingPageDesa from './screens/LandingPageDesa'
import Maintenance from './screens/Maintenance'
import LandingPageBerita from './screens/LandingPageBerita'
import DetailBerita from './screens/DetailBerita'
import DaftarPertanyaan from './screens/DaftarPertanyaan'

function App () {
  const history = useHistory()

  useEffect(()=>{
    axios({
      method : 'GET',
      url : `${SERVER}/user/setting`,
      headers : {
        token : localStorage.getItem('token_access')
      },
    })
    .then(({data})=>{
      console.log(data , ' <<< VALUE DATA loo')
      if(!data){
        history.replace('/landing-page-kabupaten')
      }else if (data.role === 'patient') {
        history.replace('/landing-page-kabupaten')
      }
    })
    .catch(console.log)
  },[])

  // useEffect(()=>{
  //   console.clear()
  // })

  return (
    <div className="App" >
      <Switch>
        {/* <Route path="/maintenance" exact component={Maintenance}/> */}
        <Route path="/login" component={LoginPage} />
        <Route path="/landing-page-utama" exact component={MainLandingPage}/>
        <Route path="/landing-page-kabupaten/diagnosa-mandiri/dengan-status" component={DiagnosaDenganStatus}/>
        <Route path="/landing-page-kabupaten/diagnosa-mandiri/tanpa-status" component={DiagnosaTanpaStatus}/>
        <Route path="/landing-page-kabupaten/diagnosa-mandiri" component={Diagnosa}/>
        <Route path="/landing-page-kabupaten" component={LandingPageKabupaten}/>
        <Route path="/landing-page-kecamatan" component={LandingPageKecamatan}/>
        <Route path="/landing-page-desa/:id" component={LandingPageDesa}/>
        <Route path="/landing-page-berita" component={LandingPageBerita} />
        <Route path="/daftar-pertanyaan" component={DaftarPertanyaan} />
        <Route path="/detail-berita/:id" component={DetailBerita}/>
        <Route path="/" component={Home} />
      </Switch>
    </div>

  );
}


export default App;
