import React, { useEffect } from 'react'
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import SERVER from '../../../endPoint'
import axios from 'axios'
import swal from 'sweetalert2'

function SecondContent(props) {

    const {
        kecamatanList,
        desaList,
        dataCount,
        dataHariIni,
        dataTotal,
        kecamatanToday,
        setRefreshDesaUpdate
    } = props

    const current = window
    console.log(current.innerWidth, ' <<< INNERWIDTH')
    console.log(current.outerWidth, ' <<< OUTER WIDTH')

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    function ExcelDateToJSDate(serial) {
        var utc_days  = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;                                        
        var date_info = new Date(utc_value * 1000);
        var fractional_day = serial - Math.floor(serial) + 0.0000001;
        var total_seconds = Math.floor(86400 * fractional_day);
        var seconds = total_seconds % 60;
        total_seconds -= seconds;
        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;
        return showDate(new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds))
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];

        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                console.log(resp,'resp')
                let excelData = []
                let temp = {}
                resp.rows.forEach((data,index)=>{
                    if(index !== 0) {
                        temp.no = data[0]
                        temp.namadiKtp = data[1]
                        temp.namaPanggilan = data[1]
                        temp.status = data[5]
                        temp.subStatus = data[6]
                        temp.kecamatan = data[2]
                        temp.kelurahan = 'Desa '+data[2]
                        temp.propinsi = "Sulawesi Tengah"
                        temp.kabupaten = 'Kabupaten Banggai'
                        temp.jenisKelamin = data[3]
                        temp.tanggal = ExcelDateToJSDate(data[4])
                        excelData.push(temp)
                        temp={}
                    }
                })
                const swalWithBootstrapButtons = swal.mixin({
                    customClass: {
                        confirmButton: 'btn-style-angga-confirm-008',
                        cancelButton: 'btn-style-angga-cancel'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    html: `Yakin Menyimpan Data Ini ?`,
                    icon: 'warning',
                    confirmButtonText: 'Yakin',
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.value) {
                        axios({
                            method : "POST",
                            url : `${SERVER}/patient/excel`,
                            data : {
                                data : excelData.sort((a,b) => (a.no < b.no) ? 1 : ((b.no < a.no) ? -1 : 0))
                            }
                        })
                        .then(({data})=>{
                            swal.close()
                            swal.fire({
                                text: 'Data Berhasil Disimpan!',
                                icon: 'success',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#0280A2',
                            })
                            .then((result) => {
                                if (result.value) {
                                    // history.push('/view-berita')
                                    setRefreshDesaUpdate(true)
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                        })
                        .catch(console.log)
                    } else if ( result.dismiss === swal.DismissReason.cancel ) {
                    }
                })

                // this.setState({
                //   cols: resp.cols,
                //   rows: resp.rows
                // });
            }
        });

    }

    useEffect(() => {
        console.clear()
        let data = document.getElementById('parent-content-sidebar')
        console.log(data, '<<<< data')
        if (data) {
            console.log(data.style.width, ' <<< OFFSET WIDTH')
        }
        // console.clear()
    }, [])


    return (
        <div
            className="bc-triple-content-597"
            style={
                {
                    marginTop: "26px",
                    width: (current.innerWidth - 296) + "px"
                }
            }
        >

            <div className="bc-triple-per-content-597" style={{ backgroundColor: "#E8C407" }}>

                <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                    {/* <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Hari Ini
                        </div>

                        <div className="bc-majority-title2-597">
                        {
                            dataHariIni && dataHariIni.odp &&
                            dataHariIni.odp.dipantau +
                            dataHariIni.odp.meninggal +
                            dataHariIni.odp.naikPDP +
                            dataHariIni.odp.naikPositif +
                            dataHariIni.odp.sembuh
                        }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#F1E398"}}>

                    </div> */}

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597" >
                            Sedang Dipantau
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odp && dataCount.odp.dipantau}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.dipantau !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.dipantau
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#F1E398" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik PDP
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odp && dataCount.odp.naikPDP}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.naikPDP !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.naikPDP
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#F1E398" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik Positif
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odp && dataCount.odp.naikPositif}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.naikPositif !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.naikPositif
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#F1E398" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Sembuh
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odp && dataCount.odp.sembuh}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.sembuh !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.sembuh
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#F1E398" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Meninggal
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odp && dataCount.odp.meninggal}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.meninggal !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.meninggal
                                    }
                                </span>
                            }
                        </div>
                    </div>

                </div>

                <div className="bct-second-content-597 border-top-lpk-type-3-867" >
                    <div className="bx-span-tgt">
                        <span className="bct-sc-title1-597">
                            {"Total "}
                            {dataCount && dataCount.odp &&
                                dataCount.odp.dipantau +
                                dataCount.odp.meninggal +
                                dataCount.odp.naikPDP +
                                dataCount.odp.naikPositif +
                                dataCount.odp.sembuh
                            }
                        </span>
                        <div className="txt-btnla-141"
                            style={{
                                marginTop: '15px',
                                marginBottom: '0px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                kecamatanToday && kecamatanToday.odp &&
                                kecamatanToday.odp.dipantau +
                                kecamatanToday.odp.meninggal +
                                kecamatanToday.odp.naikPDP +
                                kecamatanToday.odp.naikPositif +
                                kecamatanToday.odp.sembuh !== 0 &&
                                <span className="txt-131-878odp-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odp &&
                                        kecamatanToday.odp.dipantau +
                                        kecamatanToday.odp.meninggal +
                                        kecamatanToday.odp.naikPDP +
                                        kecamatanToday.odp.naikPositif +
                                        kecamatanToday.odp.sembuh
                                    }
                                </span>
                            }
                        </div>
                    </div>
                    <div className="bct-sc-title2-597">
                        Orang Dalam Pemantauan (ODP)
                    </div>
                </div>

            </div>

            <div className="bc-triple-per-content-597" style={{ backgroundColor: "#008D1F" }}>

                <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                    {/* <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Hari Ini
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataHariIni && dataHariIni.otg &&
                                dataHariIni.otg.dipantau +
                                dataHariIni.otg.naikODP +
                                dataHariIni.otg.naikPDP +
                                dataHariIni.otg.naikPositif +
                                dataHariIni.otg.selesai
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#71CE91"}}>

                    </div> */}

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik ODP
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.otg && dataCount.otg.naikODP
                            }
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.naikODP !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.naikODP
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik PDP
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.otg && dataCount.otg.naikPDP
                            }
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.naikPDP !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.naikPDP
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik Positif
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.otg && dataCount.otg.naikPositif
                            }
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.naikPositif !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.naikPositif
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Sedang Dipantau
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.otg && dataCount.otg.dipantau}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.dipantau !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.dipantau
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Selesai
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.otg && dataCount.otg.selesai}
                        </div>
                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.selesai !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.selesai
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    {/* <div className="bc-majority-hr-597" style={{backgroundColor : "#71CE91"}}> */}

                    {/* </div> */}


                </div>

                <div className="bct-second-content-597 border-top-lpk-type-4-867" >
                    <div className="bx-span-tgt">
                        <span className="bct-sc-title1-597">
                            {"Total "}
                            {
                                dataCount && dataCount.otg &&
                                dataCount.otg.dipantau +
                                dataCount.otg.naikODP +
                                dataCount.otg.naikPDP +
                                dataCount.otg.naikPositif +
                                dataCount.otg.selesai
                            }
                        </span>
                        <div className="txt-btnla-141"
                            style={{
                                marginTop: '15px',
                                marginBottom: '0px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                kecamatanToday && kecamatanToday.otg &&
                                kecamatanToday.otg.dipantau +
                                kecamatanToday.otg.naikODP +
                                kecamatanToday.otg.naikPDP +
                                kecamatanToday.otg.naikPositif +
                                kecamatanToday.otg.selesai !== 0 &&
                                <span className="txt-131-878otg-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.otg &&
                                        kecamatanToday.otg.dipantau +
                                        kecamatanToday.otg.naikODP +
                                        kecamatanToday.otg.naikPDP +
                                        kecamatanToday.otg.naikPositif +
                                        kecamatanToday.otg.selesai
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className='bct-wrapper-us'>
                        <div className="bct-sc-title2-597">
                            Orang Tanpa Gejala (OTG)
                        </div>
                        <div class="upload-btn-wrapper">
                            <button class="bct-ncf-button-597" >Upload</button>
                            <input type="file" onChange={fileHandler.bind(this)}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="bc-triple-per-content-597" style={{ backgroundColor: "#00BE41" }}>

                <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                    {/* <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Hari Ini
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataHariIni && dataHariIni.odr &&
                                dataHariIni.odr.dipantau +
                                dataHariIni.odr.naikODP +
                                dataHariIni.odr.naikPDP +
                                dataHariIni.odr.naikPositif +
                                dataHariIni.odr.selesai
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#71CE91"}}>

                    </div> */}


                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik ODP
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odr && dataCount.odr.naikODP}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.naikODP !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.naikODP
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik PDP
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odr && dataCount.odr.naikPDP}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.naikPDP !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.naikPDP
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Naik Positif
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odr && dataCount.odr.naikPositif}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.naikPositif !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.naikPositif
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}></div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Sedang Dipantau
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odr && dataCount.odr.dipantau}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.dipantau !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.dipantau
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{ backgroundColor: "#71CE91" }}></div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Selesai
                        </div>

                        <div className="bc-majority-title2-597">
                            {dataCount && dataCount.odr && dataCount.odr.selesai}
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.selesai !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.selesai
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    {/* <div className="bc-majority-hr-597" style={{backgroundColor : "#71CE91"}}></div> */}


                </div>

                <div className="bct-second-content-597 border-top-lpk-type-5-867" >
                    <div className="bx-span-tgt">
                        <span className="bct-sc-title1-597">
                            {"Total "}
                            {
                                dataCount && dataCount.odr &&
                                dataCount.odr.dipantau +
                                dataCount.odr.naikODP +
                                dataCount.odr.naikPDP +
                                dataCount.odr.naikPositif +
                                dataCount.odr.selesai
                            }
                        </span>
                        <div className="txt-btnla-141"
                            style={{
                                marginTop: '15px',
                                marginBottom: '0px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                kecamatanToday && kecamatanToday.odr &&
                                kecamatanToday.odr.dipantau +
                                kecamatanToday.odr.naikODP +
                                kecamatanToday.odr.naikPDP +
                                kecamatanToday.odr.naikPositif +
                                kecamatanToday.odr.selesai !== 0 &&
                                <span className="txt-131-878odr-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.odr &&
                                        kecamatanToday.odr.dipantau +
                                        kecamatanToday.odr.naikODP +
                                        kecamatanToday.odr.naikPDP +
                                        kecamatanToday.odr.naikPositif +
                                        kecamatanToday.odr.selesai
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className='bct-wrapper-us'>
                        <div className="bct-sc-title2-597">
                            Pelaku Perjalanan (ODR)
                        </div>
                        <div class="upload-btn-wrapper">
                            <button class="bct-ncf-button-597">Upload</button>
                            <input type="file" onChange={fileHandler.bind(this)}/>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}

export default SecondContent