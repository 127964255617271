import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert2'
import axios from 'axios'
import { Button } from '@material-ui/core';

// COMPONENT
import Navbar from '../../components/NavbarMobile'
import Header from '../LandingPageKabupaten/Header'
import FooterContent from '../../components/FooterContent1'

// STYLE
import './style.scss'

import SERVER from '../../endPoint/index'

const useStyles = makeStyles((theme) => ({
    btnNoStatus: {
        width: 140,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#999999',
        textTransform: 'none',
        backgroundColor: 'white',
        border: '1px solid #999999',
        borderRadius: 30
    },
    btnStatus: {
        width: 140,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#0185FF',
        borderRadius: 30
    }
}));

export default function Diagnosa() {
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const classes = useStyles();
    const history = useHistory()

    useEffect(()=>{
        if (localStorage.getItem('token_access')) {
            // swal.showLoading()
            axios({
                method : "GET",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/user/setting`
            })
            .then(({data})=>{
                // console.log(data,'kakakak')
                // swal.close()
                // if(data.role === 'patient'){
                //     history.push("/landing-page-kabupaten/diagnosa-mandiri/dengan-status")
                // }
            })
            .catch(err=>{
                // swal.close()
                // localStorage.clear()
                console.log(err , ' << ERROR')
            })
        }
    },[])

    return (
        <div className="mobile-diagnosa-container-001">
            {
                isMobile ?
                <div className="diagnosa-mobile-navbar" >
                    <Navbar /> :
                </div> 
                :
                <Header/>
            } 

            {
                !isMobile ?
                <div className="diagnosa-mobile-navbar" >
                    <div className="diagnosa-title-1">
                        Diagnosa Mandiri > 
                    </div>
                </div>
                : <></>

            }
            
            <div className="diagnosa-mobile-navbar-001" >
                <span className="diagnosa-mobile-text001" >
                    Diagnosa Mandiri
            </span>
            </div>
            <div className="diagnosa-mobile-container-grey-002" >
                <div className="diagnosa-mobile-container-grey-001">
                    <div className="diagnosa-container" >
                        <span className="diagnosa-mobile-text002">
                            Diagnosa "Dengan Status" ditujukan untuk anda yang telah memiliki Akun yang terdaftar pada aplikasi "Kabupaten Banggai Covid 19 Update".
                        </span>
                        <span className="diagnosa-mobile-text003">
                            Diagnosa "Tanpa Status" bisa dilakukan oleh siapa saja tanpa perlu memiliki akun terlebih dahulu
                        </span>
                    </div>
                </div>
            </div>
            <div className="diagnosa-mobile-container-btn-group">
                <Button
                    type="submit"
                    size="medium"
                    className={classes.btnNoStatus}
                    onClick={()=>history.push('/landing-page-kabupaten/diagnosa-mandiri/tanpa-status')}
                    >Tanpa Status</Button>
                <Button
                    type="submit"
                    style={{ backgroundColor: '#0185FF' }}
                    size="medium"
                    className={classes.btnStatus}
                    onClick={()=>history.push('/login',{ from : 'Diagnosa' })}
                >Dengan Status</Button>
            </div>
            {
                !isMobile && 
                <div className="diagnosa-mobile-navbar" >
                    <FooterContent style={{width : "100%",marginTop : "115px"}}/> 
                </div>
            }
        </div>
    )

    // return (
    //     isMobile ?
    //         <div className="mobile-diagnosa-container-001">
    //             <div className="diagnosa-mobile-navbar" >
    //                 <Navbar />
    //             </div>
    //             <div className="diagnosa-mobile-navbar-001" >
    //                 <span className="diagnosa-mobile-text001" >
    //                     Diagnosa Mandiri
    //             </span>
    //             </div>
    //             <div className="diagnosa-mobile-container-grey-002" >
    //                 <div className="diagnosa-mobile-container-grey-001">
    //                     <div className="diagnosa-container" >
    //                         <span className="diagnosa-mobile-text002">
    //                             Diagnosa "Dengan Status" ditujukan untuk anda yang telah memiliki Akun yang terdaftar pada aplikasi "Kabupaten Banggai Covid 19 Update".
    //                         </span>
    //                         <span className="diagnosa-mobile-text003">
    //                             Diagnosa "Tanpa Status" bisa dilakukan oleh siapa saja tanpa perlu memiliki akun terlebih dahulu
    //                         </span>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="diagnosa-mobile-container-btn-group">
    //                 <Button
    //                     type="submit"
    //                     size="medium"
    //                     className={classes.btnNoStatus}
    //                     onClick={()=>history.push('/landing-page-kabupaten/diagnosa-mandiri/tanpa-status')}
    //                     >Tanpa Status</Button>
    //                 <Button
    //                     type="submit"
    //                     style={{ backgroundColor: '#0185FF' }}
    //                     size="medium"
    //                     className={classes.btnStatus}
    //                     onClick={()=>history.push('/login',{ from : 'Diagnosa' })}
    //                 >Dengan Status</Button>
    //             </div>
    //         </div>
    //         :
    //         <div>
    //         </div>
    // )
}
