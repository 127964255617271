// MODULE
import React , { useState } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'
import swal from 'sweetalert2';

// ENDPOINT
import SERVER from '../../../endPoint/index'

// GLOBAL ACTION
import { SET_DATA_SETTING_ACTIONS } from '../../../store/actions/settings'

// STYLE
import './plus.scss'
import './edit-form.scss'

function EditContent (props) {

    // PROPS DATA
    const {
        title,
        code,
        type,
        placeHolder,
        setInputData,
        group
    } = props

    // GLOBAL STATE
    const data = useSelector(state=>state.SettingState)

    // LOCAL STATE
    const [isShowPlusButton,setIsShowPlusButton] = useState(true)
    const [isShowInputButton,setIsShowInputButton] = useState(false)
    const [editedDataNew,setEditedDataNew] = useState(null) // INPUT UNTUK TAMBAH CONTENT
    const [isEditForm,setIsEditForm] = useState(null)
    const [editedDataForm , setEditedDataForm] = useState(data[code]) // INPUT UNTUK EDIT CONTENT
    const [selectedIdEdit,setSelectedIdEdit] = useState(null)

    // GLOBAL ACTION
    const dispatch = useDispatch()

    // FUNCTION
    let deleteValue = (e) => {
        e.preventDefault()
        swal.showLoading()
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting`,
            data : {
                key : code , value : type === 1 ? null : []
            }
        })
        .then((data)=>{
            dispatch(SET_DATA_SETTING_ACTIONS(
                {
                    key : code,
                    value : null
                }
            ))
            setInputData(null)
            swal.close()
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' <<< ERROR')
        })
        
    }

    let saveToDb = (e) => {
        e.preventDefault()
        if (!editedDataNew) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }else {

            let result = [...data[code]]
            let id = data[code][ data[code].length -1 ].id + 1
    
            // result.push(editedDataNew)
            console.log(id , ' <<< ID FIX')
    
            result.push(
                {
                    value : editedDataNew,
                    id 
                }
            )
            swal.showLoading()
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : result
                }
            })
            .then((data)=>{
                setEditedDataNew(null)
                setIsShowPlusButton(true)
                setIsShowInputButton(false)
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : result
                    }
                ))
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< ERROR')
            })
    
           
            // setEditedDataNew(null)
            // setIsShowPlusButton(true)
            // setIsShowInputButton(false)

        }

    }

    let deleteValuePerIndex = (e,id) => {

        e.preventDefault()
        let result = [...data[code]]

        if (result.length === 1) {
            swal.showLoading()
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : null
                }
            })
            .then((data)=>{
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : null
                    }
                ))
                setInputData(null)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< ERROR')
            })
           
        }else {

            result = result.filter((e,index2)=> e.id !== id)
            swal.showLoading()
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : result
                }
            })
            .then((data)=>{
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : result
                    }
                ))
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< ERROR')
            })

            
        }

        // result.push(editedDataNew)
    }

    let editFormForTypeOne = (e) => {
        e.preventDefault()
        if (!editedDataForm) {
             swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }else {
            swal.showLoading()
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : editedDataForm
                }
            })
            .then(({data})=>{
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : editedDataForm
                    }
                ))
                setIsEditForm(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' << ERROR')
            })
        }
    }

    let renderValueTypeTwo = () => {
        let result = [...data[code] ]

        return result.map((el,index)=>{
            return (

                <div 
                    className="gs-edit-container-124" 
                    style={
                            { 
                                marginBottom : index === result.length -1 ? "0" : null ,
                                paddingBottom : "22px"
                            }
                    } 
                    key={index}
                    
                >
                    
                    <div className="gs-input-title-124">
                        {index === 0 ? title : ""}
                    </div>

                    {   
                        selectedIdEdit === el.id ?
                        <input 
                            type="text" 
                            // className="gs-input-edit-form-126" 
                            className="gs-input-edit-form-126" 
                            placeholder={placeHolder}
                            onChange={e=>setEditedDataForm(e.target.value)}
                            value={editedDataForm}
                        /> :
                        <div className="gs-edit-value-124">
                            {
                                el.value
                            }
                        </div>
                    }

                    

                    <div className="gs-edit-delete-container-124" >

                        {
                            selectedIdEdit === el.id ?
                            <div 
                                className="gs-plus-input-delete-form-125" 
                                onClick={e=>setSelectedIdEdit(null)}
                            >
                                    Batal
                            </div> :
                            <div className="gs-circle-edit-content-124" onClick={e=>[setSelectedIdEdit(el.id),setEditedDataForm(el.value)]}>
                                <CreateIcon style={{color : "#c4c4c4"}}/>
                            </div>
                        }

                        {
                            selectedIdEdit === el.id ?
                             <div 
                                className="gs-plus-input-save-form-125"
                                // onClick={e=>saveToDb(e)}
                                onClick={e=>editFormForTypeTwo(e,el.id)}
                            >
                                Simpan
                            </div> :
                            <div className="gs-circle-delete-content-124" onClick={e=>deleteValuePerIndex(e,el.id)}>
                               <DeleteIcon style={{color : "#c4c4c4"}}/>
                           </div>
                        } 

                    </div>

                    {
                        index === result.length -1 && isShowPlusButton && el.id !== selectedIdEdit ? 
                            <div className="gs-plus-edit-124" onClick={e=>[setIsShowPlusButton(false),setIsShowInputButton(true)]}>
                                Tambah
                            </div> : <></>
                    }

                </div>

            )
        })

    }

    let editFormForTypeTwo = (e,id) => {
        e.preventDefault()
        if (!editedDataForm) {
             swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }else {
            let result = [...data[code]]
            result.forEach((el,index)=>{
                if (id === el.id) {
                    el.value = editedDataForm
                }
            })
            swal.showLoading()
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : result
                }
            })
            .then((data)=>{
                setSelectedIdEdit(null)
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : result
                    }
                ))
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< ERROR')
            })
        }
    }

    // UNTUK TYPE 2, SELAIN PROVINSI & KABUPATEN
    if (type === 2) {
        return (
            <div 
                className="gs-125-type-two-container" 
                style={
                    {
                        borderBottom : group === 1 ? "none" : null,
                        marginBottom : group === 1 ? "12px" : null
                    }
                }
            >
                {renderValueTypeTwo()}

                {/* Input Tambah AWAL */}
                
                {
                    isShowInputButton ? 
                    <div className="gs-plus-input-edit-container-125">

                        <input 
                            type="text" 
                            className="gs-plus-input-edit-form-125" 
                            placeholder={placeHolder}
                            onChange={e=>setEditedDataNew(e.target.value)}
                        />

                        <div 
                            className="gs-plus-input-delete-form-125" 
                            onClick={e=>[setIsShowPlusButton(true),setIsShowInputButton(false)]}
                        >
                            Batal
                        </div>

                        <div 
                            className="gs-plus-input-save-form-125"
                            onClick={e=>saveToDb(e)}
                        >
                            Simpan
                        </div>

                    </div> : <></>
                }

                {/* Input Tambah AKHIR */}


            </div>
        )
    }

    // UNTUK TYPE 1, PROVINSI & KABUPATEN
    return (
        <div className="gs-edit-container-124">

            <div 
                className="gs-input-title-124"
            >
                {title}
            </div>

            {
                isEditForm ?
                <input 
                    type="text" 
                    className="gs-input-edit-form-126" 
                    placeholder={placeHolder}
                    onChange={e=>setEditedDataForm(e.target.value)}
                    value={editedDataForm}
                /> :
                <div className="gs-edit-value-124">

                    {
                        data[code]
                    }

                </div>
            }


            <div className="gs-edit-delete-container-124" >

                {
                    isEditForm?
                    
                    <div 
                        className="gs-plus-input-delete-form-125" 
                        onClick={e=>setIsEditForm(false)}
                    >
                            Batal
                    </div> :

                    <div 
                        className="gs-circle-edit-content-124" 
                        onClick={e=>setIsEditForm(true)}
                    >
                        <CreateIcon style={{color : "#c4c4c4"}}/>
                    </div>
                }

                {
                    isEditForm ?
                    <div 
                        className="gs-plus-input-save-form-125"
                        // onClick={e=>saveToDb(e)}
                        onClick={e=>editFormForTypeOne(e)}
                    >
                        Simpan
                    </div> : 
                    <div className="gs-circle-delete-content-124" onClick={e=>deleteValue(e)}>
                        <DeleteIcon style={{color : "#c4c4c4"}}/>
                    </div>
        
                }

            </div>

        </div>
    )

}

export default EditContent