import React, {useEffect} from 'react'
import Maps from './maps'
import axios from 'axios'
import SERVER from '../../endPoint'
import { useHistory } from 'react-router-dom'
export default function MapsFunction(){
    const history = useHistory()

    useEffect(()=>{
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            if (data.role === 'patient') {
                history.replace('/landing-page-kabupaten')
            } else if (data.role === 'admin-berita') {
                history.replace('/input-berita')
            } else if (data.role === 'petugas') {
                history.replace('/data-individu')
            }
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    },[])

    return(
        <Maps/>
    )
}