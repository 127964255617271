// MODULE
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert2'

// COMPONENT
import Navbar from '../../../components/NavbarMobile'
import FooterContent from '../../../components/FooterContent1'
import Header from '../../LandingPageKabupaten/Header'


// STYLING
import './style.scss'

// ENDPOINT
import SERVER from '../../../endPoint'

const useStyles = makeStyles((theme) => ({
    btnSimpan: {
        width: 100,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#0185FF',
        borderRadius: 6,
        marginTop: 15,
    },
    btnSimpanWeb : {
        width: "160px",
        height: "38px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#0185FF',
        borderRadius: 6,
        marginTop: 15,
    },
    btnBack: {
        width: 200,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185FF',
        textTransform: 'none',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 15,
        border: "1px solid #0185FF"
    }
}));

export default function Diagnosa() {
    const classes = useStyles();
    const history = useHistory()
    let [count, setCount] = useState(1)
    const [hariKe, setHariKe] = useState(0)
    const [valueInput, setValueInput] = useState([])
    const [exercises, setExercise] = useState([])
    const [finish, setFinish] = useState(false)
    const [notDay, setNotDay] = useState(false)
    const [triggered, setTrigger] = useState(false)
    const [countExercise] = useState(
        [
            "",
            "Pertama",
            "Kedua",
            "Ketiga",
            "Keempat",
            "Kelima",
            "Keenam",
            "Ketujuh",
            "Kedelapan",
            "Kesembilan",
            "Kesepuluh",
            "Kesebelas",
            "Keduabelas",
            "Ketigabelas",
            "Keempatbelas",
            "Ketujuhbelas",
            "Kedelapanbelas",
            "Kesembilanbelas",
            "Keduapuluh",
            "Keduapuluhsatu",
            "Keduapuluhdua",
            "Keduapuluhtiga",
            "Keduapuluhduaempat",
            "Keduapuluhdualima",
        ]
    )
    const [waktu, setWaktu] = useState(null)
    const [key, setKey] = useState(null)
    const [flag, setFlag] = useState(null)
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const [location, setLocation] = useState(false)

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month + ' ' + year
    }

    useEffect(() => {
        // swal.showLoading()
        axios({
            method: 'GET',
            url: `${SERVER}/diagnosa`,
            headers: {
                token: localStorage.getItem('token_access')
            },
        })
            .then(({ data }) => {
                // swal.close()
                let exerTemp = ["", ...data[0].diagnosisData]
                setExercise(exerTemp)
            })
            .catch(err => {
                // swal.close()
                console.log(err)
            })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    if (localStorage.getItem('token_access')) {
                        axios({
                            method: 'patch',
                            url: `${SERVER}/patient/location`,
                            headers: {
                                token: localStorage.getItem('token_access')
                            },
                            data: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        })
                        .then(({ data }) => {
                            location ? setLocation(false) : setLocation(true)
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                    }
                });
            } else {
                console.log("Not Available");
            }
        }, 3000);
    }, [location, localStorage.getItem('token_access')])

    const handleClick = (id) => {
        let temp = []
        if (valueInput.length === 0) {
            temp.push(id)
            setValueInput(temp)
        } else {
            let flag = false
            valueInput.map(val => {
                if (val == id) {
                    flag = true
                } else {
                    temp.push(val)
                }
            })
            if (!flag) {
                temp.push(id)
            }
            setValueInput(temp)
        }
    }

    const handleSimpan = () => {
        if(valueInput.length === 0){
            swal.fire({
                html: 'Jawaban Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            let last = false
            if (count == exercises.length - 1) {
                last = true
            }
            exercises[count].answer.map((ans, i) => {
                valueInput.map(val => {
                    if (i != exercises[count].answer.length - 1) {
                        if (val == ans.value) {
                            setTrigger(true)
                        }
                    }
                })
            })
            setCount(count += 1)
            setValueInput([])
            if (last) {
                setFinish(true)
            }
            if(exercises[count]){
                if(exercises[count].answer){
                    for (let i = 0; i < exercises[count].answer.length; i++) {
                        if (document.getElementById("checkBox" + i)) {
                            if (document.getElementById("checkBox" + i).checked) {
                                document.getElementById("checkBox" + i).checked = false;
                            }
                        }
                    }
                }
            }
        }
    }

    return (
        <div className="mobile-diagnosa-container-001">
             {
                isMobile ?
                <div className="diagnosa-mobile-navbar" >
                    <Navbar /> :
                </div> 
                :
                <Header/>
            } 

            {
                !isMobile ?
                <div className="diagnosa-mobile-navbar" >
                     <div className="diagnosa-title-1">
                        Diagnosa Mandiri > 
                    </div>
                </div>
                : <></>

            }

            {
                finish ?
                    triggered ?
                        <>
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Perhatian
                                </span>
                            </div>
                            <div className="diagnosa-mobile-container-grey-002" >
                                <div className={ isMobile ? "diagnosa-mobile-container-grey-008" : "diagnosa-mobile-container-grey-001" } >
                                    <span className={ isMobile ? "diagnosa-mobile-text012-mlb" : "diagnosa-mobile-text012" }>
                                        Sebagai tindakan pencegahan, Pemkab Banggai meminta siapa saja yang mengalami gejala (demam, batuk, bersin, sakit tenggorokan, atau sulit bernapas) untuk tinggal di rumah selama 14 hari untuk mencegah penyebaran. Anda disarankan untuk tidak keluar ke tempat publik, tinggal dirumah saja dan tidak boleh ada tamu. Anda disarankan untuk menelepon call center Pemkab Banggai
                                    </span>
                                </div>
                            </div>
                            <div className="btn-containet-lgou">
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: 'white' }}
                                    size="medium"
                                    className={classes.btnBack}
                                    onClick={() => history.replace('/landing-page-kabupaten')}
                                >
                                    Kembali ke Beranda
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Terimakasih Telah Mengisi Diagnosa Mandiri untuk Hari ini
                                </span>
                            </div>
                            <div className="diagnosa-mobile-container-grey-002" >
                                <div className={ isMobile ? "diagnosa-mobile-container-grey-008" : "diagnosa-mobile-container-grey-001" }>
                                    <span className={ isMobile ? "diagnosa-mobile-text012-mlb" : "diagnosa-mobile-text012" }>
                                        Jangan lupa untuk melakukan diagnosa mandiri secara rutin sesuai dengan jadwal yang ditentukan. Tetap jaga kesehatan dan senantiasa menerapkan phisical distancing
                                    </span>
                                </div>
                            </div>
                            <div className="btn-containet-lgou">
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: 'white' }}
                                    size="medium"
                                    className={classes.btnBack}
                                    onClick={() => history.replace('/landing-page-kabupaten')}
                                >
                                    Kembali ke Beranda
                            </Button>
                            </div>
                        </>
                    :
                    <>
                        {
                            isMobile ?
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Diagnosa Mandiri
                                </span>
                                <span className="diagnosa-mobile-text001" >
                                    Tanpa Status
                                </span>
                            </div> : 
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" style={{marginTop : !isMobile ? "15px" : null}} >
                                    Diagnosa Mandiri Tanpa Status
                                </span>
                            </div>
                        }
                        <div className="diagnosa-mobile-container-grey-002" >
                            <div className="diagnosa-mobile-container-grey-001">
                                <span className="diagnosa-mobile-text002">
                                    Untuk Mendapatkan Hasil Diagnosis Akurat, Anda Diharuskan Mengisi Pertanyaan Berikut dengan Jawaban yang Jujur dan Rutin Mengisinya Selama 14 Hari
                                </span>
                            </div>
                        </div>
                        <div className="diagnosa-mobile-container-grey-007" style={{marginTop : !isMobile ? "28px" : null}} >
                            {/* <div className="diagnosa-mobile-text005">
                                Hari Ke - {hariKe}
                            </div> */}
                            <div className="diagnosa-mobile-text006" style={{ fontSize: isMobile ? '' : 18 ,paddingTop: 11 }} >
                                Pertanyaan {countExercise[count]}
                            </div>
                        </div>

                        <div 
                            className="diagnosa-mobile-container-grey-007" 
                            style={{alignItems : !isMobile ? "center": null}}
                        >
                            <div className="pertanyaan-diagnosa"  >
                                {
                                    exercises[count] &&
                                    exercises[count].question
                                }
                            </div>
                            <div className="container-jawaban-diagnosa" >
                                {
                                    exercises[count] &&
                                    exercises[count].answer &&
                                    exercises[count].answer.map((ans, i) => (
                                        <label className="jawaban-diagnosa" key={i}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: 'row',
                                                fontSize: isMobile ? 12 : 16,
                                                alignItems: "center",
                                            }}>
                                                <input type="checkbox"
                                                    id={"checkBox" + i}
                                                    onChange={(e) => handleClick(ans.value)}
                                                />
                                                <span style={{ marginLeft: !isMobile ? 11 : '' }} >
                                                    {ans.value}
                                                </span>
                                            </div>
                                        </label>
                                    ))
                                }
                                <div className="btn-simpan-container" >
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: '#0185FF' }}
                                        size="medium"
                                        className={ !isMobile ? classes.btnSimpanWeb :  classes.btnSimpan}
                                        onClick={() => handleSimpan()}
                                    >
                                        Simpan
                                </Button>
                                </div>
                            </div>
                        </div>
                    </>
            }

            {
                !isMobile &&
                <FooterContent style={{marginTop : "130px",width : "100%"}}/>
            }
        </div>
    )

    // return (
    //     isMobile ?
    //         <div className="mobile-diagnosa-container-001">
    //             <div className="diagnosa-mobile-navbar" >
    //                 <Navbar />
    //             </div>

    //             {
    //                 finish ?
    //                     triggered ?
    //                         <>
    //                             <div className="diagnosa-mobile-navbar-004" >
    //                                 <span className="diagnosa-mobile-text001" >
    //                                     Perhatian
    //                         </span>
    //                             </div>
    //                             <div className="diagnosa-mobile-container-grey-002" >
    //                                 <div className="diagnosa-mobile-container-grey-001">
    //                                     <span className="diagnosa-mobile-text012">
    //                                         Sebagai tindakan pencegahan, Pemkab Banggai meminta siapa saja yang mengalami gejala (demam, batuk, bersin, sakit tenggorokan, atau sulit bernapas) untuk tinggal di rumah selama 14 hari untuk mencegah penyebaran. Anda disarankan untuk tidak keluar ke tempat publik, tinggal dirumah saja dan tidak boleh ada tamu. Anda disarankan untuk menelepon call center Pemkab Banggai
    //                             </span>
    //                                 </div>
    //                             </div>
    //                             <div className="btn-containet-lgou">
    //                                 <Button
    //                                     type="submit"
    //                                     style={{ backgroundColor: 'white' }}
    //                                     size="medium"
    //                                     className={classes.btnBack}
    //                                     onClick={() => history.replace('/landing-page-kabupaten')}
    //                                 >
    //                                     Kembali ke Beranda
    //                                 </Button>
    //                             </div>
    //                         </>
    //                         :
    //                         <>
    //                             <div className="diagnosa-mobile-navbar-004" >
    //                                 <span className="diagnosa-mobile-text011" >
    //                                     Terimaksih Telah Mengisi Diagnosa Mandiri untuk Hari ini
    //                         </span>
    //                             </div>
    //                             <div className="diagnosa-mobile-container-grey-002" >
    //                                 <div className="diagnosa-mobile-container-grey-001">
    //                                     <span className="diagnosa-mobile-text012">
    //                                         Jangan lupa untuk melakukan diagnosa mandiri secara rutin sesuai dengan jadwal yang ditentukan. Tetap jaga kesehatan dan senantiasa menerapkan phisical distancing
    //                                     </span>
    //                                 </div>
    //                             </div>
    //                             <div className="btn-containet-lgou">
    //                                 <Button
    //                                     type="submit"
    //                                     style={{ backgroundColor: 'white' }}
    //                                     size="medium"
    //                                     className={classes.btnBack}
    //                                     onClick={() => history.replace('/landing-page-kabupaten')}
    //                                 >
    //                                     Kembali ke Beranda
    //                             </Button>
    //                             </div>
    //                         </>
    //                     :
    //                     <>
    //                         <div className="diagnosa-mobile-navbar-004" >
    //                             <span className="diagnosa-mobile-text001" >
    //                                 Diagnosa Mandiri
    //                         </span>
    //                             <span className="diagnosa-mobile-text001" >
    //                                 Tanpa Status
    //                         </span>
    //                         </div>
    //                         <div className="diagnosa-mobile-container-grey-002" >
    //                             <div className="diagnosa-mobile-container-grey-001">
    //                                 <span className="diagnosa-mobile-text002">
    //                                     Untuk Mendapatkan Hasil Diagnosis Akurat, Anda Diharuskan Mengisi Pertanyaan Berikut dengan Jawaban yang Jujur dan Rutin Mengisinya Selama 14 Hari
    //                             </span>
    //                             </div>
    //                         </div>
    //                         <div className="diagnosa-mobile-container-grey-007" >
    //                             {/* <div className="diagnosa-mobile-text005">
    //                                 Hari Ke - {hariKe}
    //                             </div> */}
    //                             <div className="diagnosa-mobile-text006" style={{ paddingTop: 11 }} >
    //                                 Pertanyaan {countExercise[count]}
    //                             </div>
    //                         </div>

    //                         <div className="diagnosa-mobile-container-grey-007" >
    //                             <div className="pertanyaan-diagnosa">
    //                                 {
    //                                     exercises[count] &&
    //                                     exercises[count].question
    //                                 }
    //                             </div>
    //                             <div className="container-jawaban-diagnosa" >
    //                                 {
    //                                     exercises[count] &&
    //                                     exercises[count].answer &&
    //                                     exercises[count].answer.map((ans, i) => (
    //                                         <label className="jawaban-diagnosa" key={i}>
    //                                             <div style={{
    //                                                 display: "flex",
    //                                                 flexDirection: 'row'
    //                                             }}>
    //                                                 <input type="checkbox"
    //                                                     id={"checkBox" + i}
    //                                                     onChange={(e) => handleClick(ans.value)}
    //                                                 />
    //                                                 <span>
    //                                                     {ans.value}
    //                                                 </span>
    //                                             </div>
    //                                         </label>
    //                                     ))
    //                                 }
    //                                 <div className="btn-simpan-container" >
    //                                     <Button
    //                                         type="submit"
    //                                         style={{ backgroundColor: '#0185FF' }}
    //                                         size="medium"
    //                                         className={classes.btnSimpan}
    //                                         onClick={() => handleSimpan()}
    //                                     >
    //                                         Simpan
    //                                 </Button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </>
    //             }
    //         </div>
    //         :
    //         <div>
    //         </div>
    // )
}
