import React from 'react'

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    infoBtn:{
        // width: 'px',
        height: '20px',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        marginLeft: -100,
        marginRight: 10,
        paddingTop: 0,
        fontWeight: 'bold'
    }
}));

function FirstContent (props) {

    const {
        kecamatanList,
        desaList,
        dataCount,
        dataHariIni,
        dataTotal,
        kecamatanToday
    } = props

    const classes = useStyles();
    const history = useHistory()

    const current = window
    console.log(current.innerWidth , ' <<< INNERWIDTH')
    console.log(current.outerWidth , ' <<< OUTER WIDTH')

    return (
        <div 
            className="bc-triple-content-597" 
            style={
                    {
                        marginTop : "26px",
                        width : (current.innerWidth - 296)  + "px"
                    }
                }
        >

            <div className="bc-triple-per-content-597" style={{backgroundColor : "#2224C8"}}>

                <div className="bct-first-content-597">

                    <div className="bct-first-content-item-597">

                        <div className="bct-new-content-first-597"
                            style={
                                {
                                    display: "flex",
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }
                            }
                        >

                            <div className="bct-ncf-title1-597" style={{ fontSize: 50, marginTop: 5 }}>
                                {/* { dataCount && dataCount.kecamatan } */}
                                { kecamatanList ? kecamatanList.length : 0 }
                            </div>

                            <div className="bct-ncf-title2-597" style={{ marginTop: 15 }}>
                                Kecamatan
                            </div>

                        </div>

                        {/* <div className="bct-ncf-button-597" style={{ cursor: 'pointer'}}
                            onClick={()=> history.push('/landing-page-kecamatan') }
                        >
                            Lihat
                        </div> */}

                        {/* <div className="bct-fc-title1-597">
                            Kecamatan
                        </div>

                        <div className="bct-fc-title2-597">
                            { kecamatanList ? kecamatanList.length : 0 }
                        </div> */}

                    </div>

                    <div className="bct-fc-hr-597">
                    </div>   

                    <div className="bct-first-content-item-597">

                    <div 
                        className="bct-new-content-first-597" 
                        style={
                                {
                                    display: "flex",
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }
                            }
                    >

                        <div className="bct-ncf-title1-597" style={{ fontSize: 50, marginTop: 5 }}>
                            {/* { desaList ? desaList.length : 0 } */}
                            337
                        </div>

                        <div className="bct-ncf-title2-597" style={{ marginTop: 15 }}  >
                            Desa/Kelurahan
                        </div>

                    </div>
                        {/* <div className="bct-ncf-button-597" style={{marginLeft : "-20px", cursor: 'pointer'}}>
                            Lihat
                        </div> */}

                        {/* <div className="bct-fc-title1-597">
                            Desa/Kelurahan
                        </div>

                        <div className="bct-fc-title2-597">
                            { desaList ? desaList.length : 0 }
                        </div> */}

                    </div>

                </div>

                <div 
                    // className="bct-second-content-597"
                    // style={{
                    //     flexDirection : "row",
                    //     justifyContent : "center",
                    //     alignItems : "center"
                    // }}
                    className="bct-second-content-597"
                    style={{
                        flexDirection : "row",
                        // justifyContent : "space-evenly",
                        // justifyContent : "center",
                        alignItems : "center",
                        width : "100%",
                }}
                >
                    <div className="lpk-content-second-867"   >
                        Kabupaten Banggai
                    </div>
                    <div className="bct-ncf-button-597"
                        style={{ cursor: 'pointer', paddingLeft: 10, paddingRight: 10 }}
                        onClick={()=> history.push('/landing-page-kecamatan') }
                    >
                        Lihat
                    </div>
                    {/* <Button type="submit" size="small" style={{ backgroundColor: "#0185FF" }} className={classes.infoBtn}
                        onClick={()=>history.push('/landing-page-kecamatan')}
                    >
                        Lihat
                    </Button> */}
                </div>

            </div>

            <div className="bc-triple-per-content-597" style={{backgroundColor : "#B82323"}}>

                <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >

                    {/* <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Total
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.positif &&
                                dataCount.positif.perawatan +
                                dataCount.positif.isolasi +
                                dataCount.positif.sembuh +
                                dataCount.positif.meninggal
                            }
                        </div>
                    </div> */}

                    {/* <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div> */}

                    {/* <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Hari Ini
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataHariIni && dataHariIni.positif && dataHariIni.positif.perawatan +
                                dataHariIni.positif.isolasi +
                                dataHariIni.positif.sembuh +
                                dataHariIni.positif.meninggal
                            }
                        </div>
                    </div> */}

                    {/* <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div> */}

                    <div className="bct-majority-597" style={{ width: '20%' }} >
                        <div className="bc-majority-title1-597">
                            Dalam Perawatan
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.positif && dataCount.positif.perawatan
                            }
                        </div>

                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.positif &&
                                kecamatanToday.positif.perawatan !== 0 &&
                                <span className="txt-131-878positif-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.positif &&
                                        kecamatanToday.positif.perawatan
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '25%' }}>
                        <div className="bc-majority-title1-597">
                            Di Ruang Isolasi
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.positif && dataCount.positif.isolasi
                            }
                        </div>
                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.positif &&
                                kecamatanToday.positif.isolasi !== 0 &&
                                <span className="txt-131-878positif-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.positif &&
                                        kecamatanToday.positif.isolasi
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '25%' }}>
                        <div className="bc-majority-title1-597">
                            Sembuh
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.positif && dataCount.positif.sembuh
                            }
                        </div>
                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.positif &&
                                kecamatanToday.positif.sembuh !== 0 &&
                                <span className="txt-131-878positif-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.positif &&
                                        kecamatanToday.positif.sembuh
                                    }
                                </span>
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Meninggal
                        </div>

                        <div className="bc-majority-title2-597">
                            {
                                dataCount && dataCount.positif && dataCount.positif.meninggal
                            }
                        </div>
                        <div className="txt-btnla-141">
                            {
                                kecamatanToday && kecamatanToday.positif &&
                                kecamatanToday.positif.meninggal !== 0 &&
                                <span className="txt-131-878positif-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.positif &&
                                        kecamatanToday.positif.meninggal
                                    }
                                </span>
                            }
                        </div>
                    </div>

                </div>
                
                <div className="bct-second-content-597 border-top-lpk-type-1-867" >
                    <div className="bx-span-tgt">
                        <span className="bct-sc-title1-597">
                            {"Total "}
                            {
                                dataCount && dataCount.positif &&
                                dataCount.positif.perawatan +
                                dataCount.positif.isolasi +
                                dataCount.positif.sembuh +
                                dataCount.positif.meninggal
                            }
                        </span>
                        <div className="txt-btnla-141"
                            style={{
                                marginTop: '15px',
                                marginBottom: '0px',
                                marginLeft: '10px'
                            }}
                        >
                            {
                                kecamatanToday && kecamatanToday.positif &&
                                kecamatanToday.positif.perawatan +
                                kecamatanToday.positif.isolasi +
                                kecamatanToday.positif.sembuh +
                                kecamatanToday.positif.meninggal !== 0 &&
                                <span className="txt-131-878positif-12" >
                                    +  &nbsp;
                                    {
                                        kecamatanToday && kecamatanToday.positif &&
                                        kecamatanToday.positif.perawatan +
                                        kecamatanToday.positif.isolasi +
                                        kecamatanToday.positif.sembuh +
                                        kecamatanToday.positif.meninggal
                                    }
                                </span>
                            }
                        </div>
                    </div>
                    <div className="bct-sc-title2-597">
                        Konfirmasi Positif
                    </div>
                </div>

            </div>

            <div className="bc-triple-per-content-597" style={{backgroundColor : "#FF8A00"}}>

            <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                {/* <div className="bct-majority-597">
                    <div className="bc-majority-title1-597">
                        Hari Ini
                    </div>

                    <div className="bc-majority-title2-597">
                        {
                            dataHariIni && dataHariIni.pdp &&
                            dataHariIni.pdp.meninggal +
                            dataHariIni.pdp.naikPositif +
                            dataHariIni.pdp.negatif +
                            dataHariIni.pdp.nonCovid +
                            dataHariIni.pdp.perawatan +
                            dataHariIni.pdp.sembuh
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div> */}

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Naik Positif
                    </div>

                    <div className="bc-majority-title2-597">
                    {
                        dataCount && dataCount.pdp &&
                        dataCount.pdp.naikPositif
                    }
                    </div>

                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.naikPositif !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.naikPositif
                                }
                            </span>
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div>

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Dalam Perawatan
                    </div>

                    <div className="bc-majority-title2-597">
                    {
                        dataCount && dataCount.pdp && dataCount.pdp.perawatan
                    }
                    </div>

                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.perawatan !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.perawatan
                                }
                            </span>
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div>

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Negatif
                    </div>

                    <div className="bc-majority-title2-597">
                    {
                        dataCount && dataCount.pdp &&
                        dataCount.pdp.negatif
                    }
                    </div>

                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.negatif !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.negatif
                                }
                            </span>
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div>

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Bukan Covid 19
                    </div>

                    <div className="bc-majority-title2-597">
                    {
                        dataCount && dataCount.pdp &&
                        dataCount.pdp.nonCovid
                    }
                    </div>

                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.nonCovid !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.nonCovid
                                }
                            </span>
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div>

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Sembuh
                    </div>

                    <div className="bc-majority-title2-597">
                        {
                            dataCount && dataCount.pdp && dataCount.pdp.sembuh
                        }
                    </div>

                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.sembuh !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.sembuh
                                }
                            </span>
                        }
                    </div>
                </div>

                <div className="bc-majority-hr-597" style={{backgroundColor : "#EBB16D"}}>

                </div>

                <div className="bct-majority-597" style={{ width: '20%' }}>
                    <div className="bc-majority-title1-597">
                        Meninggal
                    </div>

                    <div className="bc-majority-title2-597">
                        {
                            dataCount && dataCount.pdp && dataCount.pdp.meninggal
                        }
                    </div>
                    <div className="txt-btnla-141">
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.meninggal !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.meninggal
                                }
                            </span>
                        }
                    </div>
                </div>

            </div>
            
            <div className="bct-second-content-597 border-top-lpk-type-2-867" >
                <div className="bx-span-tgt">
                    <span className="bct-sc-title1-597">
                        {"Total "}
                        {
                            dataCount && dataCount.pdp && dataCount.pdp.perawatan +
                            dataCount.pdp.naikPositif +
                            dataCount.pdp.sembuh +
                            dataCount.pdp.negatif +
                            dataCount.pdp.nonCovid + 
                            dataCount.pdp.meninggal
                        }
                    </span>
                    <div className="txt-btnla-141"
                        style={{
                            marginTop: '15px',
                            marginBottom: '0px',
                            marginLeft: '10px'
                        }}
                    >
                        {
                            kecamatanToday && kecamatanToday.pdp &&
                            kecamatanToday.pdp.perawatan +
                            kecamatanToday.pdp.naikPositif +
                            kecamatanToday.pdp.sembuh +
                            kecamatanToday.pdp.negatif +
                            kecamatanToday.pdp.nonCovid + 
                            kecamatanToday.pdp.meninggal !== 0 &&
                            <span className="txt-131-878pdp-12" >
                                +  &nbsp;
                                {
                                    kecamatanToday && kecamatanToday.pdp &&
                                    kecamatanToday.pdp.perawatan +
                                    kecamatanToday.pdp.naikPositif +
                                    kecamatanToday.pdp.sembuh +
                                    kecamatanToday.pdp.negatif +
                                    kecamatanToday.pdp.nonCovid + 
                                    kecamatanToday.pdp.meninggal
                                }
                            </span>
                        }
                    </div>
                </div>
                <div className="bct-sc-title2-597">
                    Pasien Dalam Pemantauan (PDP)
                </div>
            </div>

        </div>

        </div>
    )

}

export default FirstContent