// MODULE
import React , {useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputTypeTwo (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        type,
        valueOption,
        isDisable,
        value,
        stars
    } = props


    // DISPATCH
    const dispatch = useDispatch()

    // LOCAL STATE
    const [selectedChecked,setSelectedChecked] = useState(null)
    const dataGlobal = useSelector(state=>state.individualState)

    // FUNCTION
    let changeInput = (e) => {
        console.log(code, e.target.value)
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : e.target.value}))
        if (title === 'Kecamatan') {
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "kelurahan",value : null}))
        }
    }

    let changeRadioButton = (e,value,index) => {
        if (code === "kondisiSaatIni" && value === "Sehat") {
            console.log('MASUK SINI >>>>')
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "jenisSakit",value : "-"}))
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "keluhan",value : "-"}))
        }

        if (code === "transit"&&value === "Tidak") {
            console.log('>>>>> masUK SINI')
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "tanggalMasuk",value : "-"}))
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "jamMasuk",value : "-"}))
            dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "alamatTujuan",value : "-"}))
        }

        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value }))
        setSelectedChecked(index)
    }

    let renderRadioButton = () => {
        if (valueOption) {
            return valueOption.map((el,index)=>{
                return (
                    <label className="itt-radio-container-671-type-2" style={{width : "100%"}}>
                        <input 
                            onClick={e=>changeRadioButton(e,el,index)} 
                            checked={index === selectedChecked ? true : false} 
                            type="radio"
                            value={el}
                            className="itt-radio-671"
                            disabled={isDisable ? true : false}
                        />
                        {el}
                    </label>
                )
            })
        }else {
            return <></>
        }
    }

    let renderValueOption = () => {
        if (valueOption && valueOption.length > 0) {
            return valueOption.map((el,index)=>{
                return (
                    <option>  
                        {
                            typeof  el === "string" ? el : el.value
                        }
                    </option>
                )
            })
        }else {
            return (
                <></>
            )
        }
    }

    return (

        <form className="input-type-two-container-671" onSubmit={e=>e.preventDefault()}>

            <div className="itt-title-671">
                {title}
                {
                    stars &&
                    <p style={{
                        color: "red",
                        marginLeft: 2
                    }} >
                        *
                    </p>
                }
            </div>
            {
                type !== 'radio' ?
                <select
                    className="itt-input-671"
                    onChange={e=>changeInput(e)}
                    disabled={isDisable ? true : false}
                    value={value}
                >
                    <option disabled selected>{placeholder}</option>
                    {
                        renderValueOption()
                    }

                </select> :
                <form className="itt-radio-container-671">
                    {   
                        renderRadioButton()
                    }
                </form>
            }
    
        </form>

    )

}

export default InputTypeTwo