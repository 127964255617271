import React from 'react'

// COMPONENT
import TitleContent from '../TitleContent'
import MainContent from '../MainContent'

// STYLE
import './style.scss'

function LeftContent (props) {

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return (
        <div className="di-879-per-item-riwayat">
            
            <TitleContent
                title={"Riwayat Status & Isolasi Mandiri"}
            />

            <div className="table-manual-riwayat" >
                <table className="table-manual-header">
                    <tr className="border-bottom-manual" >
                        <th>NO</th>
                        <th>TANGGAL</th>
                        <th>STATUS</th>
                        <th>SUB STATUS</th>
                        <th>ISOLASI MANDIRI</th>
                    </tr>
                    {
                        props.flag == 'Ya' ?
                        props.patient &&
                        props.patient
                        .sort((a,b) => new Date(b.date) - new Date(a.date))
                        .map((pasien,i)=>(
                            <tr>
                                <td className="body-manual-table" align="center">{ i +=1 }</td>
                                <td className="body-manual-table" align="center">{ showDate(pasien.date) }</td>
                                <td className="body-manual-table" align="center">{ pasien.status }</td>
                                <td className="body-manual-table" align="center">{ pasien.subStatus }</td>
                                {
                                    i === 1 ?
                                    <td className="body-manual-table" align="center">{ pasien.isolasiMandiri }</td>
                                    :
                                    <td className="body-manual-table" align="center">-</td>
                                }
                            </tr>
                        ))
                        :
                        <tr>
                            <td className="body-manual-table" align="center">-</td>
                            <td className="body-manual-table" align="center">-</td>
                            <td className="body-manual-table" align="center">-</td>
                            <td className="body-manual-table" align="center">-</td>
                            <td className="body-manual-table" align="center">-</td>
                        </tr>
                    }
                </table>
            </div>

        </div>
    )

}

export default LeftContent