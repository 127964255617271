import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import '../../fonts/style.css'
import './style.scss'
import './password.scss'

import LogoKabupaten from '../../images/kab-banggai-logo.png'
import LogoOptimus from '../../images/optimus.svg'

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useDispatch } from 'react-redux'
import axios from 'axios'
import SERVER from '../../endPoint'
import { USER_LOGIN } from '../../store/constanta'
import swal from 'sweetalert2'

import MobileScreen from './MobileScreen'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    input1: {
        marginBottom: 30,
        width: 450,
        height: 38,
        fontFamily: 'OpenSans',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#888888',
    },
    input2: {
        marginBottom: 45,
        width: 450,
        height: 38,
        fontFamily: 'OpenSans',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#888888',
    },
    loginBtn:{
        width: 450,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        textTransform: 'none'
    }
}));

function Login () {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordHide, setPasswordHide] = useState(true)
    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    useEffect(()=>{
        if (localStorage.getItem('token_access')) {
            fetchDataUser()
        }
    },[])

    // useEffect(()=>{
    //     console.clear()
    // })

    const handleSubmit = e => {
        let flag = false
        if (history.location.state) {
            if (history.location.state.from == "Diagnosa") {
                flag = true
            }
        }
        swal.showLoading()
        e.preventDefault()
        axios({
            method: 'post',
            url: `${SERVER}/user/login`,
            data: { email, password }
        })
        .then(({ data })=>{
            if (data.profile.role === 'master' || data.profile.role === 'admin' || data.profile.role === 'super-master') {
                dispatch({
                    type : USER_LOGIN,
                    payload: data.profile
                })
                localStorage.setItem('token_access', data.token)
                history.replace('/beranda')
                swal.close()
            } else if (data.profile.role === 'petugas') {
                dispatch({
                    type : USER_LOGIN,
                    payload: data.profile
                })
                localStorage.setItem('token_access', data.token)
                history.replace('/data-individu')
                swal.close()
            } else if (data.profile.role === 'admin-berita') {
                dispatch({
                    type : USER_LOGIN,
                    payload: data.profile
                })
                localStorage.setItem('token_access', data.token)
                history.replace('/input-berita')
                swal.close()
            }
            else {
                if (flag) {
                    if (data.profile.dataPasien.isolasiMandiri === 'Ya') {
                        dispatch({
                            type : USER_LOGIN,
                            payload: data.profile
                        })
                        localStorage.setItem('token_access', data.token)
                        history.replace('/landing-page-kabupaten/diagnosa-mandiri/dengan-status')
                    } else {
                        swal.fire({
                            title: 'Gagal',
                            text: 'Maaf Akses Anda Ditolak',
                            icon: 'warning',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#0280A2',
                        })
                    }
                } else {
                    swal.fire({
                        html: 'Anda Hanya Bisa Login Melalui <br> Diagnosa Mandiri Dengan Status',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                }
            }
        })
        .catch(err=>{
            swal.close()
            const Toast = swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', swal.stopTimer)
                    toast.addEventListener('mouseleave', swal.resumeTimer)
                }
            })
            if(err.response){
                if(err.response.data){
                    Toast.fire({
                        icon: 'warning',
                        title: err.response.data
                    })
                }
            }
        })
    }

    let fetchDataUser = () => {
        let flag = false
        if (history.location.state) {
            if (history.location.state.from == "Diagnosa") {
                flag = true
            }
        }
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            if (data.role === 'master' || data.role === 'admin' || data.role === 'super-master') {
                localStorage.setItem('token_access', localStorage.getItem('token_access'))
                history.replace('/')
            } else if (data.role === 'petugas') {
                localStorage.setItem('token_access', localStorage.getItem('token_access'))
                history.replace('/data-individu')
            } else if (data.role === 'admin-berita') {
                localStorage.setItem('token_access', localStorage.getItem('token_access'))
                history.replace('/input-berita')
            } else {
                console.log(data, flag, 'naikikoko')
                if (flag) {
                    if (data.patientId.isolasiMandiri === 'Ya') {
                        dispatch({
                            type : USER_LOGIN,
                            payload: data.profile
                        })
                        localStorage.setItem('token_access', localStorage.getItem('token_access'))
                        history.replace('/landing-page-kabupaten/diagnosa-mandiri/dengan-status')
                    } else {
                        swal.fire({
                            title: 'Gagal',
                            text: 'Maaf Akses Anda Ditolak',
                            icon: 'warning',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#0280A2',
                        })
                    }
                }
            }
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    }

    const checkEmail = e => {
        setEmail(e.target.value)
    }

    const checkPassword = e => {
        setPassword(e.target.value)
    }

    const handleClickShowPassword = e => {
        passwordHide ? setPasswordHide(false) : setPasswordHide(true)
    }

    if (isTabletOrMobile) {
        return (
            <MobileScreen
                handleSubmit={handleSubmit}
                email={email}
                checkEmail={checkEmail}
                passwordHide={passwordHide}
                password={password}
                checkPassword={checkPassword}
                handleClickShowPassword={handleClickShowPassword}
            />
        )
    }

    return (
        <div className="container-login-1" >
            <div className="container-login-11" >
                <img src={LogoKabupaten} alt="logoKabupaten" className="login-logo-kabupaten" />
                <span className="login-text-1" >
                    Pemerintah Kabupaten Banggai Gugus Tugas Covid 19
                </span>
            </div>
            <div className="container-login-12" >
                <div className="optimus-container" >
                    <div className="login-optimus-container" >
                        <img src={LogoOptimus} alt="optimus_digital" className="login-optimus-logo-1" />
                        <span className="login-text-2" >
                            OPTIMUS DIGITAL
                        </span>
                    </div>
                </div>
                <div className="input-container" >
                    <div className="text-login-container-1">
                        <span className="input-text-login-1" >
                            Selamat Datang
                        </span>
                    </div>
                    <form className={classes.root} noValidate autoComplete="false" onSubmit={e=>handleSubmit(e)}>
                        <FormControl size="small"  className={clsx(classes.margin, classes.textField, classes.input1)} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Email/Username</InputLabel>
                            <OutlinedInput
                                value={email}
                                onChange={e=>checkEmail(e)}
                                label="Email/Username"
                                inputProps={{
                                    autoComplete: 'false',
                                    form:{
                                        autoComplete: 'false',
                                    }
                                }}
                                onKeyPress={e =>{
                                    if (e.key === 'Enter') {
                                        handleSubmit(e)
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl size="small"  className={clsx(classes.margin, classes.textField, classes.input2)}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id={passwordHide ? "password-style" : "" }
                                value={password}
                                onChange={e=>checkPassword(e)}
                                label="Password"
                                inputProps={{
                                    autoComplete: 'false',
                                    form:{
                                        autoComplete: 'false',
                                    }
                                }}
                                onKeyPress={e =>{
                                    if (e.key === 'Enter') {
                                        handleSubmit(e)
                                    }
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div className="icon-eye-p-container" 
                                            onClick={e=>handleClickShowPassword()}
                                        >
                                            {
                                                passwordHide ?
                                                <i className="far fa-eye password-eye-login-on"></i>
                                                :
                                                <i className="far fa-eye password-eye-login-off"></i>
                                            }
                                        </div>
                                    </InputAdornment>
                                }
                                // labelWidth={70}
                            />
                        </FormControl>
                        <Button type="submit" size="medium" className={classes.loginBtn} onSubmit={e=>handleSubmit(e)}>Masuk</Button>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Login