import { Box, Button, Divider, Input, TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import SERVER from '../../../endPoint'
import InputCustom from './input'
import './style.scss'

function Index() {
    const history = useHistory()
    const [input, setInput] = useState({
        PCR: '',
        konfirmasi: '',
        sembuh: '',
        meninggal: '',
        konfirmasiSulteng: '',
        aktifSulteng: '',
        sembuhSulteng: '',
        meninggalSulteng: ''
    })

    function onChangeInput(event) {
        let { name, value } = event.target

        setInput({
            ...input,
            [name]: value
        })

    }

    function submit() {
        Axios({
            method: 'POST',
            url: `${SERVER}/data-absolute`,
            data: input,
            headers: {
                token: localStorage.getItem('token_access')
            }
        })
        .then(({ data }) => {
            history.push('/data-absolute')
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showDay = (date) => {
        let hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()

        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month + ' ' + year
    }

    return (
        <>
            <div className="alert-component-container" >
                <Box display='flex' flexDirection='column'>
                    <span className="absolute-text-top" >
                        Input Data Absolute
            </span>
                    <span className='date-absolute'>
                    {showDay(new Date())}, {showDate(new Date())}
            </span>
                </Box>

                <div style={{ width: '800px' }}>
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Jumlah Tes PCR"}
                        placeholder={"Jumlah Tes PCR"}
                        code={"PCR"}
                        type={"number"}
                    />
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Terkonfirmasi Sulteng"}
                        placeholder={"Jumlah Terkonfirmasi di Sulteng"}
                        code={"konfirmasiSulteng"}
                        type={"number"}
                    />
                    <Box className='dividerInput'>
                        <Divider />
                    </Box>
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Kasus Konfirmasi"}
                        placeholder={"Jumlah Kasus Konfirmasi"}
                        code={"konfirmasi"}
                        type={"number"}
                    />
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Kasus Aktif Sulteng"}
                        placeholder={"Jumlah Kasus Aktif di Sulteng"}
                        code={"aktifSulteng"}
                        type={"number"}
                    />
                    <Box className='dividerInput'>
                        <Divider />
                    </Box>
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Sembuh"}
                        placeholder={"Jumlah yang Sembuh"}
                        code={"sembuh"}
                        type={"number"}
                    />
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Kasus Sembuh Sulteng"}
                        placeholder={"Jumlah Kasus Sembuh di Sulteng"}
                        code={"sembuhSulteng"}
                        type={"number"}
                    />
                    <Box className='dividerInput'>
                        <Divider />
                    </Box>
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Meninggal"}
                        placeholder={"Jumlah Kasus Meninggal"}
                        code={"meninggal"}
                        type={"number"}
                    />
                    <InputCustom
                        onChangeInput={onChangeInput}
                        title={"Kasus Meninggal Sulteng"}
                        placeholder={"Jumlah Kasus Meninggal di Sulteng"}
                        code={"meninggalSulteng"}
                        type={"number"}
                    />
                </div>
            </div>
            <Box className='dividerInput'>
                <Divider />
            </Box>
            <Box display='flex' paddingBottom="20px">
                <Box width='130px'>
                    <Button color='secondary' fullWidth variant='contained' style={{ textTransform: 'none', height: '50px' }} onClick={submit}>Simpan</Button>
                </Box>
                <Box width='130px' marginLeft="20px">
                    <Button color='secondary' variant='outlined' fullWidth style={{ border: "1px solid #DBDBDB", textTransform: 'none', height: '50px' }} onClick={() => [history.push('/data-absolute')]}>Batal</Button>
                </Box>
            </Box>
        </>
    )
}

export default Index
