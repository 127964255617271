import React from 'react'

import './style.scss'
import './mobile.scss'

function ThirdContent () {

    return (
        <div className="mlp-345-third-content-container">

            <div className="mlp-345-third-content">
                
                <div className="tc-only-mobile-345">

                    <div className="tc-345-left-title">
                        PT. Optimus Digital Indonesia
                    </div>

                    <div className="tc-345-left-body">
                        Gedung Nissi Bintaro Jl. Tegal Rotan Raya No.78, 
                        Bintaro Jaya Sektor VII, Kota Tangerang Selatan, 
                        Indonesia 15413, info@optimusdigital.id
                    </div>

                </div>

                <div className="tc-345-title">
                    © 2020 Optimus Digital • All rights reserve
                </div>

            </div>
        </div>
    )

}

export default ThirdContent