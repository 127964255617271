// MODULE
import React from 'react'

import LeftContent from './LeftContent'
import RightContent from './RightContent'

// STYLING
import './style.scss'


function Content (props) {

    let {
        news
    } = props

    return (
        <div className="news-content-container" style={{...props.style}}>
            
            <LeftContent news={news} />

            <RightContent news={news}/>

        </div>
    )

}

export default Content