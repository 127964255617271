import React, { useState } from 'react'

// IMAGE
import Banggai from '../../../../images/kab-banggai-logo.png'
import Poso from '../../../../images/kab-poso-logo.svg'

// STYLING
import './style.scss'


function LeftContent () {
    const [banggai] = useState(true)
    const [poso] = useState(false)
    const [Buol] = useState(false)

    return (
        <div className="header-867-left-content">

                <img
                    className="hlc-867-one"
                    src={banggai ? Banggai : poso ? Poso : Buol && Poso}
                />

                <div className="hlc-867-two">
                    {
                        banggai &&
                        <div className="hlc-867-two-1">
                            Pemerintah Kabupaten Banggai
                        </div>
                    }
                    {
                        poso &&
                        <div className="hlc-867-two-1">
                            Pemerintah Kabupaten Poso
                        </div>
                    }

                    <div className="hlc-867-two-2">
                        Gugus Tugas Covid 19
                    </div>

                </div>

            </div>

    )

}

export default LeftContent