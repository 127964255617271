// MODULE
import React from 'react'
import { useDispatch , useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputWithDate (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        code2,
        type,
        isDisable,
        stars
    } = props

    // GLOBAL STATE
    const dataGlobal = useSelector(state=>state.individualState)

    // DISPATCH
    const dispatch = useDispatch()
    
    let changeInput = (value,code) => {
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : value}))
    }

    return (

        <div className="input-with-data-container-612" >

            <div className="iwd-title-612">
                {title}
                {
                    stars &&
                    <p style={{
                        color: "red",
                        marginLeft: 2
                    }} >
                        *
                    </p>
                }
            </div>

            <div className="iwd-container-dateinput-61221">
                
                <DatePicker 
                    // minDate={new Date()} 
                    // className="pu-input-time" 
                    className="iwd-input-61213" 
                    // style={ { border: 'none' } } 
                    value={ dataGlobal[code] ? dataGlobal[code] : 'Pilih Tanggal' }
                    maxDate={new Date()}
                    disabled={ isDisable ? true : false}
                    dateFormat="MMMM d, yyyy" 
                    placeholderText="Tanggal"
                    onChange={ date => changeInput(date,code) } 
                    selected={ dataGlobal[code] && dataGlobal[code] !== "-" ? new Date(dataGlobal[code]) : new Date()}
                />

            </div>

    
        </div>

    )

}

export default InputWithDate