import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    infoBtn:{
        // width: 'px',
        height: '20px',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        marginLeft: -80,
        marginRight: 10,
        paddingTop: 0,
        fontWeight: 'bold'
    }
}));

function FirstContent (props) {
    const classes = useStyles();
    const history = useHistory()
    const {
        // dataCount,
        dataRealtime,
        dataKecamatan,
        dataDesa,
        dataHarian
    } = props

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })


    return (
        <div className="lpk-container-box-content-867" style={{ marginTop :  "26px" }}>

            <div className="bc-triple-per-content-597" style={{backgroundColor : "#2224C8",width : !isTabletOrMobile ? "48.2%" : null }}>

                <div className="bct-first-content-597">

                    <div className="bct-first-content-item-597">

                        <div className="bct-new-content-first-597"
                            style={
                                {
                                    display: "flex",
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }
                            }
                        >

                            <div className="bct-ncf-title1-597" style={{ fontSize: 50, marginTop: 5 }}>
                                {/* { dataCount && dataCount.kecamatan } */}
                                { dataKecamatan }
                            </div>

                            <div className="bct-ncf-title2-597" style={{ marginTop: 15 }}>
                                Kecamatan
                            </div>

                        </div>

                    </div>

                    <div className="bct-fc-hr-597">
                    </div>   

                    <div className="bct-first-content-item-597">

                    <div 
                        className="bct-new-content-first-597" 
                        style={
                                {
                                    display: "flex",
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }
                            }
                    >

                    <div className="bct-ncf-title1-597" style={{ fontSize: 50, marginTop: 5 }}>
                        {/* { dataCount && dataCount.desa } */}
                        {/* { dataDesa } */}
                        337
                    </div>

                    </div>

                        {/* <div className="bct-ncf-button-597" style={{marginLeft : "-20px"}}
                            style={{ cursor: 'pointer' }}
                        >
                            Lihat
                        </div> */}

                    </div>

                </div>

                <div 
                className="bct-second-content-597"
                style={{
                    flexDirection : "row",
                    // justifyContent : "space-evenly",
                    // justifyContent : "center",
                    alignItems : "center",
                    width : "100%",
                }}
            >
                <div 
                    className="lpk-content-second-867" 
                    // style={{ marginRight: isTabletOrMobile ? -50 : -100 }}  
                >
                    Kabupaten Banggai
                </div>
                <div className="bct-ncf-button-867"
                    style={{ cursor: 'pointer' }}
                    onClick={()=> history.push('/landing-page-kecamatan') }
                >
                    Lihat
                </div>
            </div>

            </div>

            <div className="bc-triple-per-content-597" style={{backgroundColor : "#B82323",width : !isTabletOrMobile ? "48.2%" : null}}>

                <div className="bct-first-content-597" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >

                    <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Hari Ini
                        </div>

                        <div className="bc-majority-title2-597">
                            {/* { dataCount && dataCount.hariIni && dataCount.hariIni.positif } */}
                            {
                                dataHarian && dataHarian.positif &&
                                dataHarian.positif.perawatan +
                                dataHarian.positif.isolasi +
                                dataHarian.positif.sembuh +
                                dataHarian.positif.meninggal
                            }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }} >
                        <div className="bc-majority-title1-597">
                            Dalam Perawatan
                        </div>

                        <div className="bc-majority-title2-597">
                            {/* { dataCount && dataCount.positif && dataCount.positif.perawatan } */}
                            { dataRealtime && dataRealtime.positif && dataRealtime.positif.perawatan }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Di Ruang Isolasi
                        </div>

                        <div className="bc-majority-title2-597">
                            {/* { dataCount && dataCount.positif && dataCount.positif.isolasi } */}
                            { dataRealtime && dataRealtime.positif && dataRealtime.positif.isolasi }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597" style={{ width: '20%' }}>
                        <div className="bc-majority-title1-597">
                            Sembuh
                        </div>

                        <div className="bc-majority-title2-597">
                            {/* { dataCount && dataCount.positif && dataCount.positif.sembuh } */}
                            { dataRealtime && dataRealtime.positif && dataRealtime.positif.sembuh }
                        </div>
                    </div>

                    <div className="bc-majority-hr-597" style={{backgroundColor : "#CD4F4F"}}>

                    </div>

                    <div className="bct-majority-597">
                        <div className="bc-majority-title1-597">
                            Meninggal
                        </div>

                        <div className="bc-majority-title2-597">
                            {/* { dataCount && dataCount.positif && dataCount.positif.meninggal } */}
                            { dataRealtime && dataRealtime.positif && dataRealtime.positif.meninggal }
                        </div>
                    </div>

                </div>

                <div className="bct-second-content-597 border-top-lpk-type-1-867" >
                    <div className="bct-sc-title1-597">
                    {/* {
                        dataCount && dataCount.positif &&
                        dataCount.positif.perawatan +
                        dataCount.positif.isolasi +
                        dataCount.positif.sembuh +
                        dataCount.positif.meninggal
                    } Orang */}
                    {"Total "}
                    {
                        dataRealtime && dataRealtime.positif &&
                        dataRealtime.positif.perawatan +
                        dataRealtime.positif.isolasi +
                        dataRealtime.positif.sembuh +
                        dataRealtime.positif.meninggal
                    } Orang

                    </div>
                    <div className="bct-sc-title2-597">
                        Konfirmasi Positif
                    </div>
                </div>

            </div>

        </div>
    )
}

export default FirstContent