import React,{ useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {  useSelector } from 'react-redux'
import './styles.scss'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import axios from 'axios'
import SERVER from '../../endPoint'
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker'

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    loginBtn:{
        width: '68px',
        height: '25px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
        marginTop: '-10px'
    },
    aksiBtn:{
        width: '80px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
    },
    tableContainer:{
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
    },
    tableHead:{
        backgroundColor: '#ffffff'
    },
    headerTable1:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.3%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '3%',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableRow:{
        borderBottom: 'none'
    },
    tableCell1:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
        paddingLeft: 30
    },
    tableCell:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
    },
    tableCell2:{
        paddingTop: 10,
        paddingBottom: 10
    },
    btnDetail:{
        width: '91px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '14px',
        border: 'solid 1px #e8e8e8',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        color: '#0185ff',
    },
    searchIcon: {
        color: "#999999"
    }
}));

export default function AlertZona () {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchDate, setDateSearch] = useState(null)
    const [allUsers, setAllUsers] = useState([])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/patient`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            console.log(data)
            setAllUsers(data)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    useEffect(()=>{
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            if (data.role === 'patient') {
                history.replace('/landing-page-kabupaten')
            } else if (data.role === 'admin-berita') {
                history.replace('/input-berita')
            } else if (data.role === 'petugas') {
                history.replace('/data-individu')
            }
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    },[])

    
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    return (
        <div className="alert-component-container" >
            <span className="beranda-text-top" >
                Data Input Post Lapangan
            </span>
            <div className="search-guest-content-first-3" style={{ marginTop: 22 }} >
                <DatePicker 
                    className="date-search-picker" 
                    selected={ searchDate } 
                    dateFormat="d MMMM yyyy" 
                    placeholderText="Cari Tanggal"
                    onChange={ date => setDateSearch(date) } 
                />
                <div className="container-for-search-icon-first-2">
                    <EventIcon className={classes.searchIcon}/>
                </div>
            </div>
            <div className="table-alert-list">
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tableHead}>
                                <TableCell className={classes.headerTable1}>
                                    <div className="beranda-row-nomor">NO</div>
                                </TableCell>
                                <TableCell className={classes.headerTable} >
                                    <div className="beranda-row-nomor">NAMA PENGINPUT</div>
                                </TableCell>
                                <TableCell className={classes.headerTable}>
                                    <div className="beranda-row-nomor">ASAL PENGINPUT</div>
                                </TableCell>
                                <TableCell className={classes.headerTable}>
                                    <div className="beranda-row-nomor">JAM/TANGGAL INPUT</div>
                                </TableCell>
                                <TableCell className={classes.headerTable}>
                                    <div className="beranda-row-nomor">NAMA YANG DIINPUT</div>
                                </TableCell>
                                <TableCell className={classes.headerTable}>
                                    <div className="beranda-row-nomor">STATUS</div>
                                </TableCell>
                                <TableCell className={classes.headerTable}>
                                    <div className="beranda-row-nomor">AKSI</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            allUsers.length > 0 &&
                            allUsers
                                .filter(users=> searchDate ? showDate(users.createdAt) === showDate(searchDate) : allUsers)
                                .sort((a,b) =>  new Date(b.createdAt) - new Date(a.createdAt))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((user, index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell component="th" scope="row" align="left" className={classes.tableCell1}>
                                        { index+=1 }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {user.namaPetugas}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {user.posAsal}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <div className="tablecell-alert-waktu" >
                                            <span className="tablecell-alert-waktu-text-top">
                                                {"11.20 WITA "}
                                            </span>
                                            <span className="tablecell-alert-waktu-text-top">
                                                Selasa, 5 Mei 2020
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {user.namadiKtp}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell2}>
                                        {
                                            !user.status ?
                                            <span className="red-color-angga">
                                                Belum Ada Status
                                            </span>
                                            :
                                            <span>
                                                {user.status}
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Button className={classes.btnDetail} onClick={()=>history.push('/data-individu-list/'+user._id)} >
                                            Detail
                                        </Button>   
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination
                                    labelRowsPerPage='Tampilan per halaman:'
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={7}
                                    count={allUsers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}` }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{textAlign : "right"}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}