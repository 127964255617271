// MODULES
import React , { useState } from 'react'
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'


// ENDPOINT
import SERVER from '../../../../endPoint/index'

import './first.scss'
import './second.scss'

function AnswerBox (props) {

    const { title , placeHolder , answerItem, setDataQA ,dataQA ,id , lastIndex  , isFirstIndex , question } = props

    // EDIT FORM
    const [isEditForm,setIsEditForm] = useState(false)
    const [inputAnswer,setInputAnswer] = useState(null)
    const [editAnswer,setEditAnswer] = useState(null)

    let handleAddData = (e) => {
        e.preventDefault()
        let result = [...dataQA]
        result.forEach((el,index)=>{
            if (el.id === id) {
                el.answer.forEach(el2=>{
                    if (el2.id === answerItem.id) {
                        el2.value = inputAnswer
                    }
                })
                // console.log(el , ' <<<< ELEMET >>>> looping')
            }
        })
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })
        // setDataQA(result)
    }

    let addNewAnswerForm = (e) => {
        e.preventDefault()
        let result = [...dataQA]
        result.forEach(el=>{
            if (el.id === id) {
                let lastId = el.answer[el.answer.length-1].id + 1
                el.answer.push({id : lastId , value : null })
            }
        })
        console.log(result , ' <<< VALUE RESULT')
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })
        // setDataQA(result)
    }

    let cancelAddNewAnswer = (e) => {

        let selectedArray;
        dataQA.forEach((el,index)=>{
            if (el.id === id) {
                selectedArray = el.answer
            }
        })
        e.preventDefault()
        if (isFirstIndex && selectedArray.length === 1) {
            let result = [...dataQA]
            result.forEach((el,index)=>{
                if (el.id === id) {
                    el.answer = [ {id : 1, value : null} ]
                }
            })
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting2`,
                data : {
                    key : "diagnosaData" , value : result
                }
            })
            .then((data)=>{
                setDataQA(result)
            })
            .catch(err=>{
                console.log(err , ' <<< ERROR')
            })
            // setDataQA(result)
        }else {
            let result = [...dataQA]
            result.forEach((el,index)=>{
                if (el.id === id) {
                    el.answer = el.answer.filter(el2=>el2.id !== answerItem.id)
                }
            })
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting2`,
                data : {
                    key : "diagnosaData" , value : result
                }
            })
            .then((data)=>{
                setDataQA(result)
            })
            .catch(err=>{
                console.log(err , ' <<< ERROR')
            })
            // setDataQA(result)
        }

        setIsEditForm(false)
        setInputAnswer(null)
        setEditAnswer(null)
        // result = result.filter(el)
    }

    let editContent = (e) => {
        e.preventDefault()
        let result = [...dataQA]
        result.forEach(el=>{
            if (el.id === id) {
                el.answer.forEach(el2=>{
                    if (el2.id === answerItem.id) {
                        el2.value = editAnswer
                    }
                })
            }
        })
        setIsEditForm(false)
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })
        // setDataQA(result)
    }

    if (answerItem.value) {
        return (
            <form onSubmit={e=>editContent(e)} className="ab-edit-container-225">

                <div 
                    className="ab-input-title-225"
                >
                    {title}
                </div>

                {
                    isEditForm ?
                    <input 
                        type="text" 
                        className="ab-input-edit-form-225" 
                        placeholder={placeHolder}
                        onChange={e=>setEditAnswer(e.target.value)}
                        value={editAnswer}
                        // onChange={e=>setEditedDataForm(e.target.value)}
                        // value={editedDataForm}
                    /> :
                    <div className="ab-edit-value-225">

                        {
                            answerItem.value
                        }

                    </div>
                }


                <div className="ab-edit-delete-container-225" >

                    {
                        isEditForm?
                        
                        <div 
                            className="ab-plus-input-delete-form-225" 
                            onClick={e=>setIsEditForm(false)}
                        >
                                Batal
                        </div> :

                        <div 
                            className="ab-circle-edit-content-225" 
                            onClick={e=>[setIsEditForm(true),setEditAnswer(answerItem.value)]}
                        >
                            <CreateIcon style={{color : "#c4c4c4"}}/>
                        </div>
                    }

                    {
                        isEditForm ?
                        <div 
                            className="ab-plus-input-save-form-225"
                            // onClick={e=>saveToDb(e)}
                            // onClick={e=>editFormForTypeOne(e)}
                            onClick={e=>editContent(e)}
                        >
                            Simpan
                        </div> : 
                        <div 
                            className="ab-circle-delete-content-225" 
                            // onClick={e=>deleteValue(e)}
                            onClick={e=>cancelAddNewAnswer(e)}
                        >
                            <DeleteIcon style={{color : "#c4c4c4"}}/>
                        </div>
            
                    }

                    {
                        lastIndex && !isEditForm &&
                        <div 
                            className="ab-plus-input-delete-form-225" 
                            style={{marginLeft : "27px",width : "178px"}}
                            onClick={e=>addNewAnswerForm(e)}
                        >
                            Tambah Jawaban
                        </div>
                    }


                </div>

            </form>
        )
    }

    return (
        <form 
            onSubmit={e=>handleAddData(e)}
            className="ab-input-container-225" 
            key={answerItem.id}
        >
                
            <div className="ab-input-title-225">
                {title}
            </div>

            <input 
                type="text"
                placeholder={placeHolder} 
                className="ab-input-form-225" 
                onChange={e=>setInputAnswer(e.target.value)}
                disabled={!question ? true : false}
            />

            {
                !isFirstIndex &&
                <div 
                    onClick={e=>cancelAddNewAnswer(e)}
                    className="ab-plus-input-delete-form-225"
                    style={{
                        marginLeft : !isFirstIndex ? "12px" : null
                    }}
                >
                    Batal
                </div>
            }

            <div 
                className="ab-input-button-225" 
                onClick={e=>handleAddData(e)}
                style={{
                    marginLeft : !isFirstIndex ? "12px" : null
                }}
            >
                Simpan
            </div>
    
        </form>
    )

}

export default AnswerBox;