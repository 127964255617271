import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'


//COMPONENT
import Header from '../LandingPageKabupaten/Header'
import NavbarMobile from '../../components/NavbarMobile'
import Content from './Content'
import FooterContent from '../../components/FooterContent1'
import {useHistory} from 'react-router-dom'

import axios from 'axios'
import SERVER from '../../endPoint'
// STYLING
import './style.scss'
import './mobile.scss'

// EDITOR
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';

function DetailBerita ({ match }) {
    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    const [imageReview, setImageReview] = useState(null)
    const [judul, setJudul] = useState(null)
    const [isiBerita, setIsiBerita] = useState(null)
    const [tanggal, setTgl] = useState(null) 
    const [newsList, setNewsList] = useState(null)

    useEffect(()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        axios({
            method : "GET",
            url : `${SERVER}/berita/${match.params.id}`,
        })
        .then(({data})=>{
            setImageReview(data.bannerImage)
            setJudul(data.judulBerita)
            setTgl(data.updatedAt)
            setIsiBerita(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(data.isiBerita)
                )
            ))
        })
        .catch(err=>{
            console.log(err)
        })
    },[history.location.pathname])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/berita`,
        })
        .then(({data})=>{
            setNewsList(data)
            console.log(data,'*&*&(&*(&&*(&*&')
        })
        .catch(console.log)
    },[])

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return  (
        <div className="detail-berita-418">
            {
                isTabletOrMobile ? <NavbarMobile/> : <Header/>
            }
            {
                !isTabletOrMobile &&
                <div className="db-418-title">
                    <div style={{marginRight : "5px"}}>Berita</div> <span style={{marginRight : "5px"}}>></span>  Detail Berita
                </div>
            }

            <div className="db-418-title-2">
                { judul }
            </div>

            <div className="db-418-title-3">
                <span>
                    <span className="green-day112" >
                        {showDay(tanggal) }
                    </span>
                    , {showDate(tanggal)}/
                    {showHour(tanggal)}
                </span>
            </div>

            <Content
                isiBerita={isiBerita}
                imageReview={imageReview}
                newsList={newsList}
                id={match.params.id}
                showDay={showDay}
                showDate={showDate}
                showHour={showHour}
            />

            <FooterContent/>

        </div>
    )

}

export default DetailBerita