import React from 'react'

function FourthParagraph () {
    return (
        <div className="dpc-word-content-254">
            <p style={{fontWeight : "bold",marginTop : "0"}}>Perlindungan untuk semua orang</p>
            <p>
                Tetap ikuti informasi terbaru tentang wabah COVID-19 yang tersedia di situs web WHO dan melalui Kementerian Kesehatan dan Dinas Kesehatan daerah Anda. Di banyak negara di dunia, kasus dan bahkan wabah COVID-19 telah terjadi. Pemerintah Tiongkok dan pemerintah beberapa negara lain telah berhasil memperlambat atau menghentikan wabah yang terjadi di wilayahnya. Namun, situasi yang ada masih sulit diprediksi. Karena itu, tetaplah ikuti berita terbaru.
                Anda dapat mengurangi risiko terinfeksi atau menyebarkan COVID-19 dengan cara melakukan beberapa langkah pencegahan:
            </p>
            <p>
                1. Seringlah mencuci tangan Anda dengan air bersih mengalir dan sabun, 
                atau cairan antiseptik berbahan dasar alkohol. Mengapa? Mencuci tangan dengan air bersih yang mengalir dan sabun, atau cairan antiseptik berbahan dasar alkohol dapat membunuh virus di tangan Anda.
            </p>
            <p>
                2. Jaga jarak setidaknya 1 meter dengan orang yang batuk-batuk atau bersin-bersin. 
                Mengapa? Ketika batuk atau bersin, orang mengeluarkan percikan dari hidung atau mulutnya dan percikan ini dapat membawa virus. Jika Anda terlalu dekat, Anda dapat menghirup percikan ini dan juga virus COVID-19 jika orang yang batuk itu terjangkit penyakit ini.
            </p>
            <p>
                3. Hindari menyentuh mata, hidung, dan mulut. Mengapa? Tangan menyentuh berbagai 
                permukaan benda dan virus penyakit ini dapat tertempel di tangan. Tangan yang terkontaminasi dapat membawa virus ini ke mata, hidung atau mulut, yang dapat menjadi titik masuk virus ini ke tubuh Anda sehingga Anda menjadi sakit.
            </p>
            <p>
                4. Pastikan Anda dan orang-orang di sekitar Anda mengikuti etika batuk dan 
                bersin dengan cara menutup mulut dan hidung dengan siku terlipat atau tisu saat batuk atau bersin dan segera buang tisu bekas tersebut. Mengapa? Percikan dapat menyebarkan virus. Dengan mengikuti etika batuk dan bersin, Anda melindungi orang-orang di sekitar dari virus-virus seperti batuk pilek, flu dan COVID-19.
            </p>
            <p>
                5. Tetaplah tinggal di rumah jika merasa kurang sehat. Jika Anda demam, batuk dan kesulitan bernapas, segeralah cari 
                pertolongan medis dan tetap memberitahukan kondisi Anda terlebih dahulu. Ikuti arahan 
                Dinas Kesehatan setempat Anda. Mengapa? Kementerian Kesehatan dan Dinas Kesehatan daerah akan memiliki informasi terbaru tentang situasi di wilayah Anda. Dengan memberitahukan kondisi Anda terlebih dahulu, petugas kesehatan yang akan merawat Anda dapat segera mengarahkan Anda ke fasilitas pelayanan kesehatan yang tepat. Langkah ini juga melindungi Anda dan membantu mencegah penyebaran virus dan infeksi lainnya.
            </p>
            <p>
                6. Tetap ikuti informasi terbaru tentang hotspot-hotspot COVID-19 (kota atau daerah di mana COVID-19 menyebar luas). 
                Jika memungkinkan, hindari bepergian ke tempat-tempat tersebut – terutama jika Anda sudah berusia lanjut atau mengidap diabetes, sakit jantung atau paru-paru Mengapa? Kemungkinan tertular COVID-19 lebih tinggi di tempat-tempat tersebut.
            </p>
            <p style={{fontWeight : "bold"}}>
                Perlindungan jika sedang berada di atau pernah berkunjung ke (dalam waktu 14 hari terakhir) wilayah di mana COVID-19 menyebar
            </p>
            <p>
                1. Ikuti panduan di atas (Perlindungan untuk semua orang)
            </p>
            <p>
                2. Lakukan isolasi diri dengan cara tetap tinggal di rumah jika Anda mulai merasa kurang sehat, bahkan jika gejalanya ringan seperti sakit kepala, demam berskala rendah (37.3 C atau lebih) dan pilek ringan, sampai Ana sembuh. Jika orang lain harus membawakan Anda persediaan atau jika Anda harus keluar, misal untuk membeli makanan, kenakanlah masker agar tidak menginfeksi orang lain.
                Mengapa? Jika Anda tidak melakukan kontak fisik dengan orang lain dan tidak mengunjungi fasilitas medis, diri Anda dan orang lain akan terlindung dari virus COVID-19 dan lainnya dan fasilitas kesehatan akan dapat beroperasi lebih efektif.              
            </p>
            <p style={{marginBottom : "0"}}>
                3. Jika Anda demam, batuk dan kesulitan bernapas, segera minta nasihat dokter karena kondisi ini 
                bisa jadi dikarenakan infeksi saluran pernapasan atau kondisi serius lainnya. Jika Anda sudah memberitahukan kondisi Anda terlebih dahulu, petugas kesehatan dapat lebih cepat mengarahkan Anda ke fasilitas kesehatan yang tepat. Hal ini juga membantu mencegah kemungkinan penyebaran COVID-19 dan virus-virus lainnya.
            </p>
        </div>
    )
}

export default FourthParagraph