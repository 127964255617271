import React,{ useState, useEffect } from 'react'
import './style.scss'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import axios from 'axios'
import SERVER from '../../endPoint'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    loginBtn:{
        width: '68px',
        height: '25px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
        marginTop: '-10px'
    },
    headerTable1:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.3%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable2:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '7%',
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerTable:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '3%',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableCell1:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
        paddingLeft: 30
    },
    tableCell:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
    },
    tableHead:{
        backgroundColor: '#ffffff'
    },
    tableContainer:{
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
    },
    searchIcon:{
        color : "#888888",
        marginRight : "5%",
        paddingLeft: '5%'
    },
    insideRow:{
        borderBottom: 'none'
    }
}));

export default function Beranda({ match }){
    const classes = useStyles();
    const history = useHistory()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [listDesa, setListDesa] = useState([])
    const [searchValue, setSearchValue] = useState('')

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/kecamatan/${match.params.id}`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setListDesa(data.desa)
        })
        .catch(err=>{
            console.log(err , '')
        })
    },[])

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    function showDay (date) {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    return (
        <div className="beranda-container">

            <div className="back-container" >
                <ArrowBackIosIcon className="back-icon" onClick={()=>history.push('/beranda/kecamatan')}/>
                <span className="back-text" onClick={()=>history.push('/beranda/kecamatan')} >
                    Kembali
                </span>
            </div>

            <span className="beranda-text-top" >
                Desa/Kelurahan, { showDay(new Date()) } - {showDate(new Date())}
            </span>

            <div className="search-guest-content-first-1" >
                <input className="input-search-first-guest-1" onChange={(e)=>setSearchValue(e.target.value)} placeholder="Cari Daftar Undangan"/>
                <div className="container-for-search-icon-first-1">
                    <SearchIcon className={classes.searchIcon}/>
                </div>
            </div>

            <div className="table-alert-list">
                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead} >
                            <TableRow className={classes.tableHead} >
                                <TableCell className={classes.headerTable1} >
                                    <div className="beranda-row-nomor">NO</div>
                                </TableCell>
                                <TableCell className={classes.headerTable2} >
                                    <div className="beranda-row-nomor">NAMA DESA</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">PELAKU PERJALANAN</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">OTG</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">ODP</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">PDP</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">KONFIRMASI POSITIF</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">POSITIF MENINGGAL</div>
                                </TableCell>
                                <TableCell align="center" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">PASIEN SEMBUH</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        listDesa.length > 0 &&
                            listDesa
                                .filter(desa=> (desa.nama).toLowerCase().includes((searchValue).toLowerCase()))
                                .sort((a,b) => (a.nama < b.nama) ? 1 : ((b.nama < a.nama) ? -1 : 0))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((desa, index) => (
                                <TableRow key={index} className={classes.insideRow}>
                                    <TableCell component="th" scope="row" align="left" className={classes.tableCell1} >
                                        { index+=1 }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { desa.nama }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.perjalanan }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.otg }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.odp }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.pdp }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.positif }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.meninggal }
                                    </TableCell>
                                    <TableCell align="center" className={classes.tableCell}>
                                        { desa.sembuh }
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination
                                    labelRowsPerPage='Tampilan per halaman:'
                                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                                    colSpan={9}
                                    count={listDesa.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}` }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{textAlign : "right"}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>

        </div>
    )
}