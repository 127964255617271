import { SET_DATA_INDIVIDUAL, SET_HELTY_RESET, SET_RESET_INDIVIDUAL } from '../constanta'

const INITIAL_STATE = {
    namadiKtp : null,
    namaPanggilan : null,
    status : null,
    subStatus : null,
    noHp : null,
    NIKDanPassport : null,
    usia : null,
    jenisKelamin : null,
    alamatDiKtp : null,
    alamatSaatIni : null,
    propinsi : null,
    kabupaten : null,
    kecamatan : null,
    kelurahan : null,
    pekerjaan : null,
    instansi : null,
    kondisiSaatIni : null,
    jenisSakit : null,
    keluhan : null,
    kotaAsal : null,
    pintuMasuk : null,
    transportasikeKota : null,
    transit : null,
    tanggalMasuk : null,
    jamMasuk : null,
    alamatTujuan : null,
    isolasiMandiri : null,
    tanggalStatus : null
}

function individualState (state = INITIAL_STATE , action) {
    switch (action.type) {
        case SET_DATA_INDIVIDUAL:
            return {  ...state , [action.payload.key] : action.payload.value }
        case SET_HELTY_RESET:
            return { ...state, jenisSakit : null, keluhan : null }
        case SET_RESET_INDIVIDUAL:
            return {
                ...state,
                namadiKtp : null,
                namaPanggilan : null,
                status : null,
                subStatus : null,
                noHp : null,
                NIKDanPassport : null,
                usia : null,
                jenisKelamin : null,
                alamatDiKtp : null,
                alamatSaatIni : null,
                propinsi : null,
                kabupaten : null,
                kecamatan : null,
                kelurahan : null,
                pekerjaan : null,
                instansi : null,
                kondisiSaatIni : null,
                jenisSakit : null,
                keluhan : null,
                kotaAsal : null,
                pintuMasuk : null,
                transportasikeKota : null,
                transit : null,
                tanggalMasuk : null,
                jamMasuk : null,
                alamatTujuan : null,
                isolasiMandiri : null
            }
        default: return state
    }
}

export default individualState