import React , {useState} from 'react'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
// COMPONENT
import BoxContent from './BoxContent'

// STYLE
import './style.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// OTHER
import SERVER from '../../endPoint'
import { useEffect } from 'react';

function Berita ({ match }) {
    const history = useHistory()
    // const [dataTitlte,setDataTitle] = useState('')
    const [imageFile,setImageFile] = useState(null)
    const [imageReview,setImageReview] = useState(null)
    const [text,setText] = useState("")
    const [judul,setJudul] = useState("")
    const [isiBerita,setIsiBerita] = useState("")

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/berita/${match.params.id}`,
        })
        .then(({data})=>{
            setImageReview(data.bannerImage)
            setJudul(data.judulBerita)
            setIsiBerita(data.isiBerita)
            setText(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(data.isiBerita)
                )
            ))
        })
        .catch(err=>{
            console.log(err)
        })
    },[])

    const onFileChange = (e) => {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        createImage(files[0]);
    }
    
    const createImage = file => {
        setImageFile(file)
        let image = new Image();
        let reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
            setImageReview(e.target.result)
        };
        reader.readAsDataURL(file);
    }

    let saveImage = (e) => {
        e.preventDefault()
        if (judul && isiBerita) {
            swal.showLoading()
            if (imageFile) {
                const formData = new FormData()
                formData.append('file',imageFile)
                formData.append('judulBerita',judul)
                formData.append('isiBerita',isiBerita)
                axios({
                    method : "PATCH",
                    url : `${SERVER}/berita/image/${match.params.id}`,
                    data :formData
                })
                .then(({data})=>{
                    swal.close()
                    swal.fire({
                        text: 'Berhasil Memperbarui Berita',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                    .then((result) => {
                        if (result.value) {
                            history.push('/view-berita')
                        } else if ( result.dismiss === swal.DismissReason.cancel ) {
                        }
                    })
                })
                .catch(err=>{
                    swal.close()
                    console.log(err , ' <<< WHY ERROR')
                })
            } else {
                let data = {
                    judulBerita : judul,
                    isiBerita : isiBerita
                }
                axios({
                    method : "PATCH",
                    url : `${SERVER}/berita/${match.params.id}`,
                    data : data
                })
                .then(({data})=>{
                    swal.close()
                    swal.fire({
                        text: 'Berhasil Memperbarui Berita',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                    .then((result) => {
                        if (result.value) {
                            history.push('/view-berita')
                        } else if ( result.dismiss === swal.DismissReason.cancel ) {
                        }
                    })
                })
                .catch(err=>{
                    swal.close()
                    console.log(err , ' <<< WHY ERROR')
                })
            }
        } else {
            swal.fire({
                text: 'Silahkan Lengkapi Berita',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }
    }

    let editoChange = (event) => {
        let editorState = event; 
        let editorSourceHTML = draftToHtml(convertToRaw(event.getCurrentContent()))
        setText(editorState)
        setIsiBerita(editorSourceHTML)
    }

    return (
        <div className="berita-container-608">
            
            <div className="berita-title-608">
                Berita Tips Sehat
            </div>

            <BoxContent/>

            <div className="berita-input-container-608" style={{marginTop : "48px"}}>
                <div className="berita-label-608"/>
                    {
                        imageReview &&
                        <img src={imageReview} style={{
                            width: "50%",
                            borderRadius: '4px'
                        }} alt="image"/>
                    }
            </div>
            <div className="berita-input-container-608">
                <div className="berita-label-608">
                    Foto Banner
                </div>
                <label for="files" className="label-608">{ imageReview ? 'Ganti Gambar' : 'Pilih Gambar' }</label>
                <input 
                    type="file" 
                    style={{visibility : "hidden"}} 
                    id="files" 
                    name="file" 
                    className="berita-file-608" 
                    placeholder={imageReview ? 'Ganti Gambar' : 'Pilih Gambar' } 
                    accept=".jpg,.png,.gif"
                    onChange={e=>onFileChange(e)}
                />
            </div>

            <div className="berita-input-container-608">
                <div className="berita-label-608">
                    Judul Berita
                </div>
                <input 
                    type="text" 
                    value={judul}
                    className="berita-input-608" 
                    placeholder={"Tulis Judul Berita "} 
                    onChange={(e)=> setJudul(e.target.value)}
                />
            </div>

            {/* <div className="berita-input-container-608">
                <div className="berita-label-608">
                    Kategori
                </div>
              
                <select className="berita-input-608">
                    <option disabled={true} style={{color : "#999999"}} selected={true}>Pilih Kategori Berita</option>
                    <option>s</option>
                    <option>s</option>

                </select>
            </div> */}

            <div id="input-berita-608" className="berita-input-container-608" style={{alignItems : "flex-start"}}>
                <div className="berita-label-608">
                    Isi Berita
                </div>
                {/* <input type="text"  className="berita-input-608 big-608" placeholder={"Tulis Konten Berita"} /> */}
                {/* <textarea className="berita-input-608 big-608"> */}

                {/* </textarea> */}
                <Editor
                    editorState={text}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="berita-input-609"
                    onEditorStateChange={e=>editoChange(e)}
                    placeholder="Ketik Disini"
                />
            </div>

            <div className="container-for-button-608" >
                <div className="button-input-608" onClick={e=>saveImage(e)}>
                    Simpan
                </div>
                <div className="button-input-608" onClick={e=>history.push('/view-berita')} style={{ marginLeft: 15 }} >
                    Batal
                </div>
            </div>
        </div>
    )

}

export default Berita;