// MODULE
import React from 'react'
import { useMediaQuery } from 'react-responsive'


// STYLE
import './style.scss'
import './mobile.scss'

// IMAGE
import Optimus1 from '../../../images/optimus.svg'
import Optimus2 from '../../../images/optimus2.svg'
import banggaiKab from '../../../images/kab-banggai-logo4.svg'
import diskes from '../../../images/kesehatan.svg'
import diskomInfo from '../../../images/diskominfo.svg'

function Footer () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    if (isTabletOrMobile) {
        return (
            <div className="lpg-footer-768-mobile">

                <div className="footer-768-hr-mobile">
                
                </div>
                
                <div className="footer-word-1-678-mobile">
                    {/* Hubungi Kami */}
                </div>

                <div className="footer-word-2-678-mobile">
                    &copy; Official Website Pemerintah Kabupaten Banggai 2020
                </div>

                <div className="footer-power-678-mobile">Didukung oleh:</div>

                <div className="footer-c3-678-img-mobile">
                    <img className="footer-optimus1-678-mobile" src={Optimus1}/>
                    <img className="footer-optimus2-678-mobile" src={Optimus2}/>

                </div>

                <div className="footer-power-678-mobile"></div>

                <div className="footer-c3-678-img-mobile">
                    <img className="footer-optimus1-678-mobile" src={banggaiKab}/>
                    <span className="mobile-ttext-fter" >
                        Pemerintah Kabupaten Banggai
                    </span>
                </div>

                <div className="footer-c3-678-img-mobile tup-uo-kobile">
                    <img className="footer-optimus1-678-mobile" src={diskes}/>
                    <span className="mobile-ttext-fter" >
                        Dinas Kesehatan Kabupaten Banggai
                    </span>
                </div>

                <div className="tup-uo-kobile-1">
                    <img className="footer-optimus1-678-mobile-313" src={diskomInfo}/>
                </div>

                {/* <div className="footer-c2-678-mobile">
                        2020 Optimus Digital • All rights reserved
                </div> */}

            </div>
        )
    }else {

        return (
            <div className="lpg-footter-23" >

                <div className="lpg-fter-079" >
                    <div className="txt-fftter-132" >
                        <span className="footer-txt-6123">
                            &copy; Official Website Pemerintah Kabupaten Banggai 2020
                        </span>
                    </div>

                    <div className="lpg-footer-768 lpg-footer-761231">
                        <span className="txt-footer-098">
                            Didukung Oleh :
                        </span>
                        <div className="footer-c3-678-img">
                            <img className="footer-optimus1-678" src={Optimus1}/>
                            <img className="footer-optimus2-678" src={Optimus2}/>
                        </div>
                    </div>

                    <div className="lpg-footer-768 lpg-footer-76812123">
                        <span className="txt-footer-098">
                            &nbsp;
                        </span>
                        <div className="footer-c3-678-img">
                            <img className="footer-optimus1-678" src={banggaiKab}/>
                            <span className="footer-txt-6123">
                                Pemerintah Kabupaten Banggai
                            </span>
                        </div>
                    </div>

                    <div className="lpg-footer-768 lpg-footer-231123">
                        <span className="txt-footer-098">
                            &nbsp;
                        </span>
                        <div className="footer-c3-678-img">
                            <img className="footer-optimus1-678" src={diskes}/>
                            <span className="footer-txt-6123">
                                Dinas Kesehatan Kabupaten Banggai
                            </span>
                        </div>
                    </div>

                    <div className="lpg-footer-768">
                        <span className="txt-footer-098">
                            &nbsp;
                        </span>
                        <div className="footer-c3-678-img">
                            <img className="footer-img-678" src={diskomInfo}/>
                            {/* <span className="footer-txt-6123">
                                Dinas Kesehatan Kabupaten Banggai
                            </span> */}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default Footer