// MODULE
import React , { useState } from 'react' 
import { useHistory } from 'react-router-dom'

// MATERIAL UI ICON
import MenuIcon from '@material-ui/icons/Menu';

// STYLING
import './style.scss'

// IMAGE
import banggai from '../../images/kab-banggai-logo.png'

function NavbarMobile (props) {

    const history = useHistory()

    const [isShowBurger,setIsShowBurger] = useState(false)

    return (
        <div className="nm-50-container-1" style={{...props.style, marginBottom: isShowBurger && props.marginBottomShow ? props.marginBottomShow : null}}>

            <div 
                className="nm-50-first-content"
                style={{height : isShowBurger ? "40px" : "60px"}}
            >

                <div className="nm-50-fc-1">

                    <img src={banggai} className="nm-50-fc-1-logo"/>

                    <div className="nm-50-fc-1-body">

                        <div className="fc-50-1-first">
                        Pemerintah Kabupaten Banggai
                        </div>

                        <div className="fc-50-1-second">
                            Gugus Tugas Covid 19
                        </div>

                    </div>

                </div>

                {
                    history.location.pathname !== '/login' &&
                    history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri/tanpa-status' &&
                    history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri/dengan-status'
                    &&
                    <div className="nm-50-fc-2" onClick={e=>setIsShowBurger(!isShowBurger)}>
                        <MenuIcon style={{color : "#CCCCCC",width : "30px" , height : "30px"}}/>
                    </div>
                }

            </div>
            {
                isShowBurger &&
                
                <div className="nm-burger-menu-50">

                    <div className="nm-bm-50" onClick={()=>history.push('/landing-page-kabupaten')}>
                        Beranda
                    </div>

                    {
                        history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri' &&
                        history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri/tanpa-status' &&
                        history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri/dengan-status' &&
                        <div className="nm-bm-50" onClick={()=>history.push('/landing-page-kabupaten/diagnosa-mandiri')}>
                            Diagnosa Mandiri
                        </div>
                    }

                    <div className="nm-bm-50" onClick={()=>history.push('/landing-page-berita')}>
                        Berita
                    </div>
                    
                    <div className="nm-bm-button-50-2" onClick={e=>history.push('/login')}>
                        Masuk
                    </div>

                </div>
            
            }
        </div>
    )
}

export default NavbarMobile