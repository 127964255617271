// MODULE
import React from 'react'
import { useDispatch } from 'react-redux'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputTypeOne (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        type,
        isBig,
        isDisable,
        value
    } = props


    // DISPATCH
    // const dispatch = useDispatch()
    
    // let changeInput = (e) => {
    //     // dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : e.target.value}))
    // }

    return (

        <form className="input-data-absolute-form" style={{alignItems : isBig ? "flex-start" : null}} onSubmit={e=>e.preventDefault()}>

            <div className="ito-title-670">
                <span>
                    {title}
                </span>
                
            </div>

            <input 
                disabled={isDisable ? true : false}
                onChange={props.onChangeInput}
                className="ito-input-670" 
                placeholder={placeholder}
                type={type}
                name={code}
                value={value}
                style={
                        {
                            height : isBig ? "76px" : null,
                            // marginTop : "20px",
                            // ma
                            // textAlign : "right"
                            paddingBottom : isBig ? "35px" : null,
                            // border : isDisable ? "0.5px solid #C2C3C7" : null
                        }
                }
            />
    
        </form>

    )

}

export default InputTypeOne