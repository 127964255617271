import { SET_DATA_INDIVIDUAL ,  SET_PATIENT_HEALTHY_STATUS , SET_PATIENT_TRANSIT_STATUS, SET_HELTY_RESET, SET_RESET_INDIVIDUAL } from '../constanta'

export const SET_DATA_INDIVIDUAL_ACTION = (data) => (dispatch)=> {
    dispatch({
        type : SET_DATA_INDIVIDUAL,
        payload : data
    })
}

export const SET_PATIENT_HEALTHY_STATUS_ACTION = (condition) => (dispatch) => {
    dispatch({
        type : SET_PATIENT_HEALTHY_STATUS,
        payload : condition
    })
}

export const SET_HELTY_RESET_ACTION = () => (dispatch) => {
    dispatch({
        type : SET_HELTY_RESET,
    })
}

export const SET_RESET_INDIVIDUAL_ACTION = () => (dispatch) => {
    dispatch({
        type : SET_RESET_INDIVIDUAL,
    })
}

export const SET_PATIENT_TRANSIT_STATUS_ACTION = (condition) => (dispatch) => {
    dispatch({
        type : SET_PATIENT_TRANSIT_STATUS,
        payload :  condition
    })
}