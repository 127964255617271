import React from 'react'
import './style.scss'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    infoBtn:{
        // width: 'px',
        height: '20px',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        marginLeft: -20,
        paddingTop: 0,
        fontWeight: 'bold'
    }
}));

    
function TableContent (props) {
    const classes = useStyles();
    const history = useHistory();
    const current = window
    console.log(current.innerWidth , ' <<< INNERWIDTH')
    console.log(current.outerWidth , ' <<< OUTER WIDTH')

    let {
        kecamatanList,
        searchDate,
        showDate
    } = props

    return (

        <table className="table-498" style={{width: '100%'}}>
            <tr>
                <th className="td-th-498 first-left-498 th-font-family-1 tb-bottom" colSpan={2} style={{ paddingLeft: 15, paddingTop: 4 }} >No</th>
                <th className="td-th-498  last-left-498 th-font-family-1 tb-bottom" colSpan={2} style={{ paddingLeft: 15, paddingRight:15 }} >Nama Kecamatan</th>
                {/* <th className="td-th-498  tb-right last-left-498 th-font-family-1 tb-bottom" colSpan={2} style={{ paddingLeft: 25, paddingRight:15 }} >Aksi</th> */}
                <th className="title-table-1-498-non-padding td-th-498 tb-right" rowSpan={6} colSpan={6}>
                    <div className="table-title-center-498 title-non-table-498 ">
                        Konfirmasi Positif
                    </div>
                    <div style={{display : 'flex',height : "40px" }} >
                        {/* <th className="double-table-48 title-bottom-non-table-498 "  >Total</th> */}
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingLeft: 15, width: 70, paddingTop: 15}}  >HARI INI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 20 }} >DALAM PERAWATAN</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20 }} >DI RUANG ISOLASI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 29 }} >SEMBUH</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 0 }} >MENINGGAL</th>
                    </div>
                </th>
                
                <th className="title-table-1-498-non-padding td-th-498 tb-right " rowSpan={6} colSpan={6}>
                    <div className="table-title-center-498 title-non-table-498 ">
                        Pasien dalam pemantauan (PDP) 
                    </div>
                    <div style={{display : 'flex',height : "40px" }} >
                        {/* <th className="double-table-48 title-bottom-non-table-498 "  >Total</th> */}
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingLeft: 15, width: 70, paddingTop: 15}} >HARI INI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 80 }} >NAIK POSITIF</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 20 }} >DALAM PERAWATAN</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 10, paddingLeft: 30 }} >NEGATIF</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 20 }} >BUKAN COVID 19</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 0, width: 70}} >SEMBUH</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 20 }} >MENINGGAL</th>
                    </div>
                </th>

                <th className="title-table-1-498-non-padding td-th-498 tb-right " rowSpan={6} colSpan={6}>
                    <div className="table-title-center-498 title-non-table-498 ">
                        ORANG DALAM PEMANTAUAN (ODP)
                    </div>
                    <div style={{display : 'flex',height : "40px" }} >
                        {/* <th className="double-table-48 title-bottom-non-table-498 "  >Total</th> */}
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingLeft: 15, width: 70, paddingTop: 15}} >HARI INI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 35}} >SEDANG DIPANTAU</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 70, paddingLeft: 20 }} >NAIK PDP</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20 }} >NAIK POSITIF</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingRight: 0, width: 70}} >SEMBUH</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15,  }} >MENINGGAL</th>
                    </div>
                </th>

                <th className="title-table-1-498-non-padding td-th-498 tb-right " rowSpan={4} colSpan={4}>
                    <div className="table-title-center-498 title-non-table-498 ">
                        ORANG TANPA GEJALA (OTG)
                    </div>
                    <div style={{display : 'flex',height : "40px" }} >
                    <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingLeft: 15, width: 70, paddingTop: 15}} >HARI INI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 70, paddingLeft: 20}} >NAIK ODP</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 70, paddingLeft: 20}} >NAIK PDP</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20}} >NAIK POSITIF</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15}} >SEDANG DIPANTAU</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20}} >SELESAI</th>
                    </div>
                </th>

                <th className="title-table-1-498-non-padding td-th-498 tb-right " rowSpan={4} colSpan={4}>
                    <div className="table-title-center-498 title-non-table-498 ">
                        PELAKU PERJALANAN/ORANG DENGAN RESIKO (ODR)
                    </div>
                    <div style={{display : 'flex',height : "40px" }} >
                    <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingLeft: 15, width: 70, paddingTop: 15}} >HARI INI</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 70, paddingLeft: 20}} >NAIK ODP</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, width: 70, paddingLeft: 20}} >NAIK PDP</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20}} >NAIK POSITIF</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15}} >SEDANG DIPANTAU</th>
                        <th className="double-table-48 title-bottom-non-table-498 " style={{ paddingTop: 15, paddingLeft: 20}} >SELESAI</th>
                    </div>
                </th>

            </tr>

            {/* TBODY 1 */}
            { console.log(kecamatanList, 'KECCCCC') }
            {
                kecamatanList &&
                kecamatanList
                .filter(harian=> searchDate ? (harian.date).toLowerCase() == showDate(searchDate).toLowerCase() : kecamatanList )
                .sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt) )
                .map((harian, index)=>(
                    <tbody key={index} >
                        <td className="td-th-498 first-left-498 td-font-family-1" colSpan={2} style={{ paddingLeft: 15 }}>{ index+=1 }</td>
                        <td className="td-th-498 last-left-498 td-font-family-1" colSpan={2} style={{ paddingLeft: 15 }} > { harian.name } </td>
                        {/* <td className="td-th-498 tb-right last-left-498 td-font-family-1" colSpan={2} style={{ paddingLeft: 30, paddingRight: 15 }} >
                            <Button type="submit" size="small" style={{ backgroundColor: "#0185FF" }} className={classes.infoBtn}
                                onClick={()=>history.push('/landing-page-desa/'+harian.id)}
                            >
                                Lihat
                            </Button>
                        </td> */}
                            
                        {/* KONFIRMASI POSITIF */}
                        
                        <td className="td-th-498-non-padding" rowSpan={6} colSpan={6}>

                            {/* <td className="double-table-48-2 text-center-498">
                            </td> */}
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }} >
                                { harian.positif.perawatan + harian.positif.isolasi + harian.positif.sembuh + harian.positif.meninggal}
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.positif.perawatan }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 35 }}>
                                { harian.positif.isolasi }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 30 }}>
                                { harian.positif.sembuh }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 15 }}>
                                { harian.positif.meninggal }
                            </td>
                        </td>

                        {/* PASIEN DALAM PEMANTAUAN */}

                        <td className="td-th-498-non-padding" rowSpan={6} colSpan={6}>

                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.pdp.meninggal + harian.pdp.naikPositif + harian.pdp.negatif + harian.pdp.nonCovid + harian.pdp.perawatan + harian.pdp.sembuh }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 12 }}>
                                { harian.pdp.naikPositif }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.pdp.perawatan }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 30 }}>
                                { harian.pdp.negatif }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 35 }}>
                                { harian.pdp.nonCovid }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.pdp.sembuh }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 15 }}>
                                { harian.pdp.meninggal }
                            </td>
                        </td>

                        {/* ORANG DALAM PEMANTAUAN (ODP) */}

                        <td className="td-th-498-non-padding" rowSpan={6} colSpan={6}>

                            {/* <td className="double-table-48-2 text-center-498">
                            </td> */}
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.odp.dipantau + harian.odp.meninggal + harian.odp.naikPDP + harian.odp.naikPositif + harian.odp.sembuh }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.odp.dipantau }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 35 }}>
                                { harian.odp.naikPDP }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.odp.naikPositif }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 15 }}>
                                { harian.odp.sembuh }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 10 }}>
                                { harian.odp.meninggal }
                            </td>

                        </td>

                        {/* ORANGTANPA GEJALA (OTG) */}

                        <td className="td-th-498-non-padding" rowSpan={4} colSpan={4}>

                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.otg.dipantau + harian.otg.naikODP + harian.otg.naikPDP + harian.otg.naikPositif + harian.otg.selesai }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.otg.naikODP }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 5 }}>
                                { harian.otg.naikPDP }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 0 }}>
                                { harian.otg.naikPositif }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.otg.dipantau }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.otg.selesai }
                            </td>
                        </td>

                        {/* PELAKU PERJALANAN/ORANG DENGAN RESIKO */}
                        <td className="td-th-498-non-padding" rowSpan={4} colSpan={4}>

                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 25 }}>
                                { harian.odr.dipantau + harian.odr.naikODP + harian.odr.naikPDP + harian.odr.naikPositif + harian.odr.selesai }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.odr.naikODP }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 5 }}>
                                { harian.odr.naikPDP }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 0 }}>
                                { harian.odr.naikPositif }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.odr.dipantau }
                            </td>
                            <td className="double-table-48-2 text-center-498" style={{ paddingLeft: 20 }}>
                                { harian.odr.selesai }
                            </td>
                        </td>
                        
                    </tbody>
                ))
            }

        </table>
    )

}

export default TableContent;