import React from 'react'
import { useHistory  } from 'react-router-dom'

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import './style.scss'

function MobileScreen (props) {

    const history = useHistory()
    const {
        newsList
    } = props

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return (
        <div className="mobile-berita-responsive-lp-082">
            
            <div className="mbr-lp-082-title">
                Berita & Tips Hidup Sehat
            </div>

            {
                    newsList &&
                    newsList
                    .slice(0,3)
                    .sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
                    .map((news,i)=>(
                        <div key={i} className="mbr-lp-082-content" style={{marginTop : i === 0 && "21px"}}>
                            <div className="mbr-lp-082-content-title-1">
                                <span onClick={()=>history.push('/detail-berita/'+news._id)} >
                                    {news.judulBerita}
                                </span>
                            </div>
                            <div className="mbr-lp-082-content-title-2">
                                { showDay(news.updatedAt) }, {showDate(news.updatedAt)}/{showHour(news.updatedAt)}
                            </div>
                            <div className="mbr-lp-082-content-title-3">
                                <Editor
                                    editorState={EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(`${(news.isiBerita)}`)))}
                                    toolbarHidden={true}
                                    readOnly={true}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName01-98"
                                    editorClassName="berita-input-60901"
                                />
                            </div>
                        </div>
                    ))
                }


            {/* <div className="mbr-lp-082-content" >
                <div className="mbr-lp-082-content-title-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem ipsum dolor sit amet
                </div>
                <div className="mbr-lp-082-content-title-2">
                    Minggu, 10 Mei 2020/13.23 WITA 
                </div>
                <div className="mbr-lp-082-content-title-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam..
                </div>
            </div>

            <div className="mbr-lp-082-content" >
                <div className="mbr-lp-082-content-title-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem ipsum dolor sit amet
                </div>
                <div className="mbr-lp-082-content-title-2">
                    Minggu, 10 Mei 2020/13.23 WITA 
                </div>
                <div className="mbr-lp-082-content-title-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam..
                </div>
            </div>

            <div className="mbr-lp-082-content" >
                <div className="mbr-lp-082-content-title-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, lorem ipsum dolor sit amet
                </div>
                <div className="mbr-lp-082-content-title-2">
                    Minggu, 10 Mei 2020/13.23 WITA 
                </div>
                <div className="mbr-lp-082-content-title-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam..
                </div>
            </div> */}

            <div className="mbr-button-see-082" onClick={e=>history.push('/landing-page-berita')}>
                Lihat Semua
            </div>

        </div>
    )

}

export default MobileScreen