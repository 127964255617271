// MODULES
import React , { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'


// ENDPOINT
import SERVER from '../../../../endPoint/index'

import './first.scss'
import './second.scss'

function QuestionBox (props) {

    const {title , placeHolder ,question , setDataQA , id, dataQA , lastIndex,isFirstIndex} = props

    const [isEditForm,setIsEditForm] = useState(false)
    const [inputQuestion,setInputQuestion] = useState(null)
    const [editQuestion,setEditQuestion] = useState(null)

    let changeQuestion = (e) => {
        e.preventDefault()
        let result = [...dataQA]
        result.forEach((el,index)=>{
            if (el.id === id) {
                el.question = inputQuestion
            }
        })
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })
    }

    let editContent = (e) => {
        e.preventDefault()
        let result = [...dataQA]
        result.forEach(el=>{
            if (id === el.id) {
                el.question = editQuestion
            }
        })
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
            setIsEditForm(false)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })

    }

    let addNewQuestionForm = (e) => {
        let result = [...dataQA]
        let lastId = dataQA[dataQA.length -1].id + 1
        result.push(
            {
                question : null,
                answer : [ {id : 1, value : null} ],
                id : lastId
            }
        )
        axios({
            method : "PATCH",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/general/setting2`,
            data : {
                key : "diagnosaData" , value : result
            }
        })
        .then((data)=>{
            setDataQA(result)
        })
        .catch(err=>{
            console.log(err , ' <<< ERROR')
        })
        // setDataQA(result)
    }

    let cancelOrDeleteQuestion = (e) => {
        e.preventDefault()
        let tempDelete = [
            {
                question : null,
                answer : [ {id : 1, value : null} ],
                id : 1
            }
        ]
        if (isFirstIndex && dataQA.length === 1 ) {
            axios({
                method : "delete",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/diagnosis`,
                // data : {
                //     key : "diagnosaData" , value : [ {id : 1, value : tempDelete} ]
                // }
            })
            .then((data)=>{
                setDataQA([
                    {
                        question : null,
                        answer : [ {id : 1, value : null} ],
                        id : 1
                    }
                ])
            })
            .catch(err=>{
                console.log(err , ' <<< ERROR')
            })
            
        }else {
            let result = [...dataQA]
            result = result.filter(el=>el.id !== id)

            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting2`,
                data : {
                    key : "diagnosaData" , value : result
                }
            })
            .then((data)=>{
                setDataQA(result)
            })
            .catch(err=>{
                console.log(err , ' <<< ERROR')
            })
        }
        setIsEditForm(false)
        setInputQuestion(null)
        setEditQuestion(null)
    }

    useEffect(()=>{
        console.log(question  , ' <<<< VALUE QUESTION PROPS -__---')
    },[])
 
    // SAAT SEDANG EDIT
    if (question) {
        return (
            <form onSubmit={e=>editContent(e)} className="qb-edit-container-224">

                <div 
                    className="qb-input-title-224"
                >
                    {title}
                </div>

                {
                    isEditForm ?
                    <input 
                        type="text" 
                        className="qb-input-edit-form-224" 
                        placeholder={placeHolder}
                        onChange={e=>setEditQuestion(e.target.value)}
                        value={editQuestion}
                        // onChange={e=>setEditedDataForm(e.target.value)}
                        // value={editedDataForm}
                    /> :
                    <div className="qb-edit-value-224">

                       {
                           question
                       }

                    </div>
                }


                <div className="qb-edit-delete-container-224" >

                    {
                        isEditForm?
                        
                        <div 
                            className="qb-plus-input-delete-form-224" 
                            onClick={e=>setIsEditForm(false)}
                        >
                                Batal
                        </div> :

                        <div 
                            className="qb-circle-edit-content-224" 
                            onClick={e=>[setIsEditForm(true),setEditQuestion(question)]}
                        >
                            <CreateIcon style={{color : "#c4c4c4"}}/>
                        </div>
                    }

                    {
                        isEditForm ?
                        <div 
                            className="qb-plus-input-save-form-224"
                            onClick={e=>editContent(e)}
                            // onClick={e=>saveToDb(e)}
                            // onClick={e=>editFormForTypeOne(e)}
                        >
                            Simpan
                        </div> : 
                        <div 
                            className="qb-circle-delete-content-224" 
                            // onClick={e=>deleteValue(e)}
                            onClick={e=>cancelOrDeleteQuestion(e)}
                        >
                            <DeleteIcon style={{color : "#c4c4c4"}}/>
                        </div>
            
                    }

                    {
                        lastIndex && !isEditForm &&
                        <div 
                            className="ab-plus-input-delete-form-225" 
                            style={{marginLeft : "27px", width : "178px"}}
                            onClick={e=>addNewQuestionForm(e)}
                        >
                            Tambah Pertanyaan
                        </div>
                    }


                </div>

            </form>
        )
    }
    
    // SAAT BELUM EDIT DAN BELUM DI ISI
    else {

        return (
            <form
                onSubmit={e=>changeQuestion(e)}
                className="qb-input-container-224" 
            >
                
                <div className="qb-input-title-224">
                    {title}
                </div>
    
                <input 
                    // onChange={e=>handleChangeDataInput(e.target.value)} 
                    onChange={e=>setInputQuestion(e.target.value)}
                    type="text"
                    // onChange={e=>setInputData(e.target.value)}
                    placeholder={placeHolder} 
                    className="qb-input-form-224" 
                />

                {
                    !isFirstIndex && 
                    <div 
                        // onClick={e=>cancelAddNewAnswer(e)}
                        onClick={e=>cancelOrDeleteQuestion(e)}
                        className="ab-plus-input-delete-form-225"
                        style={{
                            marginLeft : !isFirstIndex ? "12px" : null
                        }}
                    >
                        Batal
                    </div>
                }

                <div 
                    className="qb-input-button-224" 
                    onClick={e=>changeQuestion(e)}
                    // onClick={e=> addToDb(e)}
                >
                    Simpan
                </div>
        
            </form>
        )

    }


}

export default QuestionBox