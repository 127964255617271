const dataKey = [
    "propinsi" , 
    "kabupaten"  ,
    "kecamatan" ,
    "desa" ,
    "status" ,
    "petugas" ,
    "instansi" ,
    "jabatan" ,
    "layanan",
    "jenisSakit",
]

export default dataKey