import React from 'react'

// MATERIAL UI ICON
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Content (props) {

    const { detail , clickMore , id , question ,answer , style ,answerHtml ,childrenStyle }= props

    return (
        <>
            <div
                onClick={e=>clickMore(id)}
                className={ detail.includes(id) ? "dpc-per-box-254-detail" : "dpc-per-box-254" }
                // className="dpc-per-box-254" 
                style={{...style}}
            >
                <div className="dpc-per-box-item-254">

                    <div className="dpc-pbi-title-254">
                        {question}
                    </div>

                    <div 
                        className="dpc-pbi-circle-254" onClick={e=>clickMore(id)}
                    >
                        {
                        detail.includes(id) ?
                        <ExpandLessIcon style={{color : "#FFFFFF"}}/> :
                        <ExpandMoreIcon style={{color : "#FFFFFF"}}/>
                        }
                    </div>

                </div>
            </div>
            {
                detail.includes(id) &&
                    <div style={{...childrenStyle}} className="dpc-254-content-detail-254" >
                        <div className="dpc-per-box-item-254" style={{justifyContent : "flex-start"}} >
                            {
                                answer ? 
                                <div className="dpc-word-content-254">
                                    {answer}
                                </div> : 
                                answerHtml

                            }
                            {/* <div className="dpc-word-content-254">
                              {answer ? answer :  answerHtml}
                            </div> */}
                        </div>
                    </div> 
            }
        </>
    )

}

export default Content