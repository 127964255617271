import React from 'react'

function Paragraph12 () {
    
    return (
        <div className="dpc-word-content-254">
            <p style={{marginTop : "0",marginBottom : "0"}}>
                Kenakan masker hanya jika Anda sakit disertai gejala-gejala COVID-19 (terutama batuk) atau 
                merawat orang yang mungkin terjangkit COVID-19. Masker sekali pakai hanya dapat digunakan satu kali saja. Jika Anda tidak sakit dan tidak merawat orang yang sakit, namun Anda memakai masker maka Anda memboroskan masker. Telah terjadi kekurangan masker di mana-mana, sehingga WHO mendorong orang-orang untuk menggunakan masker dengan bijak.
            </p>
            <p style={{marginTop : "0",marginBottom : "0"}}>
                WHO menyarankan agar masker medis digunakan secara bijak, sehingga pemborosan tidak terjadi dan masker tidak disalahgunakan (<a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/when-and-how-to-use-masks">lihat Saran penggunaan masker</a>).
            </p>
            <p style={{marginTop : "0",marginBottom : "0"}}>
                Cara paling efektif melindungi diri dan orang lain dari COVID-19 adalah sering mencuci tangan, 
                menutup mulut saat batuk dengan siku yang terlipat atau tisu, dan menajga jarak setidaknya 1 meter dari orang yang batuk-batuk atau bersin-bersin. 
                Lihat <a href="https://www.who.int/publications-detail/advice-on-the-use-of-masks-the-community-during-home-care-and-in-health-care-settings-in-the-context-of-the-novel-coronavirus-(2019-ncov)-outbreak"> Langkah-langkah perlindungan dasar terhadap coronavirus baru untuk mendapatkan informasi lebih lanjut. </a>
            </p>
        </div>
    )

}

export default Paragraph12