import React from 'react'

function Paragraph16 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Tidak. Tidak ada bukti yang menunjukkan bahwa hewan pendamping atau peliharaan seperti kucing dan anjing terinfeksi atau menyebarkan virus penyebab COVID-19.
            </p>
            <p style={{marginBottom : "0", marginTop : "0"}}>
                WHO terus memantau penelitian terbaru tentang topik ini serta topik-topik COVID-19 lainnya dan akan menyampaikan temuan-temuan terbaru.
            </p>
        </div>
    )

}

export default Paragraph16