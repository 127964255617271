// MODULE
import React , { useState , useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import io from "socket.io-client";
import swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

// ENDPOINT
import SERVER from '../../endPoint/index'

// COMPONENT
import BoxContent from './BoxContent'
import BoxQA from './BoxQA'
import DailyReport from './DailyReport'

// STYLING
import './style.scss'

function DiagnosisComponent (props) {

    // HISTORY ROUTER
    const history = useHistory()

    let getDataDiagnosis = () => {
        // swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/general/diagnosis`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            console.log(data , ' <<< VALUE DATA ()()()()()()()() >>>>')
            // console.log('MASUK SINI >lll<L,')
            swal.close()
            if (data.length > 0) {
                setDataQA(data[0].diagnosisData)
            }
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' << WHY ERROR')
        })
    }

    let getDataUser = () => {
        // swal.showLoading()
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/patient`
        })
        .then(({data})=>{
            swal.close()
            setDataPatient(data)
            data.forEach(el=>{
                // console.log(new Date(el.dateDay1) , ' <<< DATE')
                console.log( new Date(el.dateDay1.date).getDate()  , ' <<< DATE 1')
                console.log( new Date(el.dateDay2.date).getDate()  , ' <<< DATE 2')
                console.log( new Date(el.dateDay3.date).getDate()  , ' <<< DATE 3')
                
            })
            console.log(data , ' <<< VALUE DATA USER IIII')
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' <<<')
        })
    }

    useEffect(()=>{
        let socket = io(`${SERVER}`)
        socket.on('fetch_diagnosis',data=>{
            getDataDiagnosis()
        })
        getDataDiagnosis()
        getDataUser()
    },[])

    const [dataQA,setDataQA] = useState([
        {
            question : null,
            answer : [ {id : 1, value : null} ],
            id : 1
        }
    ])
    const [dataPatient,setDataPatient] = useState([])

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    useEffect(()=>{
        console.log(dataQA , ' <<< VALUE DATA QA')
    },[dataQA])

    let renderDataQA = () => {
        return dataQA.map((el,index)=>{
            return (
                <BoxQA
                    index={index}
                    key={el.id}
                    data={el}
                    setDataQA={setDataQA}
                    dataQA={dataQA}
                    lastIndex={index === dataQA.length -1 ? true : false}
                    isFirstIndex={index === 0 ? true : false}
                />
            )
        })
    }

    return (

        <div className="dc-456-container">

            <div className="id-190-title">
                Diagnosa Mandiri
            </div>

            <BoxContent/>

            {
                history.location.pathname === '/diagnosis-input' ?
                <div className="dc-title-question-456">
                    Isi Pertanyaan Diagnosa Mandiri Dibawah ini
                </div> : <></>
            }


            {
                history.location.pathname === '/diagnosis-input' ?
                renderDataQA() 
                : 
                <DailyReport
                    dataPatient={dataPatient}
                    setDataPatient={setDataPatient}
                />
            }

        </div>
        
    )

}

export default DiagnosisComponent;