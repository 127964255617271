// MODULE
import React from 'react'
import { useMediaQuery  } from 'react-responsive'


// COMPONENT 
import SecondContentChildren from './SecondContent'
import SecondContentChildrenMobile from './SecondContentMobile'

// STYLING
import './style.scss'
import './mobile.scss'

function SecondContent () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    return (
        <div className="mlp-345-second-content">
            
            <div className="sc-345-one">

                <div className="sc-345-one-title">
                    Fitur yang Tersedia
                </div>

                <div className="sc-345-one-body">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </div>

            </div>

            {/* STYLING DI ORANG TUA */}
            {
                
                isTabletOrMobile ? <SecondContentChildrenMobile/> : <SecondContentChildren/>
            
            }

            <div className="sc-345-three">
                Gabung Bersama Kami
            </div>

        </div>
    )

}

export default SecondContent