import React, { useEffect } from 'react'

// COMPONENT 
import LeftContent from './LeftContent'
import RightContent from './RightContent'

// STYLING
import './style.scss'

function Header (props) {
    
    return (
        <div className="lp-867-header" id="beranda" style={{...props.style}}>

            <div className="lp-867-header-container" >

                <LeftContent/>

                <RightContent/>

            </div>

            
        </div>
    )

}

export default Header