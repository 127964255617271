// MODULE
import React from 'react'

// STYLING
import './style.scss'

// COMPONENT
import NavbarMobile from '../../../components/NavbarMobile'
import Form from './Form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import SERVER from '../../../endPoint'
import { USER_LOGIN } from '../../../store/constanta'
import swal from 'sweetalert2'

function MobileScreen (props) {
    const dispatch = useDispatch()
    const history = useHistory()

    const  {
        email,
        checkEmail,
        passwordHide,
        password,
        checkPassword,
        handleClickShowPassword,
    } = props

    const handleSubmit = e => {
        let flag = false
        if (history.location.state) {
            if (history.location.state.from == "Diagnosa") {
                flag = true
            }
        }
        e.preventDefault()
        swal.showLoading()
        axios({
            method: 'post',
            url: `${SERVER}/user/login`,
            data: { email, password }
        })
        .then(({ data })=>{
            if (data.profile.role === 'master' || data.profile.role === 'admin') {
                swal.close()
                swal.fire({
                    html: 'Anda Hanya Bisa Login Melalui <br> Website',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0280A2',
                })
            } else if (data.profile.role === 'petugas') {
                swal.close()
                swal.fire({
                    html: 'Anda Hanya Bisa Login Melalui <br> Website',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0280A2',
                })
            } else {
                if (data.profile) {
                    if (data.profile.dataPasien) {
                        swal.close()
                        if (data.profile.dataPasien.isolasiMandiri === 'Ya') {
                            dispatch({
                                type : USER_LOGIN,
                                payload: data.profile
                            })
                            localStorage.setItem('token_access', data.token)
                            history.replace('/landing-page-kabupaten/diagnosa-mandiri/dengan-status')
                        } else {
                            swal.fire({
                                title: 'Gagal',
                                text: 'Maaf Akses Anda Ditolak',
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#0280A2',
                            })
                        }
                    }
                }
            }
        })
        .catch(err=>{
            console.log(err.response)
            const Toast = swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', swal.stopTimer)
                    toast.addEventListener('mouseleave', swal.resumeTimer)
                }
            })
            if(err.response){
                if(err.response.data){
                    Toast.fire({
                        icon: 'warning',
                        title: err.response.data
                    })
                }
            }
        })
    }

    return (
        <div className="login-mobile-67-container">
            
            <NavbarMobile/>

            <div className="lm-67-title-1">
                Selamat Datang
            </div>
            <div className="lm-67-title-2">
                Silahkan masukan Nama Pengguna dan Password yang telah didaftarkan oleh Petugas
            </div>

            <Form
                handleSubmit={handleSubmit}
                email={email}
                checkEmail={checkEmail}
                passwordHide={passwordHide}
                password={password}
                checkPassword={checkPassword}
                handleClickShowPassword={handleClickShowPassword}
            />

        </div>
    )

}

export default MobileScreen