// MODULE
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
// STYLING
import './style.scss'
import swal from 'sweetalert2'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    infoBtn:{
        width: '96px',
        height: '38px',
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        fontWeight: 'bold',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)',
        letterSpacing: '1px'
    }
}));

function RightContent () {
    const history = useHistory()
    const classes = useStyles()
    const handleClick = () => {
        history.push('/landing-page-kabupaten/diagnosa-mandiri')
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        // const swalWithBootstrapButtons = swal.mixin({
        //     customClass: {
        //         confirmButton: 'btn-style-angga-confirm-008',
        //         cancelButton: 'btn-style-angga-cancel'
        //     },
        //     buttonsStyling: false
        // })

        // swalWithBootstrapButtons.fire({
        //     html: `Untuk mengisi Diagnosa Mandiri, <br> silahkan akses melalui Telepon Genggam anda`,
        //     icon: 'info',
        //     confirmButtonText: 'OK',
        //     reverseButtons: true,
        //     showCancelButton: false,
        // })
        // .then((result) => {
        //     if (result.value) {
        //         // history.push('/landing-page-kabupaten/diagnosa-mandiri')
        //     } else if ( result.dismiss === swal.DismissReason.cancel ) {
        //     }
        // })
    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if((history.location.pathname) !== '/landing-page-kabupaten'){
            history.push('/landing-page-kabupaten')
        }
    }

    function newsHandler(){
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if((history.location.pathname) !== '/landing-page-berita'){
            history.push('/landing-page-berita')
        }
    }

    return (
        <div className="header-867-right-content">
            
            <a href="#beranda" className="hrc-linkded" >
                <div className={ (history.location.pathname) === '/landing-page-kabupaten' && (history.location.hash).includes('#tentang-covid19') === false ? 'hrc-867-per-content-active' : "hrc-867-per-content" }
                    onClick={()=> topFunction() }
                >
                    Beranda 
                </div>
            </a>

            <a href="#tentang-covid19" className="href-link" onClick={()=> (history.location.hash).includes('#tentang-covid19') ? '' : history.push('/landing-page-kabupaten') }>
                <div className={ (history.location.hash).includes('#tentang-covid19') ? 'hrc-867-per-content-active' : "hrc-867-per-content"}>
                    Tentang Covid 19
                </div>
            </a>

        <div className={ (history.location.pathname).includes('diagnosa-mandiri') ? 'hrc-867-per-content-active' : "hrc-867-per-content" }
            onClick={()=> (history.location.pathname).includes('diagnosa-mandiri') ? '' : handleClick() }>
                Diagnosa Mandiri
            </div>
            
            <a href="#berita" className="href-link" >
                <div className={ (history.location.pathname).includes('landing-page-berita') ? 'hrc-867-per-content-active' : "hrc-867-per-content"}
                    onClick={e=> newsHandler()}>
                    Berita
                </div>
            </a>

            {
                history.location.pathname !== '/landing-page-kabupaten/diagnosa-mandiri' &&
                <Button
                    className={ classes.infoBtn }
                    style={{ background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)', textTransform: 'none' }}
                    onClick={()=> history.push('/login') }
                >
                    Masuk
                </Button>
            }


        </div>
    )

}

export default RightContent