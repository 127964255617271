import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import './style.scss'
import './mobile.scss'

function SecondContent (props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const isLaptopM = useMediaQuery({ query: '(max-width: 1024px)' })
    const {
        newsList,
        showDay,
        showDate,
        showHour
    } = props

    const history = useHistory()

    return (
        <div className="lpb-787-sc">
            
            {
                newsList &&
                newsList
                .slice(4, newsList.length)
                .sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
                .map((el,i)=>(
                    <div className="lpb-787-sc-item" style={{marginTop : "17px"}}>
                        <div className="lpb-787-sc-item2">

                            <div className="lpb-787-sc-item2-fc" style={{ backgroundImage: `url('${el.bannerImage}')` }}>

                            </div>

                            <div className="lpb-787-sc-item2-sc">

                                <div className="lpb-787-sc-item2-sc-1">
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={()=>history.push('/detail-berita/'+el._id)}
                                    >
                                        {
                                                isTabletOrMobile ?
                                                el.judulBerita.split('').length > 50 ?
                                                    el.judulBerita.slice(0,50)+'...'
                                                    :
                                                    el.judulBerita.slice(0,50)
                                                :
                                                isLaptopM ?
                                                el.judulBerita.split('').length > 50 ?
                                                    el.judulBerita.slice(0,50)+'...'
                                                    :
                                                    el.judulBerita.slice(0,50)
                                                :
                                                el.judulBerita.split('').length > 100 ?
                                                    el.judulBerita.slice(0,100)+'...'
                                                    :
                                                    el.judulBerita.slice(0,100)
                                            }
                                    </span>
                                </div>

                                <div className="lpb-787-fc-2-e-item2-2">
                                    <span>
                                        <span className="green-day112" >
                                            {showDay(el.updatedAt) }
                                        </span>
                                        , {showDate(el.updatedAt)}/
                                        {showHour(el.updatedAt)}
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div>
                ))
            }

        </div>
    )

}

export default SecondContent;