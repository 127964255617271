import React from 'react'

// MATERIAL UI
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        // justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        },
        justifyContent : "center",
        alignItems : "center",
        marginTop : "52px"
    },
    input1: {
        marginBottom: 30,
        width: 320,
        height: 38,
        fontFamily: 'OpenSans',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#888888',
    },
    input2: {
        marginBottom: 30,
        width: 320,
        height: 38,
        fontFamily: 'OpenSans',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#888888',
    },
    loginBtn:{
        width: 320,
        height : 38,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        textTransform: 'none'
    }
}));

function Form (props) {

    const  {
        handleSubmit,
        email,
        checkEmail,
        passwordHide,
        password,
        checkPassword,
        handleClickShowPassword,
    } = props
    
    const classes = useStyles();

    return (
        
        <form className={classes.root} noValidate autoComplete="false" onSubmit={e=>handleSubmit(e)}>
            <FormControl size="small"  className={clsx(classes.margin, classes.textField, classes.input1)} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Email/Username</InputLabel>
                <OutlinedInput
                    value={email}
                    onChange={e=>checkEmail(e)}
                    label="Email/Username"
                    inputProps={{
                        autoComplete: 'false',
                        form:{
                            autoComplete: 'false',
                        }
                    }}
                    onKeyPress={e =>{
                        if (e.key === 'Enter') {
                            handleSubmit(e)
                        }
                    }}
                />
            </FormControl>
            <FormControl size="small"  className={clsx(classes.margin, classes.textField, classes.input2)}  variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    id={passwordHide ? "password-style" : "" }
                    value={password}
                    onChange={e=>checkPassword(e)}
                    label="Password"
                    inputProps={{
                        autoComplete: 'false',
                        form:{
                            autoComplete: 'false',
                        }
                    }}
                    onKeyPress={e =>{
                        if (e.key === 'Enter') {
                            handleSubmit(e)
                        }
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <div className="icon-eye-p-container" 
                                onClick={e=>handleClickShowPassword()}
                            >
                                {
                                    passwordHide ?
                                    <i className="far fa-eye password-eye-login-on"></i>
                                    :
                                    <i className="far fa-eye password-eye-login-off"></i>
                                }
                            </div>
                        </InputAdornment>
                    }
                    // labelWidth={70}
                />
            </FormControl>
            <Button type="submit" size="medium" className={classes.loginBtn} onSubmit={e=>handleSubmit(e)}>Masuk</Button>
        </form>
    )

}

export default Form