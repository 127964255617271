// MODULES
import React from 'react'

// COMPONENTS
import QuestionBox from './QuestionBox'
import AnswerBox from './AnswerBox'

// STYLE
import './style.scss'

function BoxQa (props) {

    const {
            data , 
            key,
            setDataQA,
            dataQA,
            lastIndex,
            isFirstIndex,
            index
        } = props

    const {
            id,
            question ,
            answer ,
    } = data

    return (
        <div 
            key={key}
        >

            <QuestionBox
                title={`Pertanyaan ke-${(index + 1)}`}
                placeHolder={"Pertanyaan Diagnosa"}
                question={question}
                dataQA={dataQA}
                setDataQA={setDataQA}
                id={id}
                lastIndex={lastIndex}
                isFirstIndex={isFirstIndex}
            />
            
            {
                answer.map((el,index)=>{
                    return (
                        <AnswerBox
                            title={ index === 0 ? "Pilihan Jawaban" : ""}
                            placeHolder={"Tulis Jawaban yang berkaitan dengan Pertanyaan"}
                            answerItem={el}
                            setDataQA={setDataQA}
                            dataQA={dataQA}
                            id={id}
                            lastIndex={index === answer.length -1 ? true : false}
                            isFirstIndex={index === 0 ? true : false}
                            question={question}
                        />
                    )
                })   
            }

        </div>
    )

}

export default BoxQa