import React, { useEffect } from 'react'

// COMPONENT
import Header from './Header'
import Content1 from './Content1'
import Content2 from './Content2'

// STYLE
import './style.scss'

function LandingPageKecamatan () {
    // useEffect(()=>{
    //     console.clear()
    // })

    return (
        <div className="lp-kecamatan-869">
            
            <Header/>

            {/* Data Kecamatan per hari ini */}
            <Content1/>

            {/* Total Data Kecamatan */}
            {/* <Content2/> */}

        </div>
    )

}

export default LandingPageKecamatan