// MODULE
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'


// MATERIAL UI
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// CSS
import './styles.scss'
import './responsive.scss'

const useStyles = makeStyles((theme) => ({
    infoBtn:{
        width: '400px',
        height: '38px',
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        fontWeight: 'bold',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)',
        letterSpacing: '1px',
        marginTop: 20
    },
    infoBtnMobile: {
        width: "234.49px",
        height: "38px",
        borderTopLeftRadius: '100px',
        borderBottomLeftRadius: '100px',
        borderTopRightRadius: '100px',
        borderBottomRightRadius: '100px',
        fontFamily: 'Poppins',
        fontSize: '10px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        fontWeight: '500',
        background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)',
        marginTop: 20,
        lineHeight : "15px"
    },
    infoBtnMobileSmall: {
        width: "210.49px",
        height: "30px",
        borderTopLeftRadius: '100px',
        borderBottomLeftRadius: '100px',
        borderTopRightRadius: '100px',
        borderBottomRightRadius: '100px',
        fontFamily: 'Poppins',
        fontSize: '9.5px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        fontWeight: '500',
        background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)',
        marginTop: 20,
        lineHeight : "15px"
    }
}));

export default function Covid19(){

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' }) 
    const isSmallScreen = useMediaQuery({  query : '(max-width: 340px)'})


    const classes = useStyles()
    const history = useHistory()

    let isMobileSmall = () => {
        if (!isSmallScreen) {
            return classes.infoBtnMobile
        }else {
            return classes.infoBtnMobileSmall
        }
    }

    return(
        <div className='content-covid19'>
            <div className="box-whitr-1312" >
                <span className="text-contnt-98">
                    Tentang COVID-19
                </span>
                <div className="context-12-double" >
                    <div className="rght-cntnt">
                        <span className="txt-blu-988">
                            Apa itu Coronavirus & Apa itu Covid-19?
                        </span>
                        <span className="txt-cntnt-gry" >
                            Coronavirus adalah suatu kelompok virus yang dapat menyebabkan penyakit pada hewan atau manusia. Beberapa jenis coronavirus diketahui menyebabkan infeksi saluran nafas pada manusia mulai dari batuk pilek hingga yang lebih serius seperti Middle East Respiratory Syndrome (MERS) dan Severe Acute Respiratory Syndrome (SARS). Coronavirus jenis baru yang ditemukan menyebabkan penyakit COVID-19.
                            <br/><br/>
                            COVID-19 adalah penyakit menular yang disebabkan oleh jenis coronavirus yang baru ditemukan. Ini merupakan virus baru dan penyakit yang sebelumnya tidak dikenal sebelum terjadi wabah di Wuhan, Tiongkok, bulan Desember 2019.
                        </span>
                        {
                            !isTabletOrMobile && <div className="imge-vck-1231"></div>
                        }
                        {
                            isTabletOrMobile && <div className="img-vck-dcktr"></div>
                        }
                    </div>
                    <div className="lft-cntnt">
                        {
                            !isTabletOrMobile && <div className="img-vck-dcktr"></div>
                        }
                        <span className="txt-blu-9880">
                            Apa Saja Gejala Covid-19?
                        </span>
                        <span className="txt-cntnt-gry" >
                            Gejala-gejala COVID-19 yang paling umum adalah demam, rasa lelah, dan batuk kering. Beberapa pasien mungkin mengalami rasa nyeri dan sakit, hidung tersumbat, pilek, sakit tenggorokan atau diare, Gejala-gejala yang dialami biasanya bersifat ringan dan muncul secara bertahap. Beberapa orang yang terinfeksi tidak menunjukkan gejala apa pun dan tetap merasa sehat. Sebagian besar (sekitar 80%) orang yang terinfeksi berhasil pulih tanpa perlu perawatan khusus. Sekitar 1 dari 6 orang yang terjangkit COVID-19 menderita sakit parah dan kesulitan bernapas. Orang-orang lanjut usia (lansia) dan orang-orang dengan kondisi medis yang sudah ada sebelumnya seperti tekanan darah tinggi, gangguan jantung atau diabetes, punya kemungkinan lebih besar mengalami sakit lebih serius. Mereka yang mengalami demam, batuk dan kesulitan bernapas sebaiknya mencari pertolongan medis.
                        </span>
                        <Button
                            className={ !isTabletOrMobile ? classes.infoBtn : isMobileSmall()  }
                            style={{ background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)', textTransform: 'none' }}
                            onClick={()=> history.push('/daftar-pertanyaan')}
                        >
                            Lihat Pertanyaan yang Sering Diajukan
                        </Button>
                        {
                            isTabletOrMobile && <div className="imge-vck-1231"></div>
                        }
                    </div>
                </div>
            </div>

             {/* BLUE AND RED CONTENT */}

            <div className="notes-ctnrt">
                <div className="blue-cntn">
                    <div className="txt-blue-cntn">
                        Apa yang Boleh Dilakukan
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                            Tetap tinggal di rumah, bekerja dari rumah, belajar dari rumah, dan beribadah di rumah
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                            Cuci tangan dengan sabun dan air minimal 20 detik atau gunakan hand sanitizer berbasis alkohol minimal 60 %
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                        Bersihkan dan disinfeksi permukaan benda yang sering disentuh
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                        Tutupi mulut dan hidung saat batuk atau bersin dengan tisu atau siku bagian dalam
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                        Pakai masker jika Anda harus beraktivitas di luar rumah dan ganti secara berkala
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <VerifiedUserIcon style={{ color: "#EAEAEA" }} />
                        <span className="txt-311-caa" >
                        Terapkan pola hidup sehat dengan makanan bergizi dan olahraga
                        </span>
                    </div>
                </div>
                <div className="red-cntn">
                    <div className="txt-blue-cntn">
                        Apa yang Tidak Boleh Dilakukan
                    </div>
                    <div className="text-contrt-87613" >
                        <div className="shield-x" ></div>
                        <span className="txt-311-caa" >
                            Bepergian ke luar rumah untuk hal yang tidak penting
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <div className="shield-x" ></div>
                        <span className="txt-311-caa" >
                            Berada dekat dengan orang yang sedang sakit, batuk atau bersin
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <div className="shield-x" ></div>
                        <span className="txt-311-caa" >
                        Menyentuh mata, hidung, atau mulut dengan telapak tangan
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <div className="shield-x" ></div>
                        <span className="txt-311-caa" >
                        Menimbun masker, hand sanitizer, atau perlengkapan medis lainnya
                        </span>
                    </div>
                    <div className="text-contrt-87613" >
                        <div className="shield-x" ></div>
                        <span className="txt-311-caa" >
                        Menyebarkan berita bohong (hoax), dan menganggap Covid-19 adalah aib
                        </span>
                    </div>
                </div>
            </div>

        </div>
    )
}