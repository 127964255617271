import React from 'react'
import { useMediaQuery } from 'react-responsive'


function Paragraph5 () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' })


    return (
        <div className="dpc-word-content-254">
            <p style={{marginTop : "0"}}>
                Risiko tertular tergantung lokasi Anda – lebih tepatnya, apakah sedang terjadi wabah COVID-19 di sana. 
                Di sebagian besar lokasi, risiko tertular COVID-19 masih rendah. Namun, ada tempat-tempat (kota atau wilayah) di seluruh dunia di mana penyakit ini menyebar. Orang yang tinggal di atau mengunjungi wilayah-wilayah ini lebih berisiko tertular COVID-19. Pemerintah-pemerintah dan otoritas kesehatan mengambil tindakan tegas setiap kali kasus COVID-19 baru teridentifikasi. Patuhilah larangan-larangan perjalanan, pergerakan atau pertemuan dengan jumlah peserta yang besar yang diberlakukan di tempat Anda berada. Bekerja sama dengan upaya-upaya pengendalian penyakit akan menurunkan risiko Anda tertular atau menyebarkan COVID-19.
            </p>
            <p>
                Wabah dan penularan COVID-19 dapat ditahan dan dihentikan, seperti yang terjadi di Tiongkok dan beberapa negara lain. 
                Sayangnya, wabah-wabah baru terjadi dengan cepat. Anda perlu mengetahui situasi di tempat Anda berada atau yang akan Anda kunjungi. WHO mempublikasikan laporan terbaru tentang situasi COVID-19 di seluruh dunia setiap harinya.
            </p>
            <p style={{width : !isTabletOrMobile ? "120%" : null,marginBottom : "0"}}>
                Laporan ini dapat dilihat di lihat di <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/situation-reports">https://www.who.int/emergencies/diseases/novel-coronavirus-2019/situation-reports</a> 
            </p>
        </div>
    )

}

export default Paragraph5