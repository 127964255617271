import React from 'react'
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive'


// STYLE
import './style.scss'
import './mobile.scss'

// EDITOR
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { useHistory } from 'react-router-dom'

function Content (props) {
    let {
        isiBerita,
        imageReview,
        newsList,
        id,
        showDay,
        showDate,
        showHour
    } = props

    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })


    return (
        <div className="db-content-486">

            <div className="left-content-486">
                {
                    imageReview &&
                    <div className="left-content-486-image"
                        style={{ backgroundImage: `url('${imageReview}')` }}
                    />
                }

                <div className="left-content-486-text">
                    <Editor
                        editorState={isiBerita}
                        toolbarHidden={true}
                        readOnly={true}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="berita-input-609"
                    />
                </div>

            </div>
            

            {
                isTabletOrMobile &&
                <div className="title-other-news-486">
                    Berita Lainya
                </div>
            }

            <div className="right-content-486">
                {
                    newsList &&
                    newsList
                    .filter((a)=> a._id !== id )
                    .sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
                    .map((el,index)=>{
                        return (
                            <div key={index} className="right-content-item-486" style={{marginTop : index === 0 ? null : "19px"}}>
                                <div className="right-content-item-1-486" style={{ backgroundImage: `url('${el.bannerImage}')` }} />
                                <div className="right-content-item-2-486">
                                    <div className="right-content-item-2-486-fc">
                                        <span className="crsr-point"
                                            onClick={()=>history.push('/detail-berita/'+el._id)}
                                        >  
                                            { el.judulBerita }
                                        </span>
                                    </div>
                                    <div className="right-content-item-2-486-sc">
                                        <span>
                                            <span className="green-day112" >
                                                {showDay(el.updatedAt) }
                                            </span>
                                            , {showDate(el.updatedAt)}/
                                            {showHour(el.updatedAt)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )

}

export default Content