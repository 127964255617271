import React from 'react'
import { useMediaQuery } from 'react-responsive'


function Paragraph19 () {   

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' })


    return (
        <div className="dpc-word-content-254">
            <p style={{marginTop : "0",marginBottom : "0"}}>
                Tindakan-tindakan berikut TIDAK efektif melawan COVID-19 dan bahkan bisa berbahaya:
            </p>
            <ul>
                <li>Merokok</li>
                <li>Mengenakan lebih dari satu masker</li>
                <li style={{width : isTabletOrMobile ? "85%" : null}}>Meminum antibiotik (Lihat pertanyaan 10 "Apakah antibiotik efektif mencegah dan menangani COVID-19?")</li>
            </ul>
            <p style={{marginTop : "5px"}}>
                Apa pun keadaannya, jika Anda mengalami demam, batuk dan kesulitan bernapas, segeralah mencari pertolongan medis untuk mengurangi risiko terkena infeksi yang lebih parah dan sampaikan riwayat perjalanan Anda baru-baru ini kepada tenaga kesehatan.
            </p>
        </div>
    )

}

export default Paragraph19