import React from 'react'

function RightContent (props) {
    let { news } = props
    return (
        <div className="news-content-right-425"
            style={{ backgroundImage: `url('${news.bannerImage}')` }}
        />
    )

}

export default RightContent