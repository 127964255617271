// MODULE
import React , { useState  } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import axios from 'axios'
import swal from 'sweetalert2';

// ENDPOINT
import SERVER from '../../../endPoint/index'

// COMPONENT
import EditContent from '../EditContent'

// GLOBAL ACTION
import { SET_DATA_SETTING_ACTIONS } from '../../../store/actions/settings'


function Provinsi (props) {

    const {
        title,
        placeHolder,
        code,
        type,
        group
    } = props

    // LOCAL STATE
    const [inputData, setInputData] = useState(null)

    // GLOBAL STATE
    const data = useSelector(state=>state.SettingState)

    // GLOBAL ACTION
    const dispatch = useDispatch()

    // FUNCTION
    let addToDb = (e) => {
        if (!inputData) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }else {

            let resultTypeTwo = []
            let id;
    
            if (!data[code]) {
                id = 0
            }else {
                id = data[code][ data[code].length -1 ].id
            }
    
            resultTypeTwo.push(
                {
                    value : inputData,
                    id 
                }
            )
            swal.showLoading()
    
            axios({
                method : "PATCH",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/general/setting`,
                data : {
                    key : code , value : type === 1 ? inputData : resultTypeTwo
                }
            })
            .then((data)=>{
                dispatch(SET_DATA_SETTING_ACTIONS(
                    {
                        key : code,
                        value : type === 1 ? inputData : resultTypeTwo
                    }
                ))
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< ERROR')
            })

        }

        
    }

    if (data[code]) { // KONDISI JIKA INPUT SUDAH DI ISI

        return (
            <EditContent
                title={title}
                code={code}
                type={type}
                placeHolder={placeHolder} 
                setInputData={setInputData}
                group={group}
            />
        )

    } else { // KONDISI JIKA INPUT BELUM DI ISI

        return (
            <form 
                className="gs-input-container-124" 
                style={{ marginBottom : group === 2 ? "24px" : "12px" }} // MEMBEDAKAN MARGIN BOTTOM ANTARA KELOMPOK 1 DAN 2
            >
                
                <div className="gs-input-title-124">
                    {title}
                </div>
    
                <input 
                    // onChange={e=>handleChangeDataInput(e.target.value)} 

                    type="text"
                    onChange={e=>setInputData(e.target.value)}
                    placeholder={placeHolder} 
                    className="gs-input-form-124" 
                />

                <div 
                    className="gs-input-button-124" 
                    onClick={e=> addToDb(e)}
                >
                    Simpan
                </div>
    
            </form>
        )

    }


}

export default Provinsi;