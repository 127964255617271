import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.scss'
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import axios from 'axios'
import SERVER from '../../endPoint'
import AddIcon from '@material-ui/icons/Add';
import { BorderLeft } from '@material-ui/icons';
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5]

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root': {
            backgroundColor: '#0185ff',
        }
    },
    tableContainer: {
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        marginTop: '14px'
    },
    tableHead: {
        backgroundColor: '#ffffff',
        fontFamily: "'Poppins'",
        fontSize: '11px',
        fontWeight: 500,
        borderLeft: 'none',
        padding: '7px',
        lineHeight: "12px",
        textAlign: "center",
    },
    headerTable: {
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'center',
        color: ' #444444',
        fontFamily: "Poppins",
        borderBottom: '0.6px solid #BBBBBB',
        borderRight: '0.6px solid #BBBBBB',
        padding: '0px',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 10,
        paddingBottom: 10,
        lineHeight: 1.4
    },
    headerTable1: {
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign: 'center',
        color: ' #444444',
        fontFamily: "Poppins",
        borderBottom: '0.6px solid #BBBBBB',
        padding: '7px'
    },
    tableRow: {
        borderTop: '0.6px solid #BBBBBB',
        borderBottom: '0.6px solid #BBBBBB',
        
    },
    tableCell1: {
        fontFamily: "'Poppins'",
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#444444',
        border: '0.6px solid #BBBBBB',
        padding: '7px'
    },
    tableCell: {
        fontFamily: "'Poppins'",
        fontSize: '11px',
        fontWeight: 500,
        color: '#444444',
        lineHeight: "12px",
        textAlign: "center",
        border: '0.6px solid #BBBBBB',
        borderRight: '0.3px solid #CCCCCC',
        BorderLeft: '0.3px solid #CCCCCC',
        padding: '7px',
    },
    tableCell2: {
        fontFamily: "'Poppins'",
        fontSize: '12px',
        fontWeight: 600,
        color: '#444444',
        border: '0.6px solid #BBBBBB',
        // borderRight: '0.3px solid #CCCCCC',
        BorderLeft: '0.3px solid #CCCCCC',
        padding: '7px'
    },
    tableCellRight: {
        fontFamily: "'Poppins'",
        fontSize: '12px',
        fontWeight: 600,
        color: '#444444',
        border: '0.6px solid #BBBBBB',
        borderRight: 'none',
        borderLeft: '0.3px solid #CCCCCC',
        padding: '7px'
    },
    tableCellLeft: {
        fontFamily: "'Poppins'",
        fontSize: '11px',
        fontWeight: 500,
        color: '#444444',
        border: '0.6px solid #BBBBBB',
        borderLeft: 'none',
        padding: '7px',
        lineHeight: "12px",
        textAlign: "center",
    },
    addButton: {
        textTransform: "none",
        width: '159px'
    }
}));

export default function AlertZona() {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [datas, setData] = useState([])
    const [selectedDate, handleDateChange] = useState(null);

    // GLOBAL STATE
    const dataUser = useSelector(state => state.UserReducer.dataUser)

    useEffect(() => {
        if (dataUser) {
            if (dataUser.role === 'petugas') {
                history.replace('/data-individu')
            }
        }
    }, [dataUser])

    const showDay = (date) => {
        let hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()

        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${SERVER}/data-absolute`,
            headers: {
                token: localStorage.getItem('token_access')
            }
        })
            .then(({ data }) => {
                setData(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className="alert-component-container" >
            <span className="beranda-text-top" >
                Data Absolute
            </span>
            <div className='div-top'>
                <div className="filter-absolute-date-box">
                    <div className="filter-absolute-date-input" >
                        <KeyboardDatePicker
                            clearable
                            value={selectedDate}
                            placeholder="Filter Berdasarkan Tanggal"
                            onChange={date => handleDateChange(date)}
                            format="dd/MM/yyyy"
                            style={{
                                width: '100%',
                                paddingLeft: 15
                            }}
                            InputProps={{
                                disableUnderline: true
                            }}
                            size="small"
                        />
                    </div>
                </div>
                <div>
                    <Button color='secondary' variant='contained' onClick={() => [history.push('/data-absolute/add')]} className={classes.addButton}><Box className='boxAdd'><AddIcon style={{ fontSize: "15px" }} /></Box>  Tambah Data</Button>
                </div>

            </div>
            <div className="table-alert-list">
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tableHead}>
                                <TableCell className={classes.headerTable} style={{ fontWeight: '500', }} >
                                    No
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ fontWeight: '500', }}>
                                Hari & Tanggal
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{  backgroundColor: "#FECD1A", fontWeight: 500, color: 'white', borderRightColor: '#fed644' }} >
                                <span>
                                    Jumlah
                                </span>
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <span>
                                    Tes
                                    </span>
                                    <span style={{ marginLeft: 3 }} >
                                    PCR
                                    </span>
                                </div>
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#FECD1A", fontWeight: 500, color: 'white', borderRightColor: '#fed644'}} >
                                Positif Rate
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ fontWeight: '600', backgroundColor: "#FECD1A", color: 'white', }}>
                                Terkonfirmasi Sulteng
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#F22C39", fontWeight: 500, color: 'white', borderRightColor: '#f3414c'}}>
                                Kasus Konfirmasi
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#F22C39", fontWeight: 500, color: 'white', borderRightColor: '#f3414c'}}>
                                Positif Aktif
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ fontWeight: '600', backgroundColor: "#F22C39", color: 'white', borderRightColor: '#f3414c' }}>
                                Kasus Aktif Sulteng
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#159E3C", fontWeight: 500, color: 'white', borderRightColor: '#2ca74f'}}>
                                Sembuh
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#159E3C", fontWeight: 500, color: 'white', borderRightColor: '#2ca74f'}}>
                                Angka Kesembuhan
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ fontWeight: '600',backgroundColor: "#159E3C", color: 'white', borderRightColor: '#2ca74f' }}>
                                Kasus Sembuh Sulteng
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#3251FF", fontWeight: 500, color: 'white', borderRightColor: '#4662ff'}}>
                                Meninggal
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ backgroundColor: "#3251FF", fontWeight: 500, color: 'white', borderRightColor: '#4662ff'}}>
                                <div >
                                    <span>
                                    Case
                                    </span>
                                    <span style={{ marginLeft: 3 }} >
                                    Fatality
                                    </span>
                                </div>
                                <div >
                                    <span>
                                    Rate
                                    </span>
                                    <span style={{ marginLeft: 3 }} >
                                    (CFR)
                                    </span>
                                </div>
                                </TableCell>
                                <TableCell className={classes.headerTable} style={{ borderRight: 'none',fontWeight: '600', backgroundColor: "#3251FF", color: 'white' }} >
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <span>
                                    Kasus
                                    </span>
                                    <span style={{ marginLeft: 3 }} >
                                    Meninggal
                                    </span>
                                </div>
                                <span>
                                Sulteng
                                </span>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                datas &&
                                datas
                                    .filter(a => selectedDate ? showDate(new Date(a.createdAt)) == showDate(new Date(selectedDate)) : a)
                                    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((data, index) => (
                                        <TableRow key={index} className={classes.tableRow}>

                                            <TableCell component="th" scope="row" align="center" className={classes.tableCellLeft} style={{ borderColor: '#e7e7e7', borderRightColor: '#b2b2b2', borderTopColor: index == 0 ? '#b2b2b2' : '#e7e7e7', width: '3vw' }} >
                                                {index += 1}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell1} style={{ borderColor: '#e7e7e7',  borderTopColor: index == 1 ? '#b2b2b2' : '#e7e7e7', borderRightColor: '#b2b2b2', width: '10vw' }} >
                                               {showDay(new Date(data && data.createdAt))}, <br/> {showDate(new Date(data && data.createdAt))}, <br/> {showHour(new Date(data && data.createdAt))}
                                            </TableCell>

                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                { data && data.PCR ? data.PCR : 0}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }} >
                                                {((Number(data && data.konfirmasi ? data.konfirmasi : 0)/Number(data && data.PCR ? data.PCR : 0))*100).toFixed(0)}%
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', borderRightColor: '#b2b2b2',fontSize: 12, fontWeight: 600 }} >
                                                {data && data.konfirmasiSulteng ? data.konfirmasiSulteng : 0}
                                            </TableCell>

                                            <TableCell align="center" className={classes.tableCell2} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                { data && data.konfirmasi ? data.konfirmasi : 0}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                {(Number(data && data.konfirmasi ? data.konfirmasi : 0) - Number(data && data.sembuh ? data.sembuh : 0) - Number(data && data.meninggal ? data.meninggal : 0))}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', borderRightColor: '#b2b2b2',fontSize: 12, fontWeight: 600 }}>
                                                {data && data.aktifSulteng ? data.aktifSulteng : 0}
                                            </TableCell>

                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                { data && data.sembuh ? data.sembuh : 0}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell2} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                {((Number(data && data.sembuh ? data.sembuh : 0)/Number(data && data.konfirmasi ? data.konfirmasi : 0))*100).toFixed(0)}%
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCell} style={{ borderColor: '#e7e7e7', borderRightColor: '#b2b2b2',fontSize: 12, fontWeight: 600 }}>
                                                {data && data.sembuhSulteng ? data.sembuhSulteng : 0}
                                            </TableCell>

                                            <TableCell align="center" className={classes.tableCellRight} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                                {data && data.meninggal ? data.meninggal : 0}
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCellRight} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 500 }}>
                                            {((Number(data && data.meninggal ? data.meninggal : 0)/Number(data && data.konfirmasi ? data.konfirmasi : 0))*100).toFixed(0)}%
                                            </TableCell>
                                            <TableCell align="center" className={classes.tableCellRight} style={{ borderColor: '#e7e7e7', fontSize: 12, fontWeight: 600 }}>
                                                {data && data.meninggalSulteng ? data.meninggalSulteng : 0}
                                            </TableCell>

                                        </TableRow>
                                    ))
                            }
                        </TableBody>
                        <TableFooter >
                            <TableCell colSpan={18}>
                                <Box marginTop='2px' display='flex' justifyContent='flex-end'>
                                    <TablePagination
                                        labelRowsPerPage='Tampilan per halaman:'
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={7}
                                        count={arr ? arr.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}`}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </Box>

                            </TableCell>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}