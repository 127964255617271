import React from 'react'

import { useMediaQuery  } from 'react-responsive'


function SecondContent () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })


    return (
        <div className="sc-345-two">
                
            <div className="sc-345-two-content" style={{marginLeft : "0"}} >

                <div className="sc-345-two-circle">

                </div>

                <div className="sc-345-two-title">
                    Fitur yang Tersedia
                </div>

                <div className="sc-345-two-body">
                    Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </div>

            </div>

            <div className="sc-345-two-content">

                <div className="sc-345-two-circle">

                </div>

                <div className="sc-345-two-title">
                    Fitur yang Tersedia
                </div>

                <div className="sc-345-two-body">
                    Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </div>

            </div>

            <div 
                className="sc-345-two-content" 
                style={
                    {
                        marginLeft : isTabletOrMobile ? "0" : null, 
                        marginTop : isTabletOrMobile ? "39px" : null
                    }}
            >

                <div className="sc-345-two-circle">

                </div>

                <div className="sc-345-two-title">
                    Fitur yang Tersedia
                </div>

                <div className="sc-345-two-body">
                    Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </div>

            </div>

            <div 
                className="sc-345-two-content" 
                style={
                    {
                        marginTop : isTabletOrMobile ? "39px" : null
                    }
                }
            >

                <div className="sc-345-two-circle">

                </div>

                <div className="sc-345-two-title">
                    Fitur yang Tersedia
                </div>

                <div className="sc-345-two-body">
                    Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </div>

            </div>

        </div>
    )

}

export default SecondContent