// MODULE
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// COMPONENT
import LeftContent from './LeftContent'
import RiwayatStatus from './RiwayatStatus'
import RightContent from './RightContent'
import BoxContent from '../BoxContent'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import swal from 'sweetalert2'

// STYLE
import './style.scss'
import axios from 'axios'
import SERVER from '../../../endPoint'

function DetailIndividu ({ match }) {
    const history = useHistory()
    const [patient, setPatient] = useState({})
    const [patientAccount, setPatientAccount] = useState({})
    const [refreshData, setRefreshData] = useState(false)

    useEffect(()=>{
        swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/patient/${match.params.id}`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setPatient(data.patientId)
            setPatientAccount(data)
            setRefreshData(false)
            swal.close()
        })
        .catch(err=>{
            swal.close()
        })
    },[refreshData == true])

    return (
        <div className="detail-individu-container-fluid" >
            <div className="back-container" >
                <ArrowBackIosIcon className="back-icon" onClick={()=>history.goBack()}/>
                <span className="back-text" onClick={()=>history.goBack()}>
                    Kembali
                </span>
            </div>
            <BoxContent/>
            <div className="detail-individu-879-container">
                <div className="detail-left-content">
                    <LeftContent
                        patient={patient}
                        ID={match.params.id}
                        refreshData={setRefreshData}
                    />
                    <RiwayatStatus
                        flag={patient.isolasiMandiri}
                        patient={patient.historyStatus}
                    />
                </div>
                <RightContent
                    patientAccount={patientAccount}
                    patient={patient}
                />
            </div>
        </div>
    )

}

export default DetailIndividu