import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import './style.scss'
import './mobile.scss'

function FirstContent (props) {
    const history = useHistory()
    const {
        newsList,
        showDay,
        showDate,
        showHour
    } = props
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const isLaptopM = useMediaQuery({ query: '(max-width: 1024px)' })

    return (

        <div className="lpb-787-fc">

            {
                newsList && newsList[0] && newsList[0].updatedAt &&
                <div className="lpb-787-fc-1">

                    <div className="lpb-787-fc-1-q" style={{ backgroundImage: `url('${newsList && newsList[0] && newsList[0].bannerImage}')` }}>

                    </div>

                    <div className="lpb-787-fc-1-w" >
                        <div className="lpb-787-fc-1-w-t1">
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={()=>history.push(`/detail-berita/${newsList && newsList[0] && newsList[0]._id}`)}
                            >
                                {
                                    isTabletOrMobile ?
                                    newsList[0].judulBerita.split('').length > 30 ?
                                        newsList[0].judulBerita.slice(0,30)+'...'
                                        :
                                        newsList[0].judulBerita.slice(0,30)
                                    :
                                    isLaptopM ?
                                    newsList[0].judulBerita.split('').length > 60 ?
                                        newsList[0].judulBerita.slice(0,60)+'...'
                                        :
                                        newsList[0].judulBerita.slice(0,60)
                                    :
                                    newsList[0].judulBerita.split('').length > 100 ?
                                        newsList[0].judulBerita.slice(0,100)+'...'
                                        :
                                        newsList[0].judulBerita.slice(0,100)
                                }
                            </span>
                        </div>
                        <div className="lpb-787-fc-1-w-t2">
                            <span>
                                <span className="green-day112" >
                                    {showDay(newsList && newsList[0] && newsList[0].updatedAt) }
                                </span>
                                , {showDate(newsList && newsList[0] && newsList[0].updatedAt)}/
                                {showHour(newsList && newsList[0] && newsList[0].updatedAt)}
                            </span>
                        </div>
                    </div>

                </div>
            }


            <div className="lpb-787-fc-2">
                {
                    newsList &&
                    newsList
                        .slice(1,4)
                        .sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
                        .map((el,i)=>(
                            <div className="lpb-787-fc-2-e" key={i} >

                                <div className="lpb-787-fc-2-e-item1" style={{ backgroundImage: `url('${el.bannerImage}')` }}>

                                </div>

                                <div className="lpb-787-fc-2-e-item2">
                                    <div className="lpb-787-fc-2-e-item2-1">
                                        <span
                                            style={{ cursor: "pointer" }}
                                            onClick={()=>history.push('/detail-berita/'+el._id)}
                                        >
                                            {
                                                isTabletOrMobile ?
                                                el.judulBerita.split('').length > 50 ?
                                                    el.judulBerita.slice(0,50)+'...'
                                                    :
                                                    el.judulBerita.slice(0,50)
                                                :
                                                isLaptopM ?
                                                el.judulBerita.split('').length > 50 ?
                                                    el.judulBerita.slice(0,50)+'...'
                                                    :
                                                    el.judulBerita.slice(0,50)
                                                :
                                                el.judulBerita.split('').length > 100 ?
                                                    el.judulBerita.slice(0,100)+'...'
                                                    :
                                                    el.judulBerita.slice(0,100)
                                            }
                                        </span>
                                    </div>
                                    <div className="lpb-787-fc-2-e-item2-2">
                                        <span>
                                            <span className="green-day112" >
                                                {showDay(el.updatedAt) }
                                            </span>
                                            , {showDate(el.updatedAt)}/
                                            {showHour(el.updatedAt)}
                                        </span>
                                    </div>
                                    
                                </div>

                            </div>
                        ))
                }

            </div>

        </div>

    )

}

export default FirstContent;