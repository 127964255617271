import React , { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

//COMPONENT
import Header from '../LandingPageKabupaten/Header'
import NavbarMobile from '../../components/NavbarMobile'
import FooterContent from '../../components/FooterContent1'
import Content from './Content'
import Paragraph3 from './Paragraph3'
import Paragraph4 from './Paragraph4'
import Paragraph5 from './Paragraph5'
import Paragraph6 from './Paragraph6'
import Paragraph10 from './Paragraph10'
import Paragraph12 from './Paragraph12'
import Paragraph13 from './Paragraph13'
import Paragraph15 from './Paragraph15'
import Paragraph16 from './Paragraph16'
import Paragraph17 from './Paragraph17'
import Paragraph19 from './Paragraph19'

import './style.scss'
import './responsive.scss'

function DaftarPertanyan () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' })

    const [detail,setDetail] = useState([])
    
    useEffect(()=>{
        document.getElementById("clickA").focus()
    },[])

    let clickMore = (id) => {
        if (!detail.includes(id)) {
            let result = [...detail]
            result.push(id)
            setDetail(result)
        }else {
            let result = [...detail]
            result = result.filter(e=> e !== id)
            setDetail(result)
        }
    }


    return (
        <div className="daftar-pertanyaan-container-254" id="onthetop" >
            <a href="top" id="clickA" ></a>

            {
                isTabletOrMobile ? <NavbarMobile marginBottomShow={30}/> : <Header />
            }

            <div className="db-418-title" style={{marginTop : isTabletOrMobile ? "30px" : null}}>
                <div style={{marginRight : "5px"}}>Beranda</div> <span style={{marginRight : "5px"}}>></span>  Tentang Covid-19
            </div>

            <div className="dpc-254-title-1-254">
                Pertanyaan Paling Sering Diajukan Tentang Covid-19
            </div>
            

            <Content
                detail={detail}
                clickMore={clickMore}
                id={0}
                question={"Apa itu Corona Virus?"}
                answer={`Coronavirus adalah suatu kelompok virus yang dapat menyebabkan penyakit pada hewan atau manusia. Beberapa jenis coronavirus diketahui menyebabkan infeksi saluran nafas pada manusia mulai dari batuk pilek hingga yang lebih serius seperti Middle East Respiratory Syndrome (MERS) dan Severe Acute Respiratory Syndrome (SARS). Coronavirus jenis baru yang ditemukan menyebabkan penyakit COVID-19.`}
                style={{marginTop : isTabletOrMobile ? "44px" :  "152px"}}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={1}
                question={"Apa itu Covid-19?"}
                answer={"COVID-19 adalah penyakit menular yang disebabkan oleh jenis coronavirus yang baru ditemukan. Ini merupakan virus baru dan penyakit yang sebelumnya tidak dikenal sebelum terjadi wabah di Wuhan, Tiongkok, bulan Desember 2019."}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={2}
                question={"Apa saja gejala COVID-19?"}
                answer={"Gejala-gejala COVID-19 yang paling umum adalah demam, rasa lelah, dan batuk kering. Beberapa pasien mungkin mengalami rasa nyeri dan sakit, hidung tersumbat, pilek, sakit tenggorokan atau diare, Gejala-gejala yang dialami biasanya bersifat ringan dan muncul secara bertahap. Beberapa orang yang terinfeksi tidak menunjukkan gejala apa pun dan tetap merasa sehat. Sebagian besar (sekitar 80%) orang yang terinfeksi berhasil pulih tanpa perlu perawatan khusus. Sekitar 1 dari 6 orang yang terjangkit COVID-19 menderita sakit parah dan kesulitan bernapas. Orang-orang lanjut usia (lansia) dan orang-orang dengan kondisi medis yang sudah ada sebelumnya seperti tekanan darah tinggi, gangguan jantung atau diabetes, punya kemungkinan lebih besar mengalami sakit lebih serius. Mereka yang mengalami demam, batuk dan kesulitan bernapas sebaiknya mencari pertolongan medis."}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={3}
                question={"Bagaimana cara COVID-19 menyebar?"}
                answerHtml={<Paragraph3/>}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={4}
                // style={{marginBottom : "200px"}}
                question={"Apa yang dapat saya lakukan untuk melindungi diri saya dan mencegah penyebaran penyakit ini?"}
                answerHtml={
                   <Paragraph4/>
                }
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={5}
                // style={{marginBottom : "200px"}}
                question={"Seberapa besar kemungkinan saya tertular COVID-19?"}
                answerHtml={
                   <Paragraph5/>
                }
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={6}
                // style={{marginBottom : "200px"}}
                question={"Apakah saya harus khawatir tentang COVID-19?"}
                answerHtml={
                   <Paragraph6/>
                }
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={7}
                question={"Siapa yang berisiko menderita penyakit serius?"}
                answer={`Sementara ini kita masih perlu mempelajari dampak COVID-19 terhadap manusia, tetapi sejauh ini lansia dan orang-orang yang sudah memiliki keadaan medis sebelumnya (seperti tekanan darah tinggi, penyakit jantung, penyakit paru-paru, kanker atau diabetes) terindikasi mengalami sakit yang lebih parah.`}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={8}
                question={"Apakah antibiotik efektif mencegah dan menangani COVID-19?"}
                answer={`Tidak. Antibiotik tidak dapat melawan virus, melainkan hanya melawan infeksi bakteri. COVID-19 disebabkan oleh virus sehingga antibiotik tidak efektif. Antibiotik tidak boleh digunakan untuk mencegah atau mengobati COVID-19. Antibiotik hanya digunakan sesuai arahan dokter untuk mengobati infeksi bakteri.`}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={9}
                question={"Apakah ada obat atau perawatan yang dapat mencegah atau menyembuhkan COVID-19?"}
                answer={`Meskipun obat-obatan barat, tradisional atau buatan sendiri dapat meringankan gejala-gejala COVID-19, obat-obatan yang ada belum terbukti dapat mencegah atau menyembuhkan penyakit ini. WHO tidak merekomendasikan tindakan mengobati diri sendiri dengan obat apa pun, termasuk antibiotik, untuk mencegah atau menyembuhkan COVID-19. Namun, beberapa obat-obatan barat dan tradisional sedang diuji klinis. WHO akan terus memberikan informasi terbaru seiring tersedianya temuan klinis.`}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={10}
                question={"Apakah ada vaksin, obat atau perawatan untuk COVID-19?"}
                answerHtml={<Paragraph10/>}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={11}
                question={"Apakah COVID sama dengan SARS?"}
                answer={`Tidak. Virus penyebab COVID-19 dan virus penyebab wabah Severe Acute Respiratory Syndrome (SARS) tahun 2003 memang memiliki hubungan genetik dengan satu sama lain, tetapi penyakit yang ditimbulkannya berbeda. SARS lebih mematikan tetapi penularannya lebih rendah dibandingkan COVID-19. Tidak ada wabah SARS di mana pun sejak tahun 2003.`}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={12}
                question={"Perlukah saya mengenakan masker untuk melindungi diri saya?"}
                answerHtml={<Paragraph12/>  }
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={13}
                question={"Cara mengenakan, menggunakan, melepas dan membuang masker"}
                answerHtml={<Paragraph13/>  }
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={14}
                question={"Berapa lama masa inkubasi COVID-19?"}
                answer="Masa inkubasi adalah jangka waktu antara terjangkit virus dan munculnya gejala penyakit. Pada umumnya masa inkubasi COVID-19 diperkirakan berkisar dari 1 hingga 14 hari, umumnya sekitar lima hari. Perkiraan ini akan diperbarui seiring dengan tersedianya lebih banyak data."
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={15}
                question={"Apakah manusia dapat terinfeksi COVID-19 dari hewan?"}
                answerHtml={<Paragraph15/>}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={16}
                question={"Apakah saya dapat tertular COVID-19 dari hewan peliharaan saya?"}
                answerHtml={<Paragraph16/>}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={17}
                question={"Berapa lama virus ini bertahan di atas permukaan?"}
                answerHtml={<Paragraph17/>}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={18}
                question={"Apakah paket dari tempat di mana COVID-19 telah dilaporkan terjadi aman untuk diterima?"}
                answer={"Ya. Kecil kemungkinan barang komersial terkontaminasi dari orang yang terinfeksi dan kecil juga risiko tertular virus penyebab COVID-19 dari paket yang sudah berpindah, berada dalam perjalanan, dan terpapar kondisi serta suhu yang berbeda-beda."}
            />

            <Content
                detail={detail}
                clickMore={clickMore}
                id={19}
                question={"Apakah paket dari tempat di mana COVID-19 telah dilaporkan terjadi aman untuk diterima?"}
                answerHtml={<Paragraph19/>}
            />
           
            <div style={{marginBottom : "90px"}}>

            </div>

            <FooterContent/>
            
        </div>
    )

}

export default DaftarPertanyan