import React from 'react'

function Paragraph3 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginTop : "0"}}>
                Orang dapat tertular COVID-19 dari orang lain yang terjangkit virus ini. COVID-19 dapat menyebar dari orang ke orang melalui percikan-percikan dari hidung atau mulut yang keluar saat orang yang terjangkit COVID-19 batuk atau mengeluarkan napas. Percikan-percikan ini kemudian jatuh ke benda-benda dan permukaan-permukaan di sekitar. Orang yang menyentuh benda atau permukaan tersebut lalu menyentuh mata, hidung atau mulutnya, dapat terjangkit COVID-19. Penularan COVID-19 juga dapat terjadi jika orang menghirup percikan yang keluar dari batuk atau napas orang yang terjangkit COVID-19. Oleh karena itu, 
                penting bagi kita untuk menjaga jarak lebih dari 1 meter dari orang yang sakit. WHO terus mengkaji perkembangan penelitian tentang cara penyebaran COVID-19 dan akan menyampaikan temuan-temuan terbaru.
            </p>
            <p>
                Apakah virus penyebab COVID-19 ini dapat menular melalui udara?
                Menurut penelitian sejauh ini, virus penyebab COVID-19 ini umumnya menular melalui kontak dengan percikan dari saluran pernapasan, bukan melalui udara. Lihat jawaban sebelumnya tentang “Bagaimana cara COVID-19 menyebar?”
            </p>
            <p>
                Apakah COVID-19 dapat menular dari orang yang tidak menunjukkan gejala? Cara utama penyebaran penyakit ini 
                adalah melalui percikan saluran pernapasan yang dihasilkan saat batuk. Risiko penularan COVID-19 dari orang yang tidak ada gejala sama sekali sangatlah rendah. Namun, banyak orang yang terjangkit COVID-19 hanya mengalami gejala-gejala ringan, terutama pada tahap-tahap awal. Karena itu, COVID-19 dapat menular dari orang yang, misalnya, hanya batuk ringan tetapi merasa sehat. WHO terus mengkaji perkembangan penelitian tentang cara penyebaran COVID-19 dan akan menyampaikan temuan-temuan terbaru.
            </p>
            <p style={{marginBottom : "0"}}>
                Apakah saya dapat tertular COVID-19 dari feses orang yang terjangkit penyakit ini? Risiko penularan COVID-19 dari 
                feses orang yang terinfeksi COVID-19 adalah kecil. Penelitian awal memang mengindikasikan bahwa dalam kasus-kasus tertentu virus ini bisa ada di feses, tetapi dalam konteks wabah yang sedang terjadi ini, rute penularan ini tidak menjadi kekhawatiran. WHO terus mengkaji perkembangan penelitian tentang cara penyebaran COVID-19 dan akan menyampaikan temuan-temuan terbaru. Namun demikian, karena risiko tetap ada (walaupun kecil), hal ini memperkuat alasan mengapa kita harus rajin mencuci tangan setelah menggunakan kamar mandi dan sebelum makan.
            </p>
        </div>
    )

}

export default Paragraph3