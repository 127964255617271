import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

// STYLING
import './style.scss'
import './mobile.scss'
import './new.scss'
// import './mobilenew.scss'

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    infoBtn:{
        width: '189px',
        height: '38px',
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#FFFFFF',
        backgroundColor: "#0185FF",
        fontWeight: '500',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)',
        marginTop: 34,
    }
}));

function LeftContent () {
    const [banggai] = useState(true)
    const [poso] = useState(false)
    const classes = useStyles()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    return (
        <div className="lpk-content-867-lc">

            <div className="container-zx">
                <span className="hd-text">
                    Kabupaten Banggai Tanggap Covid-19
                </span>
                <span className="hd-text-1213" >
                    “Mulai hari ini, sakit atau tidak, pakai masker di luar rumah rekomedasi WHO. Masyarakat diminta menggunakan masker saat beraktifitas di luar rumah. Saya meminta agar masyarakat kabupaten banggai mematuhi himbauan tersebut”
                    <p className="hd-txt-3312" >
                        Dr. Ir. H. Herwin Yatim, MM, Bupati Banggai
                    </p>
                </span>
                <a href="#data-terkini" className="href-link" >
                    <Button
                        className={ classes.infoBtn }
                        style={{ background: 'linear-gradient(108.43deg, #0185FF 0.77%, #7B01FF 98.93%)', textTransform: 'none' }}
                    >
                        Lihat Data Terkini
                    </Button>
                </a>
            </div>
            <div className="ft-hhiu23">
                <div className="footer-09809" >
                    <div className="ftyt-3131" >
                        <span className="fnt-bold" >
                            Hubungi Kami:
                        </span>
                        <span className="fnt-nrml" >
                            Gugus Tugas Covid 19 Kab. Banggai
                        </span>
                        <span className="fnt-nrml">
                            Ibu Nurmasyita Datu Adam 
                        </span>
                        <span className="fnt-nrml">
                            HP, 0852-8169-1921
                        </span>
                    </div>
                    <div className="ftyt-3131321" >
                        <span className="fnt-bold" >
                            Rumah Sakit Rujukan Covid-19
                        </span>
                        <span className="fnt-nrml" >
                            RSUD Luwuk
                        </span>
                        <span className="fnt-nrml">
                            Jl. Imam Bonjol, No. 14 Luwuk, Kabupaten Banggai, Sulawesi Tengah 
                        </span>
                        <span className="fnt-nrml">
                            Ibu Hartati Labongkeng, HP 0852-8505-0806 
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default LeftContent