import React from 'react'

import './style.scss'

function TitleContet (props) {

    const { title } = props

    return (
        <div className="di-title-content-879-container">
            {title}
        </div>
    )

}

export default TitleContet