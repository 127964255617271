import React from 'react'

// COMPONENT 
import LeftContent from './LeftContent'
import RightContent from './RightContent'

// STYLING
import './style.scss'

function Header () {

    return (
        <div className="lp-867-header-kecamatan">

            <LeftContent/>

            <RightContent/>
            
        </div>
    )

}

export default Header