import React, { useState, useEffect } from 'react'
import './style.scss'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker'
import { Button } from '@material-ui/core';
import swal from 'sweetalert2';
import axios from 'axios'
import SERVER from '../../endPoint/index.js'
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        color: "#999999"
    },
    infoBtn:{
        width: '110px',
        height: '34px',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#FFFFFF',
        lineHeight: '21px',
        backgroundColor: "#0185FF",
        marginLeft: 15
    }
}))

export default function History(){
    const [searchDate, setDateSearch] = useState(null)
    const [harianList, setHarianList] = useState([])
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [refresh, setRefresh] = useState(true);

    useEffect(()=>{
        if (refresh === true) {
            swal.showLoading()
            axios({
                method : "GET",
                url : `${SERVER}/harian`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                swal.close()
                setRefresh(false)
                setHarianList(data)
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[refresh === true])

    const handleClick = () => {
        if (!searchDate) {
            swal.fire({
                text: 'Silahkan Input Tanggal',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                data : {
                    data : showDate(searchDate),
                    today : new Date(searchDate)
                },
                url : `${SERVER}/history`,
                method : "POST"
            })
            .then(({data})=>{
                swal.close()
                setRefresh(true)
                swal.fire({
                    title: 'Selamat',
                    text: 'Berhasil Input Data',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0280A2',
                })
            })
            .catch(err=>{
                swal.close()
                if(err.response){
                    if (err.response.data) {
                        swal.fire({
                            title: 'Gagal',
                            text: err.response.data,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#0280A2',
                        })
                        
                    }
                }
            })
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return(
        <div className="histrf-cntg-123" >
            <span className="beranda-text-top" >
                Buat Data History Harian
            </span>
            <div className="histr-boxbtn" >
                <div className="search-guest-content-first-4" >
                    <DatePicker 
                        className="date-search-picker-09" 
                        selected={ searchDate } 
                        dateFormat="d MMMM yyyy" 
                        placeholderText="Cari Tanggal"
                        onChange={ date => setDateSearch(date) } 
                        maxDate={new Date()}
                    />
                    <div className="container-for-search-icon-first-2">
                        <EventIcon className={classes.searchIcon}/>
                    </div>
                </div>
                <Button type="submit" size="small" style={{ backgroundColor: "#0185FF" }} className={classes.infoBtn}
                    onClick={()=>handleClick()}
                >
                    Buat History
                </Button>
            </div>

            <div className="histry-tble" >
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tableHead}>
                                <TableCell className={classes.headerTable1}>
                                    <div className="beranda-row-nomor">NO</div>
                                </TableCell>
                                <TableCell className={classes.headerTable} >
                                    <div className="beranda-row-nomor">TANGGAL</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            harianList &&
                            harianList
                                .sort((a,b) =>  new Date(b.updatedAt) - new Date(a.updatedAt))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((harian, index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell component="th" scope="row" align="left" className={classes.tableCell1}>
                                        { index+=1 }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {harian.date}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination
                                    labelRowsPerPage='Tampilan per halaman:'
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={2}
                                    count={ harianList.length }
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}` }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{textAlign : "right"}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}