import React from 'react'

import './style.scss'

function MainContent (props) {

    const { title, value , lastContent } = props

    return (
        <div className="di-main-content-879-container">
            
            <div 
                className="di-main-content-879-content"
                style={{
                    borderBottom : lastContent ? "none" : null
                }}
            >

                <div className="di-m-title-879">
                    {title } 
                </div>

                <div className={"di-m-value-879"}>
                    {value}
                </div>

            </div>

        </div>
    )

}

export default MainContent;