import { combineReducers } from 'redux'
import UserReducer from './user'
import SettingState from './settings'
import individualState from './individual'
import individualConditionState from './individualCondition'

const reducers = combineReducers({
    UserReducer,
    SettingState,
    individualState,
    individualConditionState
})

export default reducers