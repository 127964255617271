// MODULE
import React from 'react'
import { useDispatch , useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputWithDate (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        code2,
        type,
        isDisable
    } = props

    // GLOBAL STATE
    const dataGlobal = useSelector(state=>state.individualState)

    // DISPATCH
    const dispatch = useDispatch()
    
    let changeInput = (value,code) => {
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : value}))
    }

    return (

        <div className="input-with-data-container-612" >

            <div className="iwd-title-612">
                {title}
            </div>

            <div className="iwd-container-dateinput-612">
                
                <DatePicker 
                    // minDate={new Date()} 
                    // className="pu-input-time" 
                    className="iwd-input-612" 
                    // style={ { border: 'none' } } 
                    // selected={ new Date() } 
                    disabled={ isDisable ? true : false}
                    dateFormat="MMMM d, yyyy" 
                    placeholderText="Dari Tanggal"
                    onChange={ date => changeInput(date,code) } 
                    selected={ dataGlobal[code] && dataGlobal[code] !== "-" ? new Date(dataGlobal[code]) : new Date()}
                />

                <DatePicker 
                    disabled={ isDisable ? true : false}
                    className="iwd-input-612 iwd-right-input-612" 
                    selected={ dataGlobal[code2] && dataGlobal[code2] !== "-" ? new Date(dataGlobal[code2]) : new Date()} 
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    placeholderText="Dari Tanggal"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={ 30 }
                    timeCaption="Waktu"
                    onChange={ date => changeInput(date,code2) } 
                    // onChange={ date => setTanggal(date) } 
                />

            </div>

    
        </div>

    )

}

export default InputWithDate