import React from 'react'

function Paragraph17 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Belum dipastikan berapa lama virus penyebab COVID-19 bertahan di atas permukaan, tetapi perilaku virus ini menyerupai jenis-jenis coronavirus lainnya. Penelitian coronavirus, dan juga informasi awal tentang virus penyebab COVID-19, mengindikasikan bahwa coronavirus dapat bertahan di permukaan antara beberapa jam hingga beberapa hari. Lamanya coronavirus bertahan mungkin dipengaruhi kondisi-kondisi yang berbeda (seperti jenis permukaan, suhu atau kelembapan lingkungan).
            </p>
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Jika Anda merasa suatu permukaan mungkin terinfeksi, bersihkanlah dengan disinfektan sederhana untuk membunuh virus dan melindungi diri Anda dan orang lain. Cuci tangan Anda dengan air bersih mengalir dan sabun atau cairan antiseptik berbahan dasar alkohol. Hindari menyentuh mata, mulut, atau hidung Anda.
            </p>
        </div>
    )

}

export default Paragraph17