import React from 'react'

// IMAGE
import banggai from '../../../../images/kab-banggai-logo.png'

// STYLING
import './style.scss'


function LeftContent () {


    return (
        <div className="header-867-left-content">

                <img
                    className="hlc-867-one"
                    src={banggai}
                />

                <div className="hlc-867-two">

                    <div className="hlc-867-two-1">
                        Pemerintah Kabupaten Banggai
                    </div>

                    <div className="hlc-867-two-2">
                        Gugus Tugas Covid 19
                    </div>

                </div>

            </div>

    )

}

export default LeftContent