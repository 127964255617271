import React from 'react'
import { useMediaQuery } from 'react-responsive'


function Paragraph6 () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' })

    return (
        <div className="dpc-word-content-254">
            <p style={{marginBottom : "0",marginTop : "0"}}>
                Penyakit yang disebabkan infeksi COVID-19 pada umumnya bersifat ringan, terutama pada anak-anak dan orang dewasa muda. 
                Namun, infeksi ini tetap dapat menyebabkan penyakit serius: sekitar 1 dari 5 orang yang terjangkit membutuhkan perawatan di rumah sakit. Karenanya, wajar jika orang khawatir tentang dampak wabah COVID-19 pada diri mereka dan orang-orang yang mereka kasihi.
            </p>
            <p style={{marginTop : "0",marginBottom : "0"}}>
                Kita dapat menyalurkan kekhawatiran kita dengan cara melindungi diri kita, orang-orang yang kita kasihi dan masyarakat kita. 
                Tindakan yang terpenting adalah rajin mencuci tangan secara menyeluruh dan menutup hidung dan mulut saat batuk dan bersin. Selain itu, tetap ikuti perkembangan informasi dan patuhi nasihat dinas kesehatan setempat termasuk pembatasan perjalanan, pergerakan dan pertemuan yang diberlakukan.
            </p>
            <p style={{marginTop : "0",width : !isTabletOrMobile ? "120%" : null,marginBottom : "0"}}>
                Pelajari lebih lanjut cara melindungi diri di <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public">https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public</a> 
            </p>
        </div>
    )
}

export default Paragraph6