import React from 'react'

function Paragraph15 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Coronavirus adalah kelompok virus yang biasanya terdapat pada binatang. Terkadang, 
                orang terinfeksi virus-virus ini, yang kemudian menyebar ke orang lain, seperti SARS-CoV dan MERS-CoV. SARS-CoV dikaitkan dengan musang, sedangkan MERS-CoV ditularkan oleh unta. Hewan penular COVID-19 belum bisa dipastikan sampai saat ini.
            </p>
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Untuk melindungi diri, misalnya saat mengunjungi pasar hewan hidup, hindari kontak langsung dengan hewan hidup dan permukaan yang bersentuhan dengan hewan. Pastikan kebersihan makanan selalu dijaga. 
                Berhati-hatilah ketika memegang daging, susu atau organ hewan mentah untuk menghindari kontaminasi dengan makanan mentah dan hindari konsumsi produk-produk hewan yang mentah atau tidak matang sempurna.
            </p>
        </div>
    )

}

export default Paragraph15