// MODULE
import React , { useState, useEffect } from 'react'
import { useDispatch , useSelector } from 'react-redux'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputWithRadio (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        type,
        sameWith
    } = props

    // GLOBAL STATE
    const dataGlobal = useSelector(state=>state.individualState)
    
    // DISPATCH
    const dispatch = useDispatch()
    
    // LOCAL STATE
    const [isSameWith,setIsSamewith] = useState(false)

    // HOOKS
    let changeInput = (e) => {
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : e.target.value}))
    }

    useEffect(()=>{
        // console.log(dataGlobal[code] , ' [code')
        // console.log(dataGlobal[sameWith] , ' [SAME WIDTH')
        // console.log(dataGlobal[code] === dataGlobal[sameWith] , ' <<< KONDISI')
        if (dataGlobal[code] === dataGlobal[sameWith] && dataGlobal[sameWith]) {
            setIsSamewith(true)
        }
    },[dataGlobal])

    useEffect(()=>{
        console.log(isSameWith , ' << VALUE IS SAME WIDTH')
    },[isSameWith])

    let changeRadio = (e,condition) => {
        e.preventDefault()
        // console.log(isSameWith , ' <<< IS SAME WIDTH VALUE')
        let selector = document.getElementById(`radio-button-${code}-${sameWith}`)
        // selector.setAttribute('checked',true)
        // selector.checked = true
        setIsSamewith(true)
        console.log(selector , ' <<< VALUE SELECTOR')
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : dataGlobal[sameWith]}))


        // if (dataGlobal[code] !== dataGlobal[isSameWith]) {
        // }else {
        //     dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : null}))

        // }
        // if (isSameWith) {
        //     console.log('MASUK ATAS')
        // }else {
        //     dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : null}))
        //     console.log('MASUK BAWAH')
        // }
        // dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : null}))
    }

    return (

        <div className="input-with-radio-container-690">

            <div className="iwr-title-690">
                {title}
            </div>

            <form className="iwr-container-input-radio">
                <input 
                    onChange={e=>changeInput(e)}
                    className="iwr-input-690" 
                    placeholder={placeholder}
                    type={type}
                    value={dataGlobal[code] ? dataGlobal[code] : ""}
                />
                <label className="iwr-radio-container-690">

                    <input 
                        onClick={e=>changeRadio(e)}
                        // type="submit" 
                        type="radio"
                        // value={el}
                        checked={dataGlobal[code] === dataGlobal[sameWith ? true : false]}
                        className="iwr-radio-690"
                        // style={ { backgroundColor : isSameWith ? "pink" : null}}
                        id={`radio-button-${code}-${sameWith}`}
                    />
                    Alamat Saat ini Sesuai KTP
                </label>

            </form>
    
        </div>

    )

}

export default InputWithRadio