// MODULE
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import io from "socket.io-client";
import swal from 'sweetalert2'

// MATERIAL UI ICON
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

// ENDPOINT
import SERVER from '../../endPoint/index'

// GLOBAL ACTION
import { SET_DATA_USER_ACTIONS } from '../../store/actions/user'

// STYLE
import './style.scss'
import "../../fonts/style.css";
import './title.scss'

export default function MenuAppBar() {

    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userModal, setuserModal] = useState(null);
    const [notifModal, setNotifModal] = useState(null);
    const [loginName,setLoginName] = useState('user')

    const dispatch = useDispatch()

    let fetchDataUser = () => {
        // swal.showLoading()
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            // swal.close()
            console.log(data , ' <<< VALUE DATA DI THEN NAVBAR')
            console.log(data,'sasasasaas jojojo')
            dispatch(SET_DATA_USER_ACTIONS(data))
            setLoginName(data.name)
        })
        .catch(err=>{
            // swal.close()
            console.log(err , ' << ERROR')
        })
    }

    useEffect(()=>{
        let socket = io(`${SERVER}`)
        socket.on('fetch_settings',data=>{
            fetchDataUser()
        })
        fetchDataUser()
    },[])


    const History = useHistory()

    const open = Boolean(anchorEl);
    const openNotifModal = Boolean(notifModal);

    const StyledBadge = withStyles(theme => ({
        badge: {
            border: `2px solid #f16821`,
            padding: '0 4px',
            backgroundColor: '#488dfa',
            color: 'white',
            top: 6,
            right: 2
        },
    }))(Badge);

    const handleSeeNotif = () => {
        setNotifModal(null)
        History.push('/pemberitahuan')
    }

    const handleClose = () => {
        setAnchorEl(null);
        setuserModal(null);
        setNotifModal(null)
    };

    const handleModalUser = event => {
        setNotifModal(event.currentTarget)
    };

    const logoutHandler = () =>{
        localStorage.clear()
        History.replace('/landing-page-kabupaten')
        dispatch( SET_DATA_USER_ACTIONS(null) )
        const Toast = swal.mixin({
            toast: true,
            position: 'bottom-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', swal.stopTimer)
                toast.addEventListener('mouseleave', swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: 'Berhasil Keluar'
        })
    }

    return (
        <div className="root" >
            <div position="static" className="navbar">
                <Toolbar className="root"
                    style={{
                        paddingRight: 24,
                        paddingLeft: 20,
                    }}
                >
                <Typography variant="h6" className="title" style={{
                    fontSize: 14,
                    cursor: 'default',
                    fontFamily: 'SFUIDisplay',
                    zIndex: 99
                }} >

                </Typography>
                {auth && (
                    <div>

                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleModalUser}
                        color="inherit"
                    >
                        <StyledBadge  max={999}>
                        <div className="navbar-title-90">
                            {loginName}
                        </div>
                        </StyledBadge>
                    </IconButton>

                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={()=>logoutHandler()}
                    >
                        <StyledBadge  max={999}>
                        <div className="navbar-title-90">
                            Logout
                        </div>
                        </StyledBadge>
                    </IconButton>

                    {/* <Menu
                        id="menu-appbar"
                        anchorEl={notifModal}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ top: 40, left: 90, outline: 'none' }}
                        open={openNotifModal}
                        onClose={handleClose}
                    >
                        <div style={{ maxHeight: 360, width: 380 , outline: 'none', overflowY: 'hidden' }}>
                            <div style={{ width: '100%', borderBottom: '1.1px solid #e3e3e3', paddingBottom: 7  }}>
                                <span style={{paddingLeft: 20, color : "#444444", fontFamily: 'SFUIDisplay', fontSize: '13px', fontWeight: 'bold'}}>Pemberitahuan</span>
                            </div>
                            <div style={{ maxHeight: 300, overflowY: 'auto' }} className="scrool-notification" >
                            </div>
                            <div onClick={()=>{ handleSeeNotif() }} style={{height: 30,cursor: 'pointer', paddingTop: 8,display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0 -2px 2px 0 rgba(0, 0, 0, 0.1)' }}>
                                <span style={{ color : "#f16821", fontFamily: 'SFUIDisplay', fontSize: '11px', cursor: 'pointer', fontWeight: 'normal' }} onClick={()=>{ handleSeeNotif() }} >Lihat Semua</span>
                            </div>
                        </div>
                    </Menu> */}
                    </div>
                )}
                </Toolbar>
            </div>

        </div>
    );
}
