import { SET_PATIENT_HEALTHY_STATUS, SET_PATIENT_TRANSIT_STATUS } from '../constanta'

const INITIAL_STATE = {
    isPatientHealty : false,
    isTransit : true
}

function individualConditionState (state = INITIAL_STATE ,  action) {
    switch (action.type) {
        case SET_PATIENT_HEALTHY_STATUS:
            return {...state , isPatientHealty : action.payload}
        case SET_PATIENT_TRANSIT_STATUS:
            return {...state , isTransit : action.payload}
        default:
            return state
    }
}

export default individualConditionState