// MODULE
import React , { useEffect} from 'react'

// IMAGE 
import Laptop from '../../../images/laptop-banggai.png'

// STYLING
import './style.scss'


// COMPONENT 
import LeftContent from './LeftContent'

function FirstContent () {

    return (
        <div className="mlp-345-first-content">
            
            <LeftContent/>

            
            <div className="mlp-345-fc-rc-container">

                <div className="fcrc-one-345">

                    <div className="fcrc-one-345-item" style={{marginLeft : "0"}}>
                        Beranda
                    </div>

                    <div className="fcrc-one-345-item">
                        Fitur
                    </div>

                    <div className="fcrc-one-345-item">
                        Kontak
                    </div>

                </div>

                <img
                    src={Laptop}
                    className="laptop-content-345"
                />

            </div>

        </div>
    )

}

export default FirstContent;