import React, { useEffect, useState } from 'react'

// STYLING
import './style.scss'
import './responsive.scss'
import './mobile.scss'
import { Box, Grid, Typography, } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import SERVER from '../../../endPoint'
import Axios from 'axios'

export default function Index() {
    const isTabletOrMobile = useMediaQuery({ query: '(min-width: 768px, max-width: 991px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const [dateValue, setDate] = useState(new Date())
    const [data, setData] = useState([])
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''

        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay = (date) => {
        let hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }
    // PCR: '', positifRate: '', terkonfirmasi: '', kasusKonfirmasi: '', positifAktif: '', kasusAktif: '', sembuh: '', angkaKesembuhan: '', kasusSembuh: '', meninggal: '', angkaKematian: '', kasusMeninggal: ''

    useEffect(() => {
        Axios({
            method: 'GET',
            url: `${SERVER}/data-absolute/newest`,
            headers: {
                token: localStorage.getItem('token_access')
            }
        })
            .then(({ data }) => {
                setData(data)

            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (

        <div className="lpk-blue-content-78689" id="data-terkini">
            <div className="box-098-31-whti">
                <div className="lpk-987-31">
                    <span className="fnt-3131" >
                        Data Terkini Covid 19 di Kabupaten Banggai
                        </span>
                    <span className="fnt-31332">
                        Update terakhir: {data ? showDay(new Date(data.createdAt)) : '-'}, {data ? showDate(new Date(data.createdAt)) : '-'}, Jam {data ? showHour(new Date(data.createdAt)) : '-'}
                    </span>
                </div>
            </div>

            <div >
                <Box className={isMobile ? "box-newContent-mobile" : isTabletOrMobile?"box-newContent-hp":"box-newContent"}>
                    <Grid container style={{ width: "100%" }} spacing={isMobile ? 2: isTabletOrMobile?4:5} >

                        <Grid item xs={6} sm={3} >
                            <Box className={isMobile ?'box-color-mobile' : isTabletOrMobile?'box-color-hp':'box-color'} bgcolor="#F4E400" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ? 'boxText-mobile': isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="rgba(255, 255, 255, 0.38)">
                                    <span className={isMobile ? 'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'}>JUMLAH TES PCR</span>
                                    <span className={isMobile ? 'value-box-mobile': isTabletOrMobile?'value-box-hp': 'value-box'}>{data && data.PCR ? data.PCR : 0}</span>
                                </Box>
                                <Box className={isMobile ? 'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="rgba(255, 255, 255, 0.38)">
                                    <span className={isMobile ? 'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'}>POSITIF RATE</span>
                                    <span className={isMobile ? 'value-box-mobile': isTabletOrMobile?'value-box-hp': 'value-box'}>
                                        {((Number(data && data.konfirmasi ? data.konfirmasi : 0)/Number(data && data.PCR ? data.PCR : 0))*100).toFixed(0)}%

                                    </span>
                                </Box>
                            </Box>
                            <Box className={isMobile ? 'box-color-bottom-mobile': isTabletOrMobile?'box-color-bottom-hp':'box-color-bottom'} bgcolor="#F4E400" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ? 'box-text-bottom-mobile': isTabletOrMobile?'box-text-bottom-hp':'box-text-bottom'} bgcolor="rgba(255, 255, 255, 0.38)">
                                    <span className={isMobile ? 'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'}>TERKONFIRMASI <br/> SULTENG</span>
                                </Box>
                                <Box textAlign='center' alignItems='center' justifyContent="center" margin="auto 0" paddingTop="10px" paddingBottom="10px">
                                    <span className={isMobile ? 'text-bottom-mobile': isTabletOrMobile?'text-bottom-hp':'text-bottom'}>{data && data.konfirmasiSulteng ? data.konfirmasiSulteng : 0}</span>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Box className={isMobile ?'box-color-mobile' : isTabletOrMobile?'box-color-hp':'box-color'} bgcolor="#F22C39" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ? 'boxText-mobile': isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#FF414D">
                                    <span className={isMobile ? 'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>KASUS KONFIRMASI</span>
                                    <span className={isMobile ? 'value-box-mobile': isTabletOrMobile?'value-box-hp black': 'value-box'} style={{ color: '#FFFFFF' }}>{data && data.konfirmasi ? data.konfirmasi : 0}</span>
                                </Box>
                                <Box className={isMobile ? 'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#FF414D">
                                    <span className={isMobile ? 'text-title-box-mobile': isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>POSITIF AKTIF</span>
                                    <span className={isMobile ? 'value-box-mobile': isTabletOrMobile?'value-box-hp': 'value-box'} style={{ color: '#FFFFFF' }}>
                                        {(Number(data && data.konfirmasi ? data.konfirmasi : 0) - Number(data && data.sembuh ? data.sembuh : 0) - Number(data && data.meninggal ? data.meninggal : 0))}
                                    </span>
                                </Box>
                            </Box>
                            <Box className={isMobile ? 'box-color-bottom-mobile': isTabletOrMobile?'box-color-bottom-hp':'box-color-bottom'} bgcolor="#F22C39" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ? 'box-text-bottom-mobile': isTabletOrMobile?'box-text-bottom-hp':'box-text-bottom'} bgcolor="#FF414D">
                                    <span className={isMobile ? 'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>KASUS AKTIF <br/> SULTENG</span>
                                </Box>
                                <Box textAlign='center' alignItems='center' justifyContent="center" margin="auto 0" paddingTop="10px" paddingBottom="10px">
                                    <span className={isMobile ? 'text-bottom-mobile': isTabletOrMobile?'text-bottom-hp':'text-bottom'} style={{ color: '#FFFFFF' }}>{data && data.aktifSulteng ? data.aktifSulteng : 0}</span>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Box className={isMobile ?'box-color-mobile' : isTabletOrMobile?'box-color-hp':'box-color'} bgcolor="#159E3C" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ?'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#19AF44">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>SEMBUH</span>
                                    <span className={isMobile ?'value-box-mobile' : isTabletOrMobile?'value-box-hp black': 'value-box'} style={{ color: '#FFFFFF' }}>{data && data.sembuh ? data.sembuh : 0}</span>
                                </Box>
                                <Box className={isMobile ?'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#19AF44">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>ANGKA KESEMBUHAN</span>
                                    <span className={isMobile ?'value-box-mobile' : isTabletOrMobile?'value-box-hp': 'value-box'} style={{ color: '#FFFFFF' }}>
                                        {((Number(data && data.sembuh ? data.sembuh : 0)/Number(data && data.konfirmasi ? data.konfirmasi : 0))*100).toFixed(0)}%
                                    </span>
                                </Box>
                            </Box>
                            <Box className={isMobile ?'box-color-bottom-mobile' : isTabletOrMobile?'box-color-bottom-hp':'box-color-bottom'} bgcolor="#159E3C" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ?'box-text-bottom-mobile' : isTabletOrMobile?'box-text-bottom-hp':'box-text-bottom'} bgcolor="#19AF44">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>KASUS SEMBUH <br/> SULTENG</span>
                                </Box>
                                <Box textAlign='center' alignItems='center' justifyContent="center" margin="auto 0" paddingTop="10px" paddingBottom="10px">
                                    <span className={isMobile ?'text-bottom-mobile' : isTabletOrMobile?'text-bottom-hp':'text-bottom'} style={{ color: '#FFFFFF' }}>{data && data.sembuhSulteng ? data.sembuhSulteng : 0}</span>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Box className={isMobile ?'box-color-mobile' : isTabletOrMobile?'box-color-hp':'box-color'} bgcolor="#3251FF" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ?'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#526AF9">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>MENINGGAL</span>
                                    <span className={isMobile ?'value-box-mobile' : isTabletOrMobile?'value-box-hp black': 'value-box'} style={{ color: '#FFFFFF' }}>{data && data.meninggal ? data.meninggal : 0}</span>
                                </Box>
                                <Box className={isMobile ?'boxText-mobile' : isTabletOrMobile?'boxText-hp':'boxText'} bgcolor="#526AF9">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA', fontSize: isMobile ? '' : isTabletOrMobile ? '' : '' }}>CASE FATALITY RATE</span>
                                    <span className={isMobile ?'value-box-mobile' : isTabletOrMobile?'value-box-hp': 'value-box'} style={{ color: '#FFFFFF' }}>
                                        {((Number(data && data.meninggal ? data.meninggal : 0)/Number(data && data.konfirmasi ? data.konfirmasi : 0))*100).toFixed(0)}%
                                    </span>
                                </Box>
                            </Box>
                            <Box className={isMobile ?'box-color-bottom-mobile' : isTabletOrMobile?'box-color-bottom-hp':'box-color-bottom'} bgcolor="#3251FF" boxShadow="0px 6px 10px rgba(244, 228, 0, 0.2)" >
                                <Box className={isMobile ?'box-text-bottom-mobile' : isTabletOrMobile?'box-text-bottom-hp':'box-text-bottom'} bgcolor="#526AF9">
                                    <span className={isMobile ?'text-title-box-mobile' : isTabletOrMobile?'text-title-box-hp':'text-title-box'} style={{ color: '#EAEAEA' }}>KASUS MENINGGAL <br/> SULTENG</span>
                                </Box>
                                <Box textAlign='center' alignItems='center' justifyContent="center" margin="auto 0" paddingTop="10px" paddingBottom="10px" >
                                    <span className={isMobile ?'text-bottom-mobile' : isTabletOrMobile?'text-bottom-hp':'text-bottom'} style={{ color: '#FFFFFF' }}>{data && data.meninggalSulteng ? data.meninggalSulteng : 0}</span>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </div>

        </div>


    )
}
