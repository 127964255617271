// MODULE
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import SERVER from '../../../../endPoint'

// STYLING
import './style.scss'

function RightContent () {
    const history = useHistory()
    const [dataPersonal, setDataPersonal] = useState(null)

    useEffect(()=>{
        if (localStorage.getItem('token_access')) {
            fetchDataUser()
        }
    },[])

    let fetchDataUser = () => {
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            setDataPersonal(data)
            console.log(data , ' <<< VALUE DATA DI THEN NAVBAR')
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    }

    return (
        <div className="header-867-right-content">
            
            <div onClick={()=> dataPersonal ? dataPersonal.role === 'master' && history.push('/beranda') : history.push('/landing-page-kabupaten') } className="hrc-867-per-content-active">
                Kembali Ke Beranda
            </div>

            {/* <div className="hrc-867-per-content">
                Diagnosa Mandiri
            </div>

            <div className="hrc-867-per-content">
                Berita
            </div>

            <div className="hrc-867-button">
                Masuk
            </div> */}

        </div>
    )

}

export default RightContent