import React,{ useState, useEffect } from 'react'
import './style.scss'
import axios from 'axios'
import SERVER from '../../../endPoint'
import { useHistory,  } from 'react-router-dom'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from '@material-ui/core/Drawer';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker'
import CustomTable from '../FormTable'

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    filterBtn:{
        width: '92px',
        height: '35px',
        fontFamily: 'SFProText',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#b9b9b9',
        backgroundColor: 'white',
    },
    loginBtn:{
        width: '68px',
        height: '25px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
        marginTop: '-10px'
    },
    headerTable1:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.3%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable4:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.5%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable2:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '7%',
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerTable3:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '7%',
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerTable:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '3%',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableCell1:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
        paddingLeft: 30
    },
    tableCell:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
    },
    tableHead:{
        backgroundColor: '#ffffff'
    },
    tableContainer:{
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
    },
    searchIcon:{
        color : "#888888",
        marginRight : "5%",
        paddingLeft: '5%'
    },
    insideRow:{
        borderBottom: 'none'
    },
    filterIcon:{
        marginLeft : "auto",
        marginRight: 5
    },
    controlLabel:{
        fontFamily: 'OpenSans',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#999999',
        marginBottom : 9,
        marginLeft: 8,
    },
    formControl:{
        marginTop: 9
    },
    radioGroup:{
        borderBottom: '1px solid #cccccc'
    },
    btnSubmit:{
        width: '220px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: '#0185ff',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        textTransform: "none",
        '&:hover': {
            backgroundColor: '#0185ff',
            color: '#FFF'
        }
    },
    btnLink:{
        width: '91px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: '#0185ff',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        color: '#ffffff',
    },
    btnDetail:{
        width: '91px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '14px',
        border: 'solid 1px #e8e8e8',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        color: '#0185ff',
    },
}));

const RadioCustom = withStyles({
    root: {
        marginLeft: 11,
        width: 13,
        height: 13,
        color: '#999999',
        '&$checked': {
            color: '#0185ff',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function Beranda(props){
    const history = useHistory()
    const classes = useStyles();
    const [searchDate, setDateSearch] = useState(null)
    const [value, setValue] = useState(null);
    const [click, setClick] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [dataPatient, setDataPatient] = useState([])
    const [desaList, setDesaList] = useState([])
    const [namaKecamatan, setNamaKecamatan] = useState([])
    const [dateNew, setDate] = useState(new Date())

    let {
        matchId
    } = props

    const [drawerState, setDrawerState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!click){
            setValue(null);
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setClick(true)
        setDrawerState({ ...drawerState, ['right']: false });
    }

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    }

    const handleRadioReset = () => {
        setValue(null);
        setClick(false)
        setDrawerState({ ...drawerState, ['right']: false });
    }

    
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/update-date`,
        })
        .then(({data})=>{
            setDate(data.date)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/kecamatan/${matchId}`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setDesaList(data.desa)
            setNamaKecamatan(data.nama)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    return (
        <div className="kecamata1-content-869">
            <div className="view-individu-text-top kecamatan-font-style" style={{marginBottom : "0"}} >
                Kecamatan { namaKecamatan }
            </div>

            <div className="view-individu-text-top-0980 kec-datal-7679-2"  >
                D Data di bawah ini diperbaharui per { showDay(dateNew) }, { showDate(dateNew) }, Jam : { showHour(dateNew) }
            </div>

            {/* <div className="search-guest-content-first-3" style={{ marginTop: 18 }} >
                <DatePicker 
                    className="date-search-picker" 
                    selected={ searchDate } 
                    dateFormat="d MMMM yyyy" 
                    placeholderText="Cari Tanggal"
                    onChange={ date => setDateSearch(date) } 
                />
                <div className="container-for-search-icon-first-2">
                    <EventIcon className={classes.searchIcon}/>
                </div>
            </div> */}

            {/* <div className="view-individu-filter-container" >
                <div className="search-guest-content-first-1" >
                    <input className="input-search-first-guest-1" placeholder="Cari Nama Kecamatan"/>
                    <div className="container-for-search-icon-first-1">
                        <SearchIcon className={classes.searchIcon}/>
                    </div>
                </div>

            </div> */}

            <div className="table-alert-list">
                {/* <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead} >
                            <TableRow className={classes.tableHead} >
                                <TableCell className={classes.headerTable1} >
                                    <div className="beranda-row-nomor">NO</div>
                                </TableCell>
                                <TableCell className={classes.headerTable} >
                                    <div className="beranda-row-nomor">Nama Kecamatan</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">Konfirmasi Positif</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">SEMBUH</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">Meninggal Dunia</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable} >
                                    <div className="beranda-row-nomor">Pelaku Perjalanan</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable4} >
                                    <div className="beranda-row-nomor">OTG</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable4} >
                                    <div className="beranda-row-nomor">OTD</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable4} >
                                    <div className="beranda-row-nomor">PDP</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable4} >
                                    <div className="beranda-row-nomor">AKSI</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        dataDesa.length > 0 &&
                            dataPatient
                                .sort((a,b) => (a.nama < b.nama) ? 1 : ((b.nama < a.nama) ? -1 : 0))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((patient, index) => (
                                <TableRow key={index} className={classes.insideRow}>
                                    <TableCell component="th" scope="row" align="left" className={classes.tableCell1} >
                                        { index+=1 }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { patient.namadiKtp }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { patient.status }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { patient.kelurahan }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { patient.kecamatan }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        { patient.noHp }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        <Button className={classes.btnDetail} onClick={()=>history.push('/data-individu-list/'+patient._id)} >
                                            Detail
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination
                                    labelRowsPerPage='Tampilan per halaman:'
                                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                                    colSpan={9}
                                    count={dataPatient.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}` }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{textAlign : "right"}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> */}

                <CustomTable
                    showDate={ showDate }
                    desaList={ desaList }
                />
            </div>

            {/* <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
                <div className="view-individu-drawer-container">
                    <div className="view-individu-drawer-container-top">
                        <span className="view-individu-drawer-text-top">
                            FILTER
                        </span>
                        <span className="view-individu-drawer-text-top-reset" onClick={()=>{handleRadioReset()}}>
                            Hapus Filter
                        </span>
                    </div>
                    <div className="view-individu-drawer-container-middle">
                        <span className="view-individu-drawer-text-middle">
                            Pilih Status
                        </span>
                        <form onSubmit={handleSubmit}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                                    <FormControlLabel className={classes.controlLabel} value="pelakuPerjalanan" control={<RadioCustom size="small"/>} label="Pelaku Perjalanan" />
                                    <FormControlLabel className={classes.controlLabel} value="OTG" control={<RadioCustom size="small"/>} label="Orang Tanpa Gejala (OTG)" />
                                    <FormControlLabel className={classes.controlLabel} value="ODP" control={<RadioCustom size="small"/>} label="Orang Dalam Pemantauan(ODP)" />
                                    <FormControlLabel className={classes.controlLabel} value="PDP" control={<RadioCustom size="small"/>} label="Pasien Dalam Pengawasan (PDP)" />
                                    <FormControlLabel className={classes.controlLabel} value="Konfirmasi Positif" control={<RadioCustom size="small"/>} label="Konfirmasi Positif" />
                                    <FormControlLabel className={classes.controlLabel} value="Meninggal Dunia" control={<RadioCustom size="small"/>} label="Meninggal Dunia" />
                                    <FormControlLabel className={classes.controlLabel} value="Pasien Sembuh" control={<RadioCustom size="small"/>} label="Pasien Sembuh" />
                                </RadioGroup>
                            </FormControl>
                        </form>
                    </div>
                    <div className="radio-button-submit-container">
                        <Button onClick={(e)=>[handleSubmit(e)]} className={classes.btnSubmit}>
                            Terapkan
                        </Button>
                    </div>
                </div>
            </Drawer> */}
        </div>
    )
}