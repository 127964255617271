// MODULE
import React , { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert2'

import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

// ENDPOINT
import SERVER from '../../endPoint/index'

// KEY
import dataKey from './key'

// COMPONENT
import ContentInput from './ContentInput'

// GLOBAL ACTION
import { SET_DATA_SETTING_ACTIONS } from '../../store/actions/settings'

// STYLING
import './style.scss'
import './input.scss'
import './edit.scss'

const BootstrapInput = withStyles((theme) => ({
    root: {
        width: 218,
        height: 38,
        marginLeft: theme.spacing(4),
        'label + &': {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(4),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#f2f3f8',
        border: '0.5px solid #757575',
        fontSize: 14,
        color: "#666666",
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Open Sans', "sans-serif",].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    native:{
        '& .MuiNativeSelect-selectMenu':{
            height: 20,
            overflow: 'auto'
        }
    },
    margin: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row'
    },
}));

function GeneralSetting () {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [kecamatanValue, setKecamatanValue] = useState(null)
    const [kecamatanNama, setKecamatanNama] = useState(null)
    const [kecamatanSelect, setKecamatanSelect] = useState(null)
    const [kecamatanList, setKecamatanList] = useState([])
    const [refreshKecamatan, setRefreshKecamatan] = useState(false)
    const [tambahKecamatan, setTambahKecamatan] = useState(false)
    const [editKecamatan, setEditKecamatan] = useState(false)
    const [deleteKecamatan, setDeleteKecamatan] = useState(false)
    
    const [desaValue, setDesaValue] = useState(null)
    const [desaSelect, setDesaSelect] = useState(null)
    const [desaList, setDesaList] = useState([])
    const [tambahDesa, setTambahDesa] = useState(false)
    const [editDesa, setEditDesa] = useState(false)
    const [deleteDesa, setDeleteDesa] = useState(false)
    const [refreshDesa, setRefreshDesa] = useState(false)

    const history = useHistory()

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    useEffect(()=>{
        let flag = false
        kecamatanList.map(kec=>{
            if (kec._id === kecamatanSelect) {
                setKecamatanValue(kec.nama)
                setKecamatanNama(kec.nama)
                flag = true
                return
            }
        })
        if (!flag) {
            setKecamatanValue(null)
            setKecamatanNama(null)
        }
    },[kecamatanSelect])

    useEffect(()=>{
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            if (data.role === 'patient') {
                history.replace('/landing-page-kabupaten')
            } else if (data.role === 'admin-berita') {
                history.replace('/input-berita')
            } else if (data.role === 'petugas') {
                history.replace('/data-individu')
            }
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    },[])

    useEffect(()=>{
        let flag = false
        desaList.map(desa=>{
            if (desa._id === desaSelect) {
                setDesaValue(desa.nama)
                flag = true
                return
            }
        })
        if (!flag) {
            setDesaValue(null)
        }
    },[desaSelect])

    useEffect(()=>{
        swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/general/setting`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            console.log(data , ' <<<< VALUE DATA >>>>>')
            dataKey.forEach((el,index)=>{
                // if (data[el])
                // console.log(data[el])
                if (data[el]) {
                    // console.log('MASUK , =++++=')
                    if ( typeof data[el] === 'string') {

                        dispatch(SET_DATA_SETTING_ACTIONS(
                            {
                                key : el,
                                value : data[el]
                            }
                        ))

                    }else if (data[el].length > 0 ) {
                        dispatch(SET_DATA_SETTING_ACTIONS(
                            {
                                key : el,
                                value : data[el]
                            }
                        ))
                    }
                }
                
            })
            swal.close()
            console.log(data , ' << ISI DATA >>')
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' << ERROR')
        })
    },[])
    
    useEffect(()=>{
        swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/kecamatan`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setKecamatanList(data)
            setRefreshKecamatan(false)
            swal.close()
        })
        .catch(err=>{
            swal.close()
            console.log(err)
        })
    },[refreshKecamatan])

    useEffect(()=>{
        if (kecamatanSelect) {
            swal.showLoading()
            axios({
                method : "GET",
                url : `${SERVER}/kecamatan/${kecamatanSelect}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                swal.close()
                setDesaList(data.desa)
            })
            .catch(err=>{
                swal.close()
                console.log(err)
            })
        }
    },[kecamatanSelect])

    useEffect(()=>{
        if (refreshDesa) {
            swal.showLoading()
            axios({
                method : "GET",
                url : `${SERVER}/kecamatan/${kecamatanSelect}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                setDesaList(data.desa)
                setRefreshDesa(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err)
            })
        }
    },[refreshDesa])

    const handleEditKecamatan = (e) => {
        e.preventDefault()
        if (!kecamatanValue) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "PATCH",
                url : `${SERVER}/kecamatan/${kecamatanSelect}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data:{
                    nama : kecamatanValue
                }
            })
            .then(({data})=>{
                setKecamatanValue(null)
                setRefreshKecamatan(true)
                setEditKecamatan(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                if (JSON.stringify(err.response.data).includes('Error, expected nama to be unique')) {
                    swal.fire({
                        title: 'Gagal',
                        text: 'Kecamatan Sudah Terdaftar',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                }
                console.log(err.response.data)
            })
        }
    }

    const handleEditDesa = (e) => {
        e.preventDefault()
        if (!desaValue) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "PATCH",
                url : `${SERVER}/desa/${desaSelect}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data:{
                    nama : desaValue
                }
            })
            .then(({data})=>{
                setDesaValue(null)
                setRefreshDesa(true)
                setEditDesa(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err.response.data)
            })
        }
    }

    const CreateKecamatan = (e) => {
        e.preventDefault()
        if (!kecamatanValue) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "POST",
                url : `${SERVER}/kecamatan`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data:{
                    nama : kecamatanValue
                }
            })
            .then(({data})=>{
                setKecamatanValue(null)
                setRefreshKecamatan(true)
                setTambahKecamatan(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                if (JSON.stringify(err.response.data).includes('Error, expected nama to be unique')) {
                    swal.fire({
                        title: 'Gagal',
                        text: 'Kecamatan Sudah Terdaftar',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                }
                console.log(err.response.data)
            })
        }
    }

    const CreateDesa = (e) => {
        e.preventDefault()
        if (!desaValue) {
            swal.fire({
                title: 'Gagal',
                text: 'Input Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "POST",
                url : `${SERVER}/desa/${kecamatanSelect}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data:{
                    nama : desaValue
                }
            })
            .then(({data})=>{
                setRefreshDesa(true)
                setDesaValue(null)
                setTambahDesa(false)
                swal.close()
            })
            .catch(err=>{
                swal.close()
                console.log(err.response.data)
            })
        }
    }

    const handleChangeKecamatan = (event) => {
        setKecamatanSelect(event.target.value);
    };

    const handleChangeDesa = (event) => {
        setDesaSelect(event.target.value);
    };

    const handleDisable = () => {
        if (editKecamatan || tambahKecamatan) {
            return true
        } else {
            return false
        }
    }

    const handleDisableDesa = () => {
        if (editDesa || tambahDesa) {
            return true
        } else {
            return false
        }
    }

    const handleDeleteDesa = () => {
        swal.showLoading()
        axios({
            method : "DELETE",
            url : `${SERVER}/desa/${desaSelect}`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setDesaSelect(null)
            setDesaValue(null)
            setRefreshDesa(true)
            swal.close()
        })
        .catch(err=>{
            swal.close()
            console.log(err)
        })
    }

    const handleDeleteKecamatan = () =>{
        swal.showLoading()
        axios({
            method : "DELETE",
            url : `${SERVER}/kecamatan/${kecamatanSelect}`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setKecamatanSelect(null)
            setKecamatanNama(null)
            setKecamatanValue(null)
            setRefreshKecamatan(true)
            setKecamatanList([])
            swal.close()
        })
        .catch(err=>{
            swal.close()
            console.log(err)
        })
    }

    return (
        <div className="gs-124-container">

            <div className="gs-124-title">
                Pengaturan Umum
            </div>

            <ContentInput
                title={"Propinsi"}
                placeHolder={"Tulis Propinsi"}
                code={"propinsi"}
                type={1}
                group={1}
            />

            <ContentInput
                title={"Kabupaten"}
                placeHolder={"Tulis Kabupaten"}
                code={"kabupaten"}
                type={1}
                group={1}
            />

            <form 
                className="gs-input-container-124 gs-update2" 
                style={{ marginBottom : 2 ? "24px" : "12px" }} // MEMBEDAKAN MARGIN BOTTOM ANTARA KELOMPOK 1 DAN 2
            >
                
                <div className="gs-input-title-124">
                    Kecamatan
                </div>
    
                {
                    kecamatanList &&
                    kecamatanList.length === 0 ?
                    <>
                        <input 
                            onChange={e=>setKecamatanValue(e.target.value)} 
                            type="text"
                            placeholder="Tulis Kecamatan"
                            className="gs-input-form-124" 
                        />
                        <div 
                            className="gs-input-button-124" 
                            onClick={e=> CreateKecamatan(e)}
                        >
                            Simpan
                        </div>
                    </>
                :
                    <>
                        <FormControl className={classes.margin}>
                            {/* <InputLabel style={{ marginLeft: 32 }} htmlFor="demo-customized-select-native">Pilih</InputLabel> */}
                            <NativeSelect
                                disabled={handleDisable()}
                                className="custom-selectfield"
                                id="demo-customized-select-native"
                                value={kecamatanSelect}
                                onChange={handleChangeKecamatan}
                                input={<BootstrapInput />}
                            >
                                <option value="">Kecamatan Terdaftar</option>
                                {
                                    kecamatanList &&
                                    kecamatanList.map((kec, i) => (
                                        <option key={i} value={kec._id}>{kec.nama}</option>
                                    ))
                                }
                            </NativeSelect>
                            {
                                tambahKecamatan &&
                                <input 
                                    onChange={e=>setKecamatanValue(e.target.value)} 
                                    type="text"
                                    placeholder="Tulis Kecamatan"
                                    className="gs-input-form-124 gs-update" 
                                />
                            }
                            {
                                editKecamatan &&
                                <input 
                                    onChange={e=>setKecamatanValue(e.target.value)} 
                                    type="text"
                                    value={kecamatanValue}
                                    placeholder="Tulis Kecamatan"
                                    className="gs-input-form-124 gs-update" 
                                />
                            }
                        </FormControl>
                        {
                            tambahKecamatan &&
                            <>
                                <div onClick={()=>setTambahKecamatan(false)} className="gs-plus-edit-124 smpn-update" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{CreateKecamatan(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Simpan
                                </div>
                            </>
                        }
                        {
                            kecamatanSelect && !tambahKecamatan && !deleteKecamatan &&
                            !editKecamatan ?
                            <>
                                <div className="gs-circle-edit-content-124 tmbh-kcmntn-btn" onClick={()=>setEditKecamatan(true)}>
                                    <CreateIcon style={{color : "#c4c4c4"}}/>
                                </div>
                                <div className="gs-circle-delete-content-124" onClick={()=>setDeleteKecamatan(true)} >
                                    <DeleteIcon style={{color : "#c4c4c4"}}/>
                                </div>
                                <div onClick={()=>setTambahKecamatan(true)} className="gs-plus-edit-124">
                                    Tambah
                                </div>
                            </>
                            :
                            kecamatanSelect && !tambahKecamatan &&
                            !deleteKecamatan ?
                            <>
                                <div onClick={()=>setEditKecamatan(false)} className="gs-plus-edit-124 smpn-update" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{handleEditKecamatan(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Simpan
                                </div>
                            </>
                            :
                            !tambahKecamatan && !editKecamatan &&
                            kecamatanSelect ?
                            <>
                                <div onClick={()=>setDeleteKecamatan(false)} className="gs-plus-edit-124 tmbh-kcmntn-btn" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{handleDeleteKecamatan(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Hapus
                                </div>
                            </>
                            :
                            !tambahKecamatan && !editKecamatan && !deleteKecamatan &&
                            <div onClick={()=>setTambahKecamatan(true)} className="gs-plus-edit-124 tmbh-kcmntn-btn">
                                Tambah
                            </div>
                        }
                    </>
                }       
            </form>

            <form 
                className="gs-input-container-124 gs-update2" 
                style={{ marginBottom : 2 ? "24px" : "12px" }} // MEMBEDAKAN MARGIN BOTTOM ANTARA KELOMPOK 1 DAN 2
            >
                
                <div className="gs-input-title-124">
                    Desa/Kelurahan { kecamatanNama }
                </div>
    
                {
                    kecamatanSelect ?
                    desaList &&
                    desaList.length === 0 ?
                    <>
                        <input 
                            onChange={e=>setDesaValue(e.target.value)} 
                            type="text"
                            placeholder="Tulis Desa/Kelurahan"
                            className="gs-input-form-124" 
                        />
                        <div 
                            className="gs-input-button-124" 
                            onClick={e=> CreateDesa(e)}
                        >
                            Simpan
                        </div>
                    </>
                :
                    <>
                        <FormControl className={classes.margin}>
                            {/* <InputLabel style={{ marginLeft: 32 }} htmlFor="demo-customized-select-native">Pilih</InputLabel> */}
                            <NativeSelect
                                disabled={handleDisableDesa()}
                                className="custom-selectfield custom-selectfield-angga"
                                id="demo-customized-select-native"
                                value={desaSelect}
                                onChange={handleChangeDesa}
                                input={<BootstrapInput />}
                            >
                                <option value="">Desa/Kelurahan Terdaftar</option>
                                {
                                    desaList &&
                                    desaList.map((desa, i) => (
                                        <option key={i} value={desa._id}>{desa.nama}</option>
                                    ))
                                }
                            </NativeSelect>
                            {
                                tambahDesa &&
                                <input 
                                    onChange={e=>setDesaValue(e.target.value)} 
                                    type="text"
                                    placeholder="Tulis Desa/Kelurahan"
                                    className="gs-input-form-124 gs-update" 
                                />
                            }
                            {
                                editDesa &&
                                <input 
                                    onChange={e=>setDesaValue(e.target.value)} 
                                    type="text"
                                    value={desaValue}
                                    placeholder="Tulis Desa/Kelurahan"
                                    className="gs-input-form-124 gs-update" 
                                />
                            }
                        </FormControl>
                        {
                            tambahDesa &&
                            <>
                                <div onClick={()=>setTambahDesa(false)} className="gs-plus-edit-124 smpn-update" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{CreateDesa(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Simpan
                                </div>
                            </>
                        }
                        {
                            desaSelect && !tambahDesa && !deleteDesa &&
                            !editDesa ?
                            <>
                                <div className="gs-circle-edit-content-124 tmbh-kcmntn-btn" onClick={()=>setEditDesa(true)}>
                                    <CreateIcon style={{color : "#c4c4c4"}}/>
                                </div>
                                <div className="gs-circle-delete-content-124" onClick={()=>setDeleteDesa(true)}>
                                    <DeleteIcon style={{color : "#c4c4c4"}}/>
                                </div>
                                <div onClick={()=>setTambahDesa(true)} className="gs-plus-edit-124">
                                    Tambah
                                </div>
                            </>
                            :
                            desaSelect && !tambahDesa &&
                            !deleteDesa ?
                            <>
                                <div onClick={()=>setEditDesa(false)} className="gs-plus-edit-124 smpn-update" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{handleEditDesa(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Simpan
                                </div>
                            </>
                            :
                            !tambahDesa && !editDesa &&
                            desaSelect ?
                            <>
                                <div onClick={()=>setDeleteDesa(false)} className="gs-plus-edit-124 tmbh-kcmntn-btn" >
                                    Batal
                                </div>
                                <div onClick={(e)=>{handleDeleteDesa(e)}} className="gs-plus-input-save-form-125 smpn-update" >
                                    Hapus
                                </div>
                            </>
                            :
                            kecamatanSelect && !tambahDesa && !editDesa && !deleteDesa &&
                            <div onClick={()=>setTambahDesa(true)} className="gs-plus-edit-124 tmbh-kcmntn-btn">
                                Tambah
                            </div>
                        }
                    </>
                    :
                    <span className="pilih-kec">
                        Pilih Kecamatan
                    </span>
                }       
            </form>

            {/* <ContentInput
                title={"Nama Petugas"}
                placeHolder={"Tulis Nama Petugas"}
                code={"petugas"}
                type={2}
                group={2}
            />

            <ContentInput
                title={"Instansi"}
                placeHolder={"Tulis Nama Petugas"}
                code={"instansi"}
                type={2}
                group={2}
            />

            <ContentInput
                title={"Jabatan"}
                placeHolder={"Tulis Nama Jabatan"}
                code={"jabatan"}
                type={2}
                group={2}
            />

            <ContentInput
                title={"Unit Layanan"}
                placeHolder={"Tulis Unit Layanan Tersedia"}
                code={"layanan"}
                type={2}
                group={2}
            /> */}

            {/* <ContentInput
                title={"Jenis Sakit"}
                placeHolder={"Tulis Jenis Sakit"}
                code={"jenisSakit"}
                type={2}
                group={2}
            /> */}

        </div>
    )

}

export default GeneralSetting;