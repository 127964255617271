// MODULE
import React, { useState, useEffect } from 'react'
import { useHistory  } from 'react-router-dom'
import axios from 'axios'
import SERVER from '../../../endPoint'
import { useMediaQuery } from 'react-responsive'
// COMPONENT
import Content from './Content'
import MobileScreen from './MobileScreen'

// STYLING
import './style.scss'

function News () {

    const history = useHistory()
    const [newsList,setNewsList] = useState([])
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' })

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/berita`,
        })
        .then(({data})=>{
            setNewsList(data)
        })
        .catch(console.log)
    },[])

    if (!isTabletOrMobile) {
        return (
            
            <div className="news-cntr" id="berita">

                <span className="news-hdr">
                    Berita & Tips Hidup Sehat
                </span>

                {
                    newsList &&
                    newsList
                    .sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0))
                    .slice(0,4)
                    .map((news,i)=>(
                        <Content key={i} news={news} style={{marginTop : i === 0 && "5px", borderBottom : i === newsList.length-1 && "none"}}/>
                    ))
                }

            {/* <Content newsList={newsList} />
            <Content newsList={newsList} />
            <Content newsList={newsList} style={{borderBottom : "none"}}/> */}

                <div className="news-button-container">

                    <div className="news-button-all" onClick={e=>history.push('/landing-page-berita')}>
                        Lihat Semua
                    </div>
                </div>
                
            </div>
    
        )

    }else {
        return (
            <MobileScreen
                newsList={newsList}
            />
        )
    }


}

export default News