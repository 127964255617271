import React from 'react'

function SecondContent () {

    return (
        <div className="sc-345-two">
            
            <div className="sc-345-container-for-mobile">

                <div className="sc-345-two-content"  >

                    <div className="sc-345-two-circle">

                    </div>

                    <div className="sc-345-two-title">
                        Fitur yang Tersedia
                    </div>

                    <div className="sc-345-two-body">
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit, sed do eiusmod tempor incididunt  
                    </div>

                </div>

                <div className="sc-345-two-content">

                    <div className="sc-345-two-circle">

                    </div>

                    <div className="sc-345-two-title">
                        Fitur yang Tersedia
                    </div>

                    <div className="sc-345-two-body">
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit, sed do eiusmod tempor incididunt  
                    </div>

                </div>

            </div>

            <div className="sc-345-container-for-mobile" style={{marginTop : "32px"}}>

                <div className="sc-345-two-content"  >

                    <div className="sc-345-two-circle">

                    </div>

                    <div className="sc-345-two-title">
                        Fitur yang Tersedia
                    </div>

                    <div className="sc-345-two-body">
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit, sed do eiusmod tempor incididunt  
                    </div>

                </div>

                <div className="sc-345-two-content">

                    <div className="sc-345-two-circle">

                    </div>

                    <div className="sc-345-two-title">
                        Fitur yang Tersedia
                    </div>

                    <div className="sc-345-two-body">
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit, sed do eiusmod tempor incididunt  
                    </div>

                </div>

            </div>

        </div>
    )

}

export default SecondContent