// MODULE
import React , { useEffect , useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import io from "socket.io-client";

// COMPONENT
import FirstContent from './FirstContent'
import SecondContent from './SecondContent'
import ThirdContent from './ThirdContent'

// ENPOINT
import SERVER from '../../../../endPoint'

// STYLING
import './style.scss'
import './mobile.scss'
import './new.scss'
import './mobile-new.scss'

function RightContent () {

    const history = useHistory()

    const [dataCount, setDataCount] = useState([])
    const [dataKecamatan, setDataKecamatan] = useState([])
    const [dataDesa, setDataDesa] = useState([])
    const [dataRealtime, setDataRealtime] = useState([])
    const [dataHarian, setDataHarian] = useState([])
    const [flag, setFlag] = useState(true)
    const [dateNew, setDate] = useState(new Date())

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    // DATA LANDING
    useEffect(()=>{
        if (flag) {
            axios({
                method : "GET",
                url : `${SERVER}/landing-page`,
            })
            .then(({data})=>{
                setDataCount(data)
            })
            .catch(err=>{
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[flag])

    // KECAMATAN
    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/kecamatan`
        })
        .then(({data})=>{
            setDataKecamatan(data.length)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // DESA -REALTIME
    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/desa`
        })
        .then(({data})=>{
            setDataDesa(data.length)

            let dataBaru = {
                positif : {
                    perawatan : 0,
                    isolasi : 0,
                    sembuh : 0,
                    meninggal : 0
                },
                pdp : {
                    perawatan : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    negatif : 0,
                    nonCovid : 0,
                    meninggal : 0
                },
                odp : {
                    dipantau : 0,
                    naikPDP : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    meninggal : 0
                },
                otg: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                },
                odr: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                }
            }
            if (data) {
                data.map(desa=>{
                    // POSITIF
                    dataBaru.positif.perawatan += desa.positif.perawatan
                    dataBaru.positif.isolasi += desa.positif.isolasi
                    dataBaru.positif.sembuh += desa.positif.sembuh
                    dataBaru.positif.meninggal += desa.positif.meninggal

                    // PDP
                    dataBaru.pdp.perawatan += desa.pdp.perawatan
                    dataBaru.pdp.naikPositif += desa.pdp.naikPositif
                    dataBaru.pdp.sembuh += desa.pdp.sembuh
                    dataBaru.pdp.negatif += desa.pdp.negatif
                    dataBaru.pdp.nonCovid += desa.pdp.nonCovid
                    dataBaru.pdp.meninggal += desa.pdp.meninggal
                    
                    // ODP
                    dataBaru.odp.naikPositif += desa.odp.naikPositif
                    dataBaru.odp.naikPDP += desa.odp.naikPDP
                    dataBaru.odp.dipantau += desa.odp.dipantau
                    dataBaru.odp.sembuh += desa.odp.sembuh
                    dataBaru.odp.meninggal += desa.odp.meninggal
                    
                    // ODP
                    dataBaru.otg.naikODP += desa.otg.naikODP
                    dataBaru.otg.naikPDP += desa.otg.naikPDP
                    dataBaru.otg.naikPositif += desa.otg.naikPositif
                    dataBaru.otg.dipantau += desa.otg.dipantau
                    dataBaru.otg.selesai += desa.otg.selesai
                    
                    // ODR
                    dataBaru.odr.naikODP += desa.odr.naikODP
                    dataBaru.odr.naikPDP += desa.odr.naikPDP
                    dataBaru.odr.naikPositif += desa.odr.naikPositif
                    dataBaru.odr.dipantau += desa.odr.dipantau
                    dataBaru.odr.selesai += desa.odr.selesai
                })
            }
            console.log(dataBaru)
            setDataRealtime(dataBaru)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/harian/search`,
        })
        .then(({data})=>{
            console.log(data,'asasasasasas klklkl')
            setDataHarian(data)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/update-date`,
        })
        .then(({data})=>{
            setDate(data.date)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // useEffect(()=>{
    //     setInterval(()=>{
    //         let socket = io(`${SERVER}`)
    //         socket.on('fetch_beranda' , data=>{
    //             setFlag(true)
    //         })
    //     },6000)
    // })

    return (
        <div className="lpk-content-867-rc">

            <div className="vck-iiu-887" >

            </div>

            {/* <div className="lpk-content-867-rc-title-1">
                Data Terkini Covid 19 di Kabupaten Banggai
            </div> */}

            {/* <div className="lpk-content-867-rc-title-2">
                Data di bawah ini diperbaharui per {showDay(dateNew) }, { showDate(dateNew) }, Jam { showHour(dateNew) }
            </div>

            <FirstContent 
                dataCount={ dataCount }
                dataKecamatan={ dataKecamatan }
                dataDesa={ dataDesa }
                dataRealtime={ dataRealtime }
                dataHarian={ dataHarian }
            />

            <SecondContent
                dataCount={ dataCount }
                dataRealtime={ dataRealtime }
                dataHarian={ dataHarian }
            />

            <ThirdContent
                dataCount={ dataCount }
                dataRealtime={ dataRealtime }
                dataHarian={ dataHarian }
            /> */}
            
        </div>
    )

}

export default RightContent