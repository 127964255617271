import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SET_RESET_INDIVIDUAL_ACTION } from '../../../store/actions/individual'
import './style.scss'

function BoxContent () {

    const history = useHistory()
    const dispatch = useDispatch()
    const [id,setId] = useState(null)

    useEffect(()=>{
        const split = (history.location.pathname).split('/')
        if (split[2]) {
            setId(split[2])
        }
    },[history.location.pathname])

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    return (
        <div className="box-content-id-190-container">
            {
                history.location.pathname === '/data-individu' || history.location.pathname === '/data-individu-list' ?
                <>
                    <div
                        className={history.location.pathname === '/data-individu' ? "bc-190-item" : "bc-190-item-disable" }
                        onClick={()=> history.location.pathname !== '/data-individu' ? history.push('/data-individu') : '' }
                    >
                        Input Data Individu
                    </div>
                    
                    <div
                        className={history.location.pathname === '/data-individu-list' ? "bc-190-item-2" : "bc-190-item-2-disable" }
                        onClick={()=> [history.location.pathname !== '/data-individu-list' && history.push('/data-individu-list'), dispatch(SET_RESET_INDIVIDUAL_ACTION())] }
                    >
                        Lihat Semua Data Individu
                    </div>
                </>
                :
                <>
                    <div
                        className={history.location.pathname === '/data-individu-list/'+id ? "bc-190-item" : "bc-190-item-disable" }
                        onClick={()=> history.location.pathname !== '/data-individu-list/'+id ? history.push('/data-individu-list/'+id) : '' }
                    >
                        Detail Individu
                    </div>
                    
                    <div
                        onClick={()=> history.location.pathname !== '/riwayat-diagnosa/'+ id ? history.push('/riwayat-diagnosa/'+id) : '' }
                        // className={"bc-190-item-2-disable"}
                        style={{width : "208px" }}
                        className={history.location.pathname === '/riwayat-diagnosa/'+id ? "bc-190-item" : "bc-190-item-disable" }
                    >
                        Riwayat Diagnosis Mandiri
                    </div>
                </>
            }

        </div>
    )

}

export default BoxContent