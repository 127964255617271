import React from 'react'

// STYLING
import './style.scss'

// IMAGE
import Logo from '../../images/optimus3.svg'

function FooterContent (props) {

    return (
        <div className="footer-container-type-1" style={{...props.style}}>

            <div className="fct1-682-title1">
                Powered By
            </div>

            <img src={Logo} className="fct1-682-img"/>

            <div className="fct1-682-title2">
                Official website Pemerintah Kabupaten Banggai 2020
            </div>
            
        </div>
    )

}

export default FooterContent