// MODULE
import React from 'react'


// COMPONENT
import LeftContent from './LeftContent'
import RightContent from './RightContent'

// STYLING
import './style.scss'

function Content () {
    
    return (
        <div className="lpk-321-312">
            <div className="lpk-content-867">

                <LeftContent/>

                <RightContent/>

            </div>
            <div className="wave-312" ></div>
        </div>
    )

}

export default Content