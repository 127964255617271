import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import './styles.scss'
import { useHistory } from 'react-router-dom';

function LeftContent (props) {

    let {news} = props

    const history = useHistory()

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return (
        <div className="news-content-left-424">
            
            <span className="ncl-424-fc"
                style={{ cursor: "pointer", color: "#2196f3" }}
                onClick={()=>history.push('/detail-berita/'+news._id)}
            >
                {news.judulBerita}
            </span>

            <span className="ncl-424-sc">
                { showDay(news.updatedAt) }, {showDate(news.updatedAt)}/{showHour(news.updatedAt)}
            </span>

            <span className="ncl-424-tc">
                <Editor
                    editorState={EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML((news.isiBerita))))}
                    toolbarHidden={true}
                    readOnly={true}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName01-9090"
                    editorClassName="berita-input-60901"
                />
            </span>

        </div>
    )

}

export default LeftContent