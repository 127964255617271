// MODULE
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import SERVER from '../../endPoint'

// COMPONENT
import Header from './Header'
import Content from './Content'
import CovidData from './CovidData'
import News from './Berita'
import NavbarMobile from '../../components/NavbarMobile'
import Footer from './Footer'

// STYLING
import './style.scss'
import BlueContent from './BlueContent'
import ContentCovid from './Covid19Content'

function LandigPageKabupate () {
    const [location, setLocation] = useState(false)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    // useEffect(()=>{
    //     console.clear()
    // })

    useEffect(() => {
        setTimeout(() => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    if (localStorage.getItem('token_access')) {
                        axios({
                            method: 'patch',
                            url: `${SERVER}/patient/location`,
                            headers: {
                                token: localStorage.getItem('token_access')
                            },
                            data: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        })
                        .then(({ data }) => {
                            location ? setLocation(false) : setLocation(true)
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                    }
                });
            } else {
                console.log("Not Available");
            }
        }, 3000);
    }, [location, localStorage.getItem('token_access')])

    return (
        <div className="lp-kabupaten-867">

            {
                isTabletOrMobile ? 
                    <NavbarMobile 
                        //  style={{boxShadow: "0px 1px 2px rgba(117, 117, 117, 0.1)"}} 
                        marginBottomShow={50}
                    /> : 
                    <Header/>
            }
            
            <Content/>

            <CovidData/>
            
            <ContentCovid/>

            <News/>

            {/* <BlueContent/> */}

            <Footer/>

        </div>
    )

}

export default LandigPageKabupate