// MODULE
import React,{ useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import './styles.scss'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios'
import { Button } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import DatePicker from 'react-datepicker'
import swal from 'sweetalert2'
// COMPONENT
import FirstContent from './FirstContent'
import SecondContent from './SecondContent'
import FormTable from './FormTable'

import SERVER from '../../endPoint'

// STYLING
import './style.scss'
import './new.scss'
import './responsive.scss'

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    loginBtn:{
        width: '68px',
        height: '25px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
        marginTop: '-10px'
    },
    aksiBtn:{
        width: '80px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
    },
    tableContainer:{
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
    },
    tableHead:{
        backgroundColor: '#ffffff'
    },
    headerTable1:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.3%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '3%',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableRow:{
        borderBottom: 'none'
    },
    tableCell1:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
        paddingLeft: 30
    },
    tableCell:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
    },
    tableCell2:{
        paddingTop: 10,
        paddingBottom: 10
    },
    searchIcon: {
        color: "#999999"
    },
    infoBtn:{
        width: '125px',
        height: '34px',
        borderRadius: '4px',
        fontFamily: 'Poppins',
        fontSize: '12px',
        color: '#FFFFFF',
        lineHeight: '21px',
        marginLeft: 34,
        backgroundColor: "#0185FF",
        marginTop: -3
    }
}));

export default function Beranda(){
    const classes = useStyles();
    const history = useHistory()
    const [today] = useState(new Date())
    const [kecamatanList,setKecamatanList] = useState([])
    const [desaList,setDesaList] = useState([])
    const [harianList,setHarianList] = useState([])
    const [kecamatanToday, setKecamatanToday] = useState([])
    const [searchDate, setDateSearch] = useState(null)
    const [refreshDesaUpdate, setRefreshDesaUpdate] = useState(true)

    // BERANDA BOX
    const [dataCount,setDataCount] = useState({})
    const [dataHariIni,setDataHariIni] = useState({})
    const [dataTotal, setDataTotal] = useState({})

    const dataUser = useSelector(state=>state.UserReducer.dataUser)
    
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    useEffect(()=>{
        if (history.location.pathname == '/beranda') {
            axios({
                method : "GET",
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/user/setting`
            })
            .then(({data})=>{
                if (data.role === 'patient') {
                    history.replace('/landing-page-kabupaten')
                } else if (data.role === 'admin-berita') {
                    history.replace('/input-berita')
                } else if (data.role === 'petugas') {
                    history.replace('/data-individu')
                }
            })
            .catch(err=>{
                console.log(err , ' << ERROR')
            })
        }
    },[history.location.pathname])

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const TotalPerhari = (hari) => {
        console.log(hari)
    }

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    useEffect(()=>{
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() )
    },[])

    // KECAMATAN
    useEffect(()=>{
        // swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/kecamatan`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            setKecamatanList(data)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // DESA
    useEffect(()=>{
        // swal.showLoading()
        if(refreshDesaUpdate){
            setRefreshDesaUpdate(false)
            axios({
                method : "GET",
                url : `${SERVER}/desa`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                // swal.close()
                setDesaList(data)
                let dataBaru = {
                    positif : {
                        perawatan : 0,
                        isolasi : 0,
                        sembuh : 0,
                        meninggal : 0
                    },
                    pdp : {
                        perawatan : 0,
                        naikPositif: 0,
                        sembuh : 0,
                        negatif : 0,
                        nonCovid : 0,
                        meninggal : 0
                    },
                    odp : {
                        dipantau : 0,
                        naikPDP : 0,
                        naikPositif: 0,
                        sembuh : 0,
                        meninggal : 0
                    },
                    otg: {
                        naikODP: 0,
                        naikPDP: 0,
                        naikPositif: 0,
                        dipantau : 0,
                        selesai : 0
                    },
                    odr: {
                        naikODP: 0,
                        naikPDP: 0,
                        naikPositif: 0,
                        dipantau : 0,
                        selesai : 0
                    }
                }
                if (data) {
                    data.map(desa=>{
                        // POSITIF
                        dataBaru.positif.perawatan += desa.positif.perawatan
                        dataBaru.positif.isolasi += desa.positif.isolasi
                        dataBaru.positif.sembuh += desa.positif.sembuh
                        dataBaru.positif.meninggal += desa.positif.meninggal
    
                        // PDP
                        dataBaru.pdp.perawatan += desa.pdp.perawatan
                        dataBaru.pdp.naikPositif += desa.pdp.naikPositif
                        dataBaru.pdp.sembuh += desa.pdp.sembuh
                        dataBaru.pdp.negatif += desa.pdp.negatif
                        dataBaru.pdp.nonCovid += desa.pdp.nonCovid
                        dataBaru.pdp.meninggal += desa.pdp.meninggal
                        
                        // ODP
                        dataBaru.odp.naikPositif += desa.odp.naikPositif
                        dataBaru.odp.naikPDP += desa.odp.naikPDP
                        dataBaru.odp.dipantau += desa.odp.dipantau
                        dataBaru.odp.sembuh += desa.odp.sembuh
                        dataBaru.odp.meninggal += desa.odp.meninggal
                        
                        // ODP
                        dataBaru.otg.naikODP += desa.otg.naikODP
                        dataBaru.otg.naikPDP += desa.otg.naikPDP
                        dataBaru.otg.naikPositif += desa.otg.naikPositif
                        dataBaru.otg.dipantau += desa.otg.dipantau
                        dataBaru.otg.selesai += desa.otg.selesai
                        
                        // ODR
                        dataBaru.odr.naikODP += desa.odr.naikODP
                        dataBaru.odr.naikPDP += desa.odr.naikPDP
                        dataBaru.odr.naikPositif += desa.odr.naikPositif
                        dataBaru.odr.dipantau += desa.odr.dipantau
                        dataBaru.odr.selesai += desa.odr.selesai
                    })
                }
                setDataCount(dataBaru)
            })
            .catch(err=>{
                swal.close()
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[refreshDesaUpdate])

    // kecamatan
    useEffect(()=>{
        axios({
            method : "POST",
            url : `${SERVER}/history/date`,
            data: { data : new Date() }
        })
        .then(({data})=>{
            let dataBaru = {
                positif : {
                    perawatan : 0,
                    isolasi : 0,
                    sembuh : 0,
                    meninggal : 0
                },
                pdp : {
                    perawatan : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    negatif : 0,
                    nonCovid : 0,
                    meninggal : 0
                },
                odp : {
                    dipantau : 0,
                    naikPDP : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    meninggal : 0
                },
                otg: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                },
                odr: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                }
            }
            data.map(kec=>{
                // POSITIF
                dataBaru.positif.perawatan += kec.data.positif.perawatan
                dataBaru.positif.isolasi += kec.data.positif.isolasi
                dataBaru.positif.sembuh += kec.data.positif.sembuh
                dataBaru.positif.meninggal += kec.data.positif.meninggal

                // PDP
                dataBaru.pdp.perawatan += kec.data.pdp.perawatan
                dataBaru.pdp.naikPositif += kec.data.pdp.naikPositif
                dataBaru.pdp.sembuh += kec.data.pdp.sembuh
                dataBaru.pdp.negatif += kec.data.pdp.negatif
                dataBaru.pdp.nonCovid += kec.data.pdp.nonCovid
                dataBaru.pdp.meninggal += kec.data.pdp.meninggal
                
                // ODP
                dataBaru.odp.naikPositif += kec.data.odp.naikPositif
                dataBaru.odp.naikPDP += kec.data.odp.naikPDP
                dataBaru.odp.dipantau += kec.data.odp.dipantau
                dataBaru.odp.sembuh += kec.data.odp.sembuh
                dataBaru.odp.meninggal += kec.data.odp.meninggal
                
                // ODP
                dataBaru.otg.naikODP += kec.data.otg.naikODP
                dataBaru.otg.naikPDP += kec.data.otg.naikPDP
                dataBaru.otg.naikPositif += kec.data.otg.naikPositif
                dataBaru.otg.dipantau += kec.data.otg.dipantau
                dataBaru.otg.selesai += kec.data.otg.selesai
                
                // ODR
                dataBaru.odr.naikODP += kec.data.odr.naikODP
                dataBaru.odr.naikPDP += kec.data.odr.naikPDP
                dataBaru.odr.naikPositif += kec.data.odr.naikPositif
                dataBaru.odr.dipantau += kec.data.odr.dipantau
                dataBaru.odr.selesai += kec.data.odr.selesai
            })
            console.log('dataBaru',dataBaru)
            setKecamatanToday(dataBaru)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // HARIAN ALL
    useEffect(()=>{
        // swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/harian`,
            headers : {
                token : localStorage.getItem('token_access')
            },
        })
        .then(({data})=>{
            swal.close()
            setHarianList(data)
            let dataBaru = {
                positif : {
                    perawatan : 0,
                    isolasi : 0,
                    sembuh : 0,
                    meninggal : 0
                },
                pdp : {
                    perawatan : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    negatif : 0,
                    nonCovid : 0,
                    meninggal : 0
                },
                odp : {
                    dipantau : 0,
                    naikPDP : 0,
                    naikPositif: 0,
                    sembuh : 0,
                    meninggal : 0
                },
                otg: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                },
                odr: {
                    naikODP: 0,
                    naikPDP: 0,
                    naikPositif: 0,
                    dipantau : 0,
                    selesai : 0
                }
            }
            if (data) {
                data.map(harians=>{
                    // POSITIF
                    dataBaru.positif.perawatan += harians.positif.perawatan
                    dataBaru.positif.isolasi += harians.positif.isolasi
                    dataBaru.positif.sembuh += harians.positif.sembuh
                    dataBaru.positif.meninggal += harians.positif.meninggal

                    // PDP
                    dataBaru.pdp.perawatan += harians.pdp.perawatan
                    dataBaru.pdp.naikPositif += harians.pdp.naikPositif
                    dataBaru.pdp.sembuh += harians.pdp.sembuh
                    dataBaru.pdp.negatif += harians.pdp.negatif
                    dataBaru.pdp.nonCovid += harians.pdp.nonCovid
                    dataBaru.pdp.meninggal += harians.pdp.meninggal
                    
                    // ODP
                    dataBaru.odp.naikPositif += harians.odp.naikPositif
                    dataBaru.odp.naikPDP += harians.odp.naikPDP
                    dataBaru.odp.dipantau += harians.odp.dipantau
                    dataBaru.odp.sembuh += harians.odp.sembuh
                    dataBaru.odp.meninggal += harians.odp.meninggal
                    
                    // ODP
                    dataBaru.otg.naikODP += harians.otg.naikODP
                    dataBaru.otg.naikPDP += harians.otg.naikPDP
                    dataBaru.otg.naikPositif += harians.otg.naikPositif
                    dataBaru.otg.dipantau += harians.otg.dipantau
                    dataBaru.otg.selesai += harians.otg.selesai
                    
                    // ODR
                    dataBaru.odr.naikODP += harians.odr.naikODP
                    dataBaru.odr.naikPDP += harians.odr.naikPDP
                    dataBaru.odr.naikPositif += harians.odr.naikPositif
                    dataBaru.odr.dipantau += harians.odr.dipantau
                    dataBaru.odr.selesai += harians.odr.selesai
                })
            }
            setDataTotal(dataBaru)
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    // HARIAN ONE
    useEffect(()=>{
        // swal.showLoading()
        axios({
            method : "GET",
            url : `${SERVER}/harian/search`,
            headers : {
                token : localStorage.getItem('token_access')
            }
        })
        .then(({data})=>{
            swal.close()
            console.log(data,'asasasasasa')
            setDataHariIni(data)
        })
        .catch(err=>{
            swal.close()
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    },[])

    const handleUpdateInfo = () => {
        let temp = dataCount
        let positif = dataCount.positif.perawatan + dataCount.positif.isolasi + dataCount.positif.sembuh + dataCount.positif.meninggal
        let pdp = dataCount.pdp.perawatan + dataCount.pdp.naikPositif + dataCount.pdp.sembuh + dataCount.pdp.negatif + dataCount.pdp.nonCovid + dataCount.pdp.meninggal
        let odp = dataCount.odp.dipantau + dataCount.odp.meninggal + dataCount.odp.naikPDP + dataCount.odp.naikPositif + dataCount.odp.sembuh
        let otg = dataCount.otg.dipantau + dataCount.otg.naikODP + dataCount.otg.naikPDP + dataCount.otg.naikPositif + dataCount.otg.selesai
        let odr = dataCount.odr.dipantau + dataCount.odr.naikODP + dataCount.odr.naikPDP + dataCount.odr.naikPositif + dataCount.odr.selesai
        temp.kecamatan = kecamatanList.length
        temp.desa = desaList.length
        temp.hariIni = { date : new Date(), positif, pdp, odp, otg, odr }
        axios({
            method : "PATCH",
            url : `${SERVER}/landing-page`,
            headers : {
                token : localStorage.getItem('token_access')
            },
            data : {
                data : temp
            }
        })
        .then(data=>{})
        .catch(err=>{
            console.log(err)
        })
    }

    return (
        <>
        <div className="beranda-content-597-container">
            
            <div className="bc-ctbainl-2131">
                <div className="bc-597-title">
                    Beranda, { showDay(today) } - { showDate(today) }
                </div>

                {/* <Button type="submit" size="small" style={{ backgroundColor: "#0185FF" }} className={classes.infoBtn}
                    onClick={()=>handleUpdateInfo()}
                >
                    Update Info
                </Button> */}
            </div>

            <FirstContent
                kecamatanList={ kecamatanList }
                desaList={ desaList }
                dataCount={ dataCount }
                dataHariIni={ dataHariIni }
                dataTotal={ dataTotal }
                kecamatanToday={kecamatanToday}
            />

            <SecondContent
                setRefreshDesaUpdate={setRefreshDesaUpdate}
                kecamatanList={ kecamatanList }
                desaList={ desaList }
                dataCount={ dataCount }
                dataHariIni={ dataHariIni }
                dataTotal={ dataTotal }
                kecamatanToday={kecamatanToday}
            />

        </div>
        <div className="table-inline-00897 table-inline-06788" >
            <span className="beranda-text-top" >
                Data Harian
            </span>
            <div className="search-guest-content-first-3" style={{ marginTop: 18 }} >
                <DatePicker 
                    className="date-search-picker" 
                    selected={ searchDate } 
                    dateFormat="d MMMM yyyy" 
                    placeholderText="Cari Tanggal"
                    onChange={ date => setDateSearch(date) } 
                />
                <div className="container-for-search-icon-first-2">
                    <EventIcon className={classes.searchIcon}/>
                </div>
            </div>
            <FormTable
                searchDate={ searchDate }
                showDate={showDate}
                harianList={ harianList }
                TotalPerhari={ TotalPerhari }
            />
        </div>
    </>
    )
}