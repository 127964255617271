import { SET_DATA_SETTING } from  '../constanta'

const INITIAL_STATE = { 
    propinsi : null , 
    kabupaten : null ,
    kecamatan : null,
    desa : null,
    // status : null,
    petugas : null,
    instansi : null,
    jabatan : null,
    layanan : null,
    jenisSakit : null
}

function settingState (state = INITIAL_STATE , action) {
    switch (action.type) {
        case SET_DATA_SETTING:
            return {  ...state , [action.payload.key] : action.payload.value }
        default:
            return state
    }
}

export default settingState