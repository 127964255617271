import React from 'react'
import { useMediaQuery } from 'react-responsive'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIcon from '@material-ui/icons/Phone';

// STYLING
import './style.scss'
import './mobile.scss'
import { useEffect, useState } from 'react'

import axios from 'axios'
import SERVER from '../../../endPoint'

function BlueContent () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const [dataIndonesia, setDataIndonesia] = useState(null)
    const [banggai] = useState(true)
    const [poso] = useState(false)

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    useEffect(()=>{
        axios({
            method : "GET",
            url : `https://covid19.mathdro.id/api/countries/indonesia`,
        })
        .then(({data})=>{
            setDataIndonesia(data)
        })
        .catch(err=>{
            console.log(err , ' <<< PENYEBAB ERROR >>>')
        })
    })

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <div className="lpk-blue-content-867">
            
            <div className="blue-container-867">

                <div 
                    style={
                            {
                                marginTop : isTabletOrMobile ? "21px" : null,
                                paddingBottom : isTabletOrMobile ? "9px" : null
                            }
                        } 
                    className="blue-per-content-867 border-bottom-867 border-left-867"
                >

                    <div className="blue-title1-867">
                        Kontak Darurat
                    </div>

                    <div className="blue-container-title-867" style={{ marginTop : "17px" }}>

                        {/* <div className="blue-title2-867" >
                            Nama Instansi
                        </div> */}
                        {
                            banggai && 
                            <div className="blue-body1-867 font-angga-cstm" >
                                Gugus Tugas Covid 19 Kab. Banggai 
                            </div>
                        }
                        {
                            poso && 
                            <div className="blue-body1-867 font-angga-cstm" >
                                -
                            </div>
                        }

                    </div>
                    <div className="blue-container-title-867">

                        {/* <div className="blue-title2-867" >
                            Nomor Telepon
                        </div> */}

                        <PhoneIcon style={{ color: 'white', marginTop: -5 }} />
                        {
                            banggai &&
                            <div className="blue-body1-867 font-angga-cstm" style={{ marginLeft: 7 }} >
                                0852-8169-1921
                            </div>
                        }
                        {
                            poso &&
                            <div className="blue-body1-867 font-angga-cstm" style={{ marginLeft: 7 }} >
                                -
                            </div>
                        }

                    </div>

                    <div className="blue-container-title-867">

                        {/* <div className="blue-title2-867" >
                            Kontak Person
                        </div> */}
                        <AccountBoxIcon style={{ marginTop: -5 , color: 'white' }} />
                        {
                            banggai &&
                            <div className="blue-body1-867" style={{ marginLeft: 7 }}>
                                Ibu Nurmasyita Datu Adam
                            </div>
                        }
                        {
                            poso &&
                            <div className="blue-body1-867" style={{ marginLeft: 7 }}>
                                -
                            </div>
                        }
                    </div>

                </div>

                <div className="blue-per-content-867 border-bottom-867 border-left-867">

                    <div className="blue-title1-867">
                        Rumah Sakit Rujukan
                    </div>

                    <div className="blue-container-title-867" style={{marginTop  : "17px"}}>

                        {/* <div className="blue-title2-867" >
                            Nama Rumah Sakit
                        </div> */}

                        <div className="blue-body1-867 font-angga-cstm">
                            RSUD Kab. Banggai Luwuk
                        </div>

                    </div>

                    <div className="blue-container-title-867">
                        {/* <div className="blue-title2-867" >
                            Sembuh
                        </div> */}

                        <div className="blue-body1-867"  >
                            Jl. Imam Bonjol No.14, Luwuk
                        </div>

                    </div>

                    <div className="blue-container-title-867">

                        {/* <div className="blue-title2-867" >
                            Nomor Telepon
                        </div> */}
                        <PhoneIcon style={{ color: 'white', marginTop: -5 }} />

                        <div className="blue-body1-867" style={{ marginLeft: 7 }}>
                            0852-8505-0806
                        </div>

                    </div>

                    <div className="blue-container-title-867">

                        {/* <div className="blue-title2-867" >
                            Kontak Person
                        </div> */}
                        <AccountBoxIcon style={{ marginTop: -5 , color: 'white' }} />

                        <div className="blue-body1-867" style={{ marginLeft: 7 }}>
                            Ibu Hartati Labongkeng
                        </div>
                    </div>


                </div>

                <div className="blue-per-content-867" style={{marginBottom : isTabletOrMobile ? "-10px" : 0}}>

                    <div className="container-blk-olk">
                        <div className="blue-title1-867">
                            Data Terbaru di Indonesia
                        </div>
                        <div className="blue-title1-890" >
                            Diperbarui Per { showDay(dataIndonesia ? dataIndonesia.lastUpdate ? dataIndonesia.lastUpdate : new Date() : new Date()) }, { showDate(dataIndonesia ? dataIndonesia.lastUpdate ? dataIndonesia.lastUpdate : new Date :new Date) }, Jam { showHour(dataIndonesia ? dataIndonesia.lastUpdate ? dataIndonesia.lastUpdate : new Date() : new Date()) }
                        </div>
                    </div>


                    <div className="blue-container-title-867" style={{ marginTop : "17px" , width : "60%" }}>
                        <div className="blue-title2-867" >
                            Positif
                        </div>

                        <div className="blue-body1-867">
                            { dataIndonesia ? dataIndonesia.confirmed ? dataIndonesia.confirmed.value ? numberWithCommas(dataIndonesia.confirmed.value) : 0 : 0 : 0 }
                        </div>

                    </div>

                    <div className="blue-container-title-867" style={{width : "60%"}}>
                        <div className="blue-title2-867" >
                            Sembuh
                        </div>

                        <div className="blue-body1-867">
                            { dataIndonesia ? dataIndonesia.recovered ? dataIndonesia.recovered.value ? numberWithCommas(dataIndonesia.recovered.value) : 0 : 0 : 0  }
                        </div>
                    </div>

                    <div className="blue-container-title-867" style={{width : "60%"}}>
                        <div className="blue-title2-867" >
                            Meninggal
                        </div>

                        <div className="blue-body1-867">
                            { dataIndonesia ? dataIndonesia.deaths ? dataIndonesia.deaths.value ? numberWithCommas(dataIndonesia.deaths.value) : 0 : 0 : 0 }
                        </div>

                    </div>


                </div>

            </div>
            
            {/* <div style={{width : "90%" , display : "flex" , flexDirection : "column" , justifyContent : "flex-start" ,marginTop : "-15px"}}>
                <div className="blue-last-message1-867">
                    Update :28 April 2020 Pukul 15.53 WITA 
                </div>

                <div className="blue-last-message1-867" style={{marginTop : "4px"}}>
                Sumber: Kementerian Kesehatan Republik Indonesia
                </div>

            </div> */}
    

        </div>
    )

}

export default BlueContent