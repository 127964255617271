// MODULE
import React , { useEffect , useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

// COMPONENT
import InputTypeOne from '../../InputComponents/InputTypeOne'
import InputTypeTwo from '../../InputComponents/InputTypeTwo'
import InputWithRadio from '../../InputComponents/InputWithRadio'
import InputWithDateSingle from '../../InputComponents/InputWithDateSingle'

// ENPOINT 
import SERVER from '../../../../endPoint/index.js'

import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

function LeftContent () {
    const history = useHistory()
    const dispatch = useDispatch()

    // LOCAL STATE
    const [dataPropinsi,setDataPropinsi] = useState(null)
    const [dataKabupaten,setDataKabupaten] = useState(null)
    const [status] = useState([
        "Konfirmasi Positif",
        "Pasien Dalam Pemantauan (PDP)",
        "Orang Dalam Pemantauan(ODP)",
        "Orang Tanpa Gejala (OTG)",
        "Pelaku Perjalanan (ODR)",
    ])
    const [subStatus, setSubStatus] = useState([])
    const [dataKecamatan, setDataKecamatan] = useState(null)
    const [dataKecamatanASLI, setDataKecamatanASLI] = useState(null)
    const [kecamatanId, setKecamatanId] = useState(null)
    const [dataDesa,setDataDesa] = useState(null)
    const [dataInstansi,setDataInstansi] = useState(null)

    // GLOBAL STATE
    const dataUser = useSelector(state=> state.UserReducer.dataUser)
    const dataGlobal = useSelector(state=>state.individualState)

    useEffect(()=>{
        if(dataGlobal.status === "Konfirmasi Positif"){
            setSubStatus([
                "Dalam Perawatan",
                "Di Ruang Isolasi",
                "Sembuh",
                "Meninggal"
            ])
        } else if (dataGlobal.status === "Pasien Dalam Pemantauan (PDP)"){
            setSubStatus([
                "Dalam Perawatan",
                "Sembuh",
                "Negatif",
                "Bukan Covid 19",
                "Meninggal"
            ])
        } else if (dataGlobal.status === "Orang Dalam Pemantauan(ODP)"){
            setSubStatus([
                "Sedang Dipantau",
                "Sembuh",
                "Meninggal"
            ])
        } else if (dataGlobal.status === "Orang Tanpa Gejala (OTG)"){
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
        } else if (dataGlobal.status === "Pelaku Perjalanan (ODR)"){
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
        }
    },[dataGlobal.status])

    useEffect(()=>{
        if (dataKecamatanASLI) {
            let ID
            dataKecamatanASLI.map(kec=>{
                if (kec.nama === dataGlobal.kecamatan) {
                    ID = kec._id
                }
            })
            axios({
                method : "GET",
                url : `${SERVER}/kecamatan/${ID}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                let temp = []
                data.desa.forEach(data=>{
                    dispatch(SET_DATA_INDIVIDUAL_ACTION({key : "kelurahan",value : data.nama}))
                })
                // setDataDesa(temp)
            })
            .catch(err=>{
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[dataGlobal.kecamatan])

    useEffect(()=>{
        if (history.location.pathname === '/data-individu') {
            axios({
                method : "GET",
                url : `${SERVER}/general/setting`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                setDataInstansi(data.instansi)
                if (data.propinsi) {
                    setDataPropinsi([data.propinsi])
                }
                if (data.kabupaten) {
                    setDataKabupaten([data.kabupaten])
                }
            })
            .catch(err=>{
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[history.location.pathname])

    useEffect(()=>{
        if (history.location.pathname === '/data-individu') {
            axios({
                method : "GET",
                url : `${SERVER}/kecamatan`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                setDataKecamatanASLI(data)
                let temp = []
                data.forEach(data=>{
                    temp.push(data.nama)
                })
                setDataKecamatan(temp)
            })
            .catch(err=>{
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[history.location.pathname])

    return (
        <div className="fc-id-190-per-content">

            <div className="id-190-title">
                Data Pribadi
            </div>

            <InputTypeOne
                title={"Nama Sesuai KTP"}
                stars={true}
                placeholder={"Nama Sesuai di KTP"}
                code={"namadiKtp"}
                type={"text"}
            />

            <InputTypeOne
                title={"Nama Panggilan"}
                stars={true}
                placeholder={"Tulis Nama Panggilan"}
                code={"namaPanggilan"}
                type={"text"}
            />

            {
                dataUser &&
                dataUser.role === 'master' &&
                <InputTypeTwo
                    title={"Status"}
                    stars={true}
                    placeholder={"Pilih Status"}
                    code={"status"}
                    type={"text"}
                    valueOption={status}
                    isDisable={dataUser && dataUser.role != 'master' ? true : false}
                />
            }

            {
                dataUser &&
                dataUser.role === 'master' &&
                <InputTypeTwo
                    title={"Sub Status"}
                    stars={true}
                    placeholder={"Sub Status"}
                    code={"subStatus"}
                    type={"text"}
                    valueOption={subStatus}
                    isDisable={dataUser && dataUser.role != 'master' ? true : false}
                />
            }

            {
                dataUser &&
                dataUser.role === 'master' &&
                <InputWithDateSingle
                    title={"Tanggal Status"}
                    stars={true}
                    placeholder={"Tanggal Status"}
                    code={"tanggalStatus"}
                    isDisable={dataUser && dataUser.role != 'master' ? true : false}
                />
            }

            {
                dataUser &&
                dataUser.role === 'master' &&
                <InputTypeTwo
                    stars={true}
                    title={"Isolasi Mandiri"}
                    code={"isolasiMandiri"}
                    type={"radio"}
                    valueOption={["Ya","Tidak"]}
                    isDisable={dataUser && dataUser.role != 'master' ? true : false}
                />
            }

            <InputTypeOne
                title={"No. HP"}
                placeholder={"Tulis Nomor HP yang Bisa Dihubungi"}
                code={"noHp"}
                type={"text"}
            />

            <InputTypeOne
                title={"NIK"}
                placeholder={"Tulis NIK"}
                code={"NIKDanPassport"}
                type={"text"}
            />


            <InputTypeOne
                title={"Usia"}
                placeholder={"Usia"}
                code={"usia"}
                // type={"number"}
                type={"text"}
                stars={true}
            />

            <InputTypeTwo
                title={"Jenis Kelamin"}
                code={"jenisKelamin"}
                type={"radio"}
                stars={true}
                valueOption={["Laki-laki","Perempuan"]}
            />

            <InputTypeOne
                title={"Alamat sesuai KTP"}
                placeholder={"Tulis Alamat Sesuai KTP"}
                code={"alamatDiKtp"}
                type={"text"}
            />

            <InputTypeOne
                title={"Alamat Saat ini"}
                placeholder={"Tulis Alamat Saat ini"}
                code={"alamatSaatIni"}
                type={"text"}
                // sameWith={"alamatDiKtp"}
            />

            <InputTypeTwo
                title={"Propinsi"}
                placeholder={"Pilih Propinsi"}
                code={"propinsi"}
                type={"text"}
                stars={true}
                valueOption={dataPropinsi}
            />

            <InputTypeTwo
                title={"Kabupaten"}
                placeholder={"Pilih Kabupaten"}
                code={"kabupaten"}
                type={"text"}
                stars={true}
                valueOption={dataKabupaten}
            />

            <InputTypeTwo
                title={"Kecamatan"}
                placeholder={"Pilih Kecamatan"}
                code={"kecamatan"}
                type={"text"}
                stars={true}
                valueOption={dataKecamatan}
            />

            {/* <InputTypeTwo
                title={"Kelurahan/Desa"}
                placeholder={"Pilih Kelurahan/Desa"}
                code={"kelurahan"}
                type={"text"}
                stars={true}
                valueOption={dataDesa}
                isDisable={dataDesa ? false : true}
            />   */}

            <InputTypeOne
                title={"Pekerjaan"}
                placeholder={"Tulis Pekerjaan"}
                code={"pekerjaan"}
                type={"text"}
            />

            <InputTypeOne
                title={"Instansi"}
                placeholder={"Tulis Instansi"}
                code={"instansi"}
                type={"text"}
                valueOption={dataInstansi}
            />

        </div>
    )

}

export default LeftContent