// MODULE
import React from 'react'

// STYLING
import './style.scss'

// MATERIAL UI ICON
import MenuIcon from '@material-ui/icons/Menu';

// IMAGE LOCAL
import optimus3 from '../../../images/optimus3.svg'
import banggai from '../../../images/kab-banggai-logo.png'
import Laptop from '../../../images/laptop-banggai.png'

function FirstContentMobile () {

    return (
        <div className="fcm-outside-374">

            <div className="fcm-374-container">
                
                <div className="fcm-logo-optimus-374">
                    <img src={optimus3}/>
                </div>

                <div className="fcm-burger-374" >
                    <MenuIcon style={{color : "#CCCCCC",width : "30px" , height : "30px"}}/>
                </div>


            </div>

            <div className="fcm-title-1-374">
                Pantau Covid 19
            </div>

            <div className="fcm-title-2-374">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
            </div>

            <div className="fcm-button-1-374">
                Lihat Fitur
            </div>

            <div className="fcm-hr1-374">

            </div>

            <div className="fcm-body-1-374">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </div>

            <div className="fcm-title-3-374-use">
                Digunakan Oleh
            </div>

            <div className="fcm-circle1-374">
                <img src={banggai}/>
            </div>

            <div className="fcm-title-key-374">
                Pemerintah Kabupaten Banggai
            </div>

            <div className="fcm-last-button-374">
                Lihat di sini
            </div>

            <img src={Laptop} className="fcm-laptop-content-374"/>

        </div>
    )

}

export default FirstContentMobile