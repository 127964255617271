import React from 'react'
import { useMediaQuery  } from 'react-responsive'

// Component
import FirstContent from './FirstContent'
import SecondContent from './SecondContent'
import ThirdContent from './ThirdContent'
import FirstContentMobile from './FirstContentMobile'

import './style.scss'

function MainLandingPage () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })


    return (
        <div className="mlp-345-container">
            
            {
                isTabletOrMobile ?
                <FirstContentMobile/>:
                <FirstContent/>
            }

            <SecondContent/>
            
            <ThirdContent/>

        </div>
    )

}

export default MainLandingPage;