// MODULE
import React from 'react'
import copy from 'copy-to-clipboard';
import Swal from 'sweetalert2'

// COMPONENT
import TitleContent from '../TitleContent'
import MainContent from '../MainContent'

// STYLING
import './style.scss'

function RightContent ( props ) {
    
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const handleCopy = (patient) => {
        copy(`Username : ${patient.email} \n Password : ${patient.truePassword} `, {})
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: 'Username dan Password Berhasil Disalin'
        })
    }

    return (
        <div className="di-879-per-item-right">
            
            <div className="di-879-item-content" style={{paddingBottom : 0}}>
                <TitleContent title={"Riwayat Pribadi"} />
                <MainContent
                    title={"Nama Pengguna"}
                    value={props.patientAccount.email}
                />
                <MainContent
                    title={"Password"}
                    value={props.patientAccount.truePassword}
                />
                <div className="di-879-container-copy-button">
                    <div className="di-879-right-button-copy" onClick={()=>handleCopy(props.patientAccount)}>
                        Salin Nama Pengguna & Password
                    </div>
                </div>
            </div>


            <div className="di-879-item-content">
                <TitleContent title={"Riwayat Kesehatan"} />
                <MainContent
                    title={"Kondisi Saat Ini"}
                    value={props.patient.kondisiSaatIni}
                />
                {
                    props.patient &&
                    props.patient.jenisSakit &&
                    props.patient.jenisSakit == '-' ?
                    <MainContent
                        title={"Jenis Sakit"}
                        value={props.patient.jenisSakit}
                        lastContent={true}
                    />
                    :
                    props.jenisSakit &&
                    props.patient.jenisSakit &&
                    <MainContent
                        title={"Keluhan"}
                        value={props.patient.jenisSakit[0]}
                        lastContent={true}
                    />
                }
                {
                    props.patient &&
                    props.patient.keluhan &&
                    props.patient.keluhan == '-' ?
                    <MainContent
                        title={"Keluhan"}
                        value={props.patient.keluhan}
                        lastContent={true}
                    />
                    :
                    props.patient &&
                    props.patient.keluhan &&
                    <MainContent
                        title={"Keluhan"}
                        value={props.patient.keluhan}
                        lastContent={true}
                    />
                }
                
            </div>

            <div className="di-879-item-content">
                <TitleContent title={"Riwayat Perjalanan"} />
                <MainContent
                    title={"Kota Asal"}
                    value={props.patient.kotaAsal}
                    />
                <MainContent
                    title={"Pintu Masuk"}
                    value={props.patient.pintuMasuk}
                    />
                <MainContent
                    title={"Transportasi Menuju Kab.Banggai"}
                    value={props.patient.transportasikeKota}
                />
                <MainContent
                    title={"Tanggal & Jam Masuk"}
                    value={ props.patient.tanggalMasuk && props.patient.jamMasuk && showDate(props.patient.tanggalMasuk) + ' - ' + showHour(props.patient.jamMasuk)}
                />
                <MainContent
                    title={"Alamat Tujuan"}
                    value={props.patient.alamatTujuan}
                    lastContent={true}
                />  
            </div>

        </div>
    )

}

export default RightContent