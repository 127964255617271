// MODULE
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import swal from 'sweetalert2';
import axios from 'axios'

// ENPOINT 
import SERVER from '../../../endPoint/index.js'

// COMPONENT
import LeftContent from './LeftContent'
import RightContent from './RightContent'

// STYLING
import './style.scss'

function FormContent () {

    // GLOBAL STATE
    const dataGlobal = useSelector(state=>state.individualState)

    // HOOKS

    useEffect(()=>{
        console.log(dataGlobal,'<><><><><><><><><><>>><<>')
    },[dataGlobal])

    let isValidationSucces = () => {
        let condition = true
        for (let i in dataGlobal) {
            if (!dataGlobal[i]) {
                condition = false
            }
        }
        return condition
    }

    let inputDataIndividualFN = (e) => {
        e.preventDefault()
        if (isValidationSucces()) {
            axios({
                data : {
                    data :  dataGlobal
                },
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/patient`,
                method : "POST"
            })
            .then(({data})=>{
                swal.fire({
                    title: 'Selamat',
                    text: 'Berhasil Input Data',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0280A2',
                })
            })
            .catch(err=>{
                swal.fire({
                    title: 'Gagal',
                    text: 'Internal Server Error',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#0280A2',
                })
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })

        }else {
            swal.fire({
                title: 'Gagal',
                text: 'Harap isi Semua Form',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }
    }

    return (
        <div className="form-content-id-190-container" onSubmit={e=>inputDataIndividualFN(e)}>
            
            <LeftContent/>
            <div style={
                {
                    height : "100%",
                    width : "0.5px",
                    backgroundColor : "#c4c4c4"
                }
            }>

            </div>
            <RightContent swal={swal}/>

        </div>
    )

}

export default FormContent