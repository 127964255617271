// MODULE
import React from 'react'
import { useDispatch } from 'react-redux'

// GLOBAL ACTION
import { SET_DATA_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLE
import './style.scss'

function InputTypeOne (props) {

    // INITIAL PROPS
    const {
        title,
        placeholder,
        code,
        type,
        isBig,
        isDisable,
        value,
        stars
    } = props


    // DISPATCH
    const dispatch = useDispatch()
    
    let changeInput = (e) => {
        dispatch(SET_DATA_INDIVIDUAL_ACTION({key : code , value : e.target.value}))
    }

    return (

        <form className="input-type-one-container-670" style={{alignItems : isBig ? "flex-start" : null}} onSubmit={e=>e.preventDefault()}>

            <div className="ito-title-670">
                {title}
                {
                    stars &&
                    <p style={{
                        color: "red",
                        marginLeft: 2
                    }} >
                        *
                    </p>
                }
            </div>

            <textarea 
                disabled={isDisable ? true : false}
                onChange={e=>changeInput(e)}
                className="ito-input-6701" 
                placeholder={placeholder}
                type={type}
                value={value}
                style={
                        {
                            minHeight : isBig ? "76px" : null,
                            // marginTop : "20px",
                            // ma
                            // textAlign : "right"
                            paddingBottom : isBig ? "35px" : null,
                            // border : isDisable ? "0.5px solid #C2C3C7" : null
                        }
                }
            >
            </textarea>
    
        </form>

    )

}

export default InputTypeOne