import React from 'react'

function Paragraph13 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginTop : "0"}}>1. Ingat, masker sebaiknya hanya digunakan oleh tenaga kesehatan, orang yang merawat orang sakit, dan orang-orang yang memiliki gejala-gejala pernapasan, seperti demam dan batuk.</p>
            <p>2. Sebelum menyentuh masker, bersihkan tangan dengan air bersih mengalir dan sabun atau cairan antiseptik berbahan dasar alkohol.</p>
            <p>3. Ambil masker dan periksa apakah ada sobekan atau lubang.</p>
            <p>4. Pastikan arah masker sudah benar (pita logam terletak di sisi atas).</p>
            <p>5. Pastikan sisi depan masker (sisi yang berwarna) menghadap depan.</p>
            <p>6. Letakkan masker di wajah Anda. Tekan pita logam atau sisi masker yang kaku sampai menempel sempurna ke hidung. 7. Tarik sisi bawah masker sampai menutupi mulut dan dagu.</p>
            <p>8. Setelah digunakan, lepas masker; lepas tali elastis dari daun telinga sambil tetap menjauhkan masker dari wajah dan pakaian, untuk menghindari permukaan masker yang mungkin terkontaminasi.</p>
            <p>9. Segera buang masker di tempat sampah tertutup setelah digunakan.</p>
            <p style={{marginBottom : "0"}}>10. Bersihkan tangan setelah menyentuh atau membuang masker – gunakan cairan antiseptik berbahan dasar alkohol atau, jika tangan terlihat kotor, cuci tangan dengan air bersih mengalir dan sabun.</p>
        </div>
    )

}

export default Paragraph13