import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

// COMPONENT 
import Header from '../LandingPageKabupaten/Header'
import NavbarMobile from '../../components/NavbarMobile'
import FirstContent from './FirstContent'
import SecondContent from './SecondContent'
import FooterContent from '../../components/FooterContent1'

import './style.scss'
import axios from 'axios'
import SERVER from '../../endPoint'

function LandingPageBerita () {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
    
    const [newsList, setNewsList] = useState([])

    useEffect(()=>{
        axios({
            method : "GET",
            url : `${SERVER}/berita`,
        })
        .then(({data})=>{
            setNewsList(data.sort((a,b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0)))
        })
        .catch(console.log)
    },[])

    
    const showHour = (hourParams) => {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ' WITA'
    }

    const showDay =  (date) => {
        let hari = ['Minggu','Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
        return hari[new Date(date).getDay()]
    }

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return (
        <div className="lp-berita-787">

            {
                isTabletOrMobile ? <NavbarMobile/> : <Header/>
            }
            {
                !isTabletOrMobile &&
                <div className="lpb-787-title">
                    Berita >
                </div>

            }
            
            <FirstContent
                newsList={newsList}
                showHour={showHour}
                showDay={showDay}
                showDate={showDate}
            />

            {
                newsList &&
                newsList
                    .slice(4, newsList.length)
                    .map((data, i)=>(
                            i == 0 &&
                            <div className="lpb-787-title-2">
                                Semua Berita
                            </div>
                    ))
            }

            <div className="lpb-787-hr">

            </div>

            <SecondContent
                newsList={newsList}
                showHour={showHour}
                showDay={showDay}
                showDate={showDate}
            />

            <FooterContent/>

        </div>

    )

}

export default LandingPageBerita