// MODULE
import React , { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch , useSelector } from 'react-redux'

// COMPONENT
import BoxContent from './BoxContent'
import FormContent from './FormContent'
import ViewContent from './ViewContent'
import axios from 'axios'
import SERVER from '../../endPoint'
// GLOBAL ACTION
import {
    SET_HELTY_RESET_ACTION,
    SET_PATIENT_HEALTHY_STATUS_ACTION,
    SET_PATIENT_TRANSIT_STATUS_ACTION,
    SET_DATA_INDIVIDUAL_ACTION
} from '../../store/actions/individual'

// STYLING
import './style.scss'

function IndividualData () {

    // HISTORY ROUTER
    const history = useHistory()

    // GLOBAL STATE
    const dataGlobal = useSelector(state=>state.individualState)
    const conditionIndividual = useSelector(state=> state.individualConditionState)

    // DISPATCH
    const dispatch = useDispatch()

    
    useEffect(()=>{
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/user/setting`
        })
        .then(({data})=>{
            if (data.role === 'patient') {
                history.replace('/landing-page-kabupaten')
            } else if (data.role === 'admin-berita') {
                history.replace('/input-berita')
            } else if (data.role === 'petugas') {
                history.replace('/data-individu')
            }
        })
        .catch(err=>{
            console.log(err , ' << ERROR')
        })
    },[])

    // HOOKS
    useEffect(()=>{

        let isHealthy = dataGlobal["kondisiSaatIni"]
        let isTransit = dataGlobal["transit"]

        if (isHealthy === 'Sakit') {
            dispatch(SET_PATIENT_HEALTHY_STATUS_ACTION(false))
        }else if (isHealthy === 'Sehat') {
            dispatch(SET_PATIENT_HEALTHY_STATUS_ACTION(true))
            dispatch(SET_HELTY_RESET_ACTION())
        }

        if (isTransit === "Tidak") {
            dispatch(SET_PATIENT_TRANSIT_STATUS_ACTION(false))
        }else if (isTransit === "Ya") {
            dispatch(SET_PATIENT_TRANSIT_STATUS_ACTION(true))
        }

    },[dataGlobal["kondisiSaatIni"]])

    useEffect(()=>{
        // console.log(conditionIndividual , ' <<< INDIVIDUAL')
    },[conditionIndividual])

    return (
        <div className="id-190-container">

            <div className="id-190-title">
                Data Individu
            </div>

            <BoxContent/>
            {
                history.location.pathname == '/data-individu' ?
                <FormContent/>
                :
                <ViewContent/>
            }

        </div>
    )

}

export default IndividualData;