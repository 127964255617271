// MODULE
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

// COMPONENT
import InputTypeOne from '../../InputComponents/InputTypeOne'
import InputTypeTwo from '../../InputComponents/InputTypeTwo'
import TextAreaType from '../../InputComponents/TextArea'
import InputWithDate from '../../InputComponents/InputWithDate'

// ENDPOINT
import SERVER from '../../../../endPoint/index'
import { SET_RESET_INDIVIDUAL_ACTION } from '../../../../store/actions/individual'

// STYLING
import "react-datepicker/dist/react-datepicker.css";

function RightContent (props) {

    const [sakit, setDataSakit] = useState(null)
    const history = useHistory()
    const dispatch = useDispatch()

    // PROPS
    const { swal } = props

    // GLOBAL STATE
    const dataUser = useSelector(state=> state.UserReducer.dataUser)
    const dataGlobal = useSelector(state=>state.individualState)
    const conditionIndividual = useSelector(state=> state.individualConditionState)

    console.log(dataGlobal)

    // HOOKS

    let isValidationSucces = () => {
        let condition = true
        for (let i in dataGlobal) {
            if (dataUser.role === 'petugas' || dataUser.role === 'master' ) {
                if (i == 'jenisSakit' || i == 'keluhan') {
                    if (!conditionIndividual.isPatientHealty) {
                        if (dataGlobal["jenisSakit"] == '-' || dataGlobal["keluhan"] == '-' || !dataGlobal["jenisSakit"] || !dataGlobal["keluhan"]) {
                            condition = false
                        }
                    }
                } else if(i === 'status' || i === 'subStatus' || i === 'isolasiMandiri') {
                    if (dataUser.role === 'master') {
                        if (!dataGlobal['status'] || !dataGlobal['subStatus'] || !dataGlobal['isolasiMandiri']) {
                            condition = false
                        }
                    } else if (dataGlobal['status'] || dataGlobal['subStatus'] || dataGlobal['isolasiMandiri']) {
                        condition = false
                    }
                } else if(
                    i === "tanggalMasuk"||
                    i === "jamMasuk" ||
                    i === 'kotaAsal' ||
                    i === 'pintuMasuk' ||
                    i === 'transportasikeKota'||
                    i === 'transit' ||
                    i === 'alamatTujuan' ||
                    i === 'instansi' ||
                    i === 'noHp' ||
                    i === 'NIKDanPassport' ||
                    i === 'alamatSaatIni' ||
                    i === 'alamatDiKtp' ||
                    i === 'pekerjaan'
                ){} else {
                    console.log(i)
                    if (!dataGlobal[i]) {
                        condition = false
                    }
                }
            }
        }
        return condition
    }

    useEffect(()=>{
        console.log(dataGlobal , ' <<< VALUE DATA GLOBAL')
    },[dataGlobal])

    useEffect(()=>{
        if (history.location.pathname === '/data-individu') {
            axios({
                method : "GET",
                url : `${SERVER}/general/setting`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
            })
            .then(({data})=>{
                setDataSakit(data.jenisSakit)
            })
            .catch(err=>{
                console.log(err , ' <<< PENYEBAB ERROR >>>')
            })
        }
    },[history.location.pathname])

    let inputDataIndividualFN = (e) => {
        e.preventDefault()
        swal.showLoading()
        const swalWithBootstrapButtons = swal.mixin({
            customClass: {
                confirmButton: 'btn-style-angga-confirm',
                cancelButton: 'btn-style-angga-cancel'
            },
            buttonsStyling: false
        })
        if (isValidationSucces()) {
            axios({
                data : {
                    data :  dataGlobal,
                    userInput : dataUser
                },
                headers : {
                    token : localStorage.getItem('token_access')
                },
                url : `${SERVER}/patient`,
                method : "POST"
            })
            .then(({data})=>{
                console.log(data, '<<<<<<<?<<<<<<>?<?<?<')
                swal.close()
                return swalWithBootstrapButtons.fire({
                    title: 'Selamat',
                    html: `Username : ${data.username} <br> Password : ${data.password}`,
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonText: 'Lihat Daftar',
                    cancelButtonText: 'Input Lagi',
                    reverseButtons: true
                })
            })
            .then((result) => {
                if (result.value) {
                    history.push(`/data-individu-list`)
                    dispatch(SET_RESET_INDIVIDUAL_ACTION())
                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                    dispatch(SET_RESET_INDIVIDUAL_ACTION())
                    // let data = document.getElementsByClassName('ito-input-670')
                    // for (let i in data  ) {
                    //     // console.log(data[i] , ' <<<< VALUE DATA')
                    //     if (data[i]) {
                    //         if (data[i].value) {
                    //             data[i].value = ""
                    //         }
                    //     }
                    // }
                    window.location.reload(false);
                }
            })
            .catch(err=>{
                swal.close()
                console.log(err.response, 'errrrrr')
                if (err.response) {
                    if (err.response.data) {
                        if ((err.response.data).includes('Tanggal Status Tidak Ditemukan!')) {
                            swal.fire({
                                title: 'Gagal',
                                html: err.response.data,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#0280A2',
                            })
                        }
                        if ((err.response.data).includes('E11000 duplicate key error collection')) {
                            swal.fire({
                                title: 'Gagal',
                                html: "NIK yang anda masukkan salah, <br> Cek kembali NIK anda',",
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#0280A2',
                            })
                        } 
                    }
                } else {
                    swal.fire({
                        title: 'Gagal',
                        text: 'Internal Server Error',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0280A2',
                    })
                }
            })
        } else {
            swal.fire({
                title: 'Gagal',
                text: 'Data yang anda isi belum lengkap',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }
    }

    return (
        <div className="fc-id-190-per-content" >

            <div className="id-190-title">
                Riwayat Kesehatan
            </div>

            <InputTypeTwo
                title={"Kondisi Saat ini"}
                code={"kondisiSaatIni"}
                stars={true}
                type={"radio"}
                valueOption={["Sehat","Sakit"]}
            />
            
            <InputTypeOne
                title={"Jenis Sakit"}
                placeholder={dataGlobal["kondisiSaatIni"] === "Sehat" ? "-" : "Jenis Sakit yang Dirasakan"}
                code={"jenisSakit"}
                type={"text"}
                stars={ dataGlobal["kondisiSaatIni"] === "Sakit" ? true : false}
                valueOption={dataGlobal["kondisiSaatIni"] === "Sehat" ? ["-"] : sakit}
                isDisable={ !dataGlobal["kondisiSaatIni"] ? true : dataGlobal["kondisiSaatIni"] === "Sehat" ? true : false}
            />

            <TextAreaType
                title={"Keluhan"}
                placeholder={"Tulis Keluhan yang Dirasakan"}
                code={"keluhan"}
                type={"text"}
                stars={ dataGlobal["kondisiSaatIni"] === "Sakit" ? true : false}
                isBig={true}
                isDisable={ !dataGlobal["kondisiSaatIni"] ? true : dataGlobal["kondisiSaatIni"] === "Sehat" ? true : false}
            />

            <div className="id-190-title" style={{marginTop : "75px" , marginBottom : "15px"}}>
                Riwayat Perjalanan
            </div>

            <InputTypeOne
                title={"Kota Asal"}
                placeholder={"Tulis Kota Asal"}
                code={"kotaAsal"}
                type={"text"}
            />

            <InputTypeOne
                title={"Pintu Masuk"}
                placeholder={"Tulis Pintu Masuk"}
                code={"pintuMasuk"}
                type={"text"}
            />

            <InputTypeTwo
                title={"Transportasi Menuju Kab. Banggai"}
                placeholder={"Pilih Transportasi yang Digunakan"}
                code={"transportasikeKota"}
                type={"text"}
                valueOption={["Mobil","Bus","Sepeda Motor", "Pesawat", "Kapal Laut"]}
            />

            <InputTypeTwo
                title={"Transit"}
                code={"transit"}
                type={"radio"}
                valueOption={["Ya","Tidak"]}
            />

            <InputWithDate
                title={"Tanggal & Jam Masuk"}
                code={"tanggalMasuk"}
                code2={"jamMasuk"}
                type={"radio"}
                isDisable={ !dataGlobal["transit"] ? true : false }
            />

            <InputTypeOne
                title={"Alamat Tujuan"}
                placeholder={"Tulis Alamat Tujuan"}
                code={"alamatTujuan"}
                type={"text"}
                isDisable={ !dataGlobal["transit"] ? true : false }
            />

            <div style={{marginBottom : "15px"}}>

            </div>

            <div className="fc-container-button-190">

                <div className="fc-last-button-190" onClick={e=>inputDataIndividualFN(e)}>
                    Simpan
                </div>

            </div>

        </div>
    )

}

export default RightContent