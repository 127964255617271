// export default DailyReport
import React,{ useState, useEffect } from 'react'
import './style.scss'
import axios from 'axios'
// import SERVER from '../../../endPoint'
import { useHistory,  } from 'react-router-dom'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from '@material-ui/core/Drawer';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

const useStyles1 = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 5,
            '&.Mui-focused fieldset': {
                borderColor: '#0185ff',
            },
        },
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '& .MuiButton-root' : {
            backgroundColor: '#0185ff',
        }
    },
    filterBtn:{
        width: '92px',
        height: '35px',
        fontFamily: 'SFProText',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#b9b9b9',
        backgroundColor: 'white',
    },
    loginBtn:{
        width: '68px',
        height: '25px',
        borderRadius: '4px',
        border: 'solid 0.5px #0185ff',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185ff',
        marginTop: '-10px'
    },
    headerTable1:{
        fontSize: 11,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.1%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable4:{
        fontSize: 11,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0.5%',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 30
    },
    headerTable2:{
        fontSize: 11,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '1%',
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerTable3:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '0%',
        paddingTop: 10,
        paddingBottom: 10,
    },
    headerTable:{
        fontSize: 12,
        color: '#888888',
        fontFamily: "'Poppins', sans-serif",
        width: '3%',
        paddingTop: 10,
        paddingBottom: 10
    },
    tableCell1:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
        paddingLeft: 30
    },
    tableCell:{
        fontFamily: 'SFProText',
        fontsize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#444444',
    },
    tableHead:{
        backgroundColor: '#ffffff'
    },
    tableContainer:{
        boxShadow: 'none',
        borderBottom: 'none',
        borderRadius: '4px',
        backgroundColor: '#ffffff',
        maxWidth: '87%'
    },
    searchIcon:{
        color : "#888888",
        marginRight : "5%",
        paddingLeft: '5%'
    },
    insideRow:{
        borderBottom: 'none'
    },
    filterIcon:{
        marginLeft : "auto",
        marginRight: 5
    },
    controlLabel:{
        fontFamily: 'OpenSans',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#999999',
        marginBottom : 9,
        marginLeft: 8,
    },
    formControl:{
        marginTop: 9
    },
    radioGroup:{
        borderBottom: '1px solid #cccccc'
    },
    btnSubmit:{
        width: '220px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: '#0185ff',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        textTransform: "none",
        '&:hover': {
            backgroundColor: '#0185ff',
            color: '#FFF'
        }
    },
    btnLink:{
        width: '91px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: '#0185ff',
        fontSize: '14px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        color: '#ffffff',
    },
    btnDetail:{
        width: '91px',
        height: '32px',
        borderRadius: '4px',
        backgroundColor: 'white',
        fontSize: '14px',
        border: 'solid 1px #e8e8e8',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'none',
        color: '#0185ff',
    },
}));

const RadioCustom = withStyles({
    root: {
        marginLeft: 11,
        width: 13,
        height: 13,
        color: '#999999',
        '&$checked': {
            color: '#0185ff',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function Beranda(props){
    const history = useHistory()
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [searchValue, setSearch] = useState(null);
    const [click, setClick] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const {dataPatient,setDataPatient} = props

    const [drawerState, setDrawerState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(+event.target.value, 10));
        setPage(0);
    }

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!click){
            setValue(null);
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setClick(true)
        setDrawerState({ ...drawerState, ['right']: false });
    }

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    }

    const handleRadioReset = () => {
        setValue(null);
        setClick(false)
        setDrawerState({ ...drawerState, ['right']: false });
    }

    let renderPerButton = (obj,id) => {
        if (obj) {
            console.log(obj , ' <<<< VALUE OBJ')
            // if (new Date(obj.date) <= new Date()) {
            // if (
                // new Date(obj.date).getDate() >= new Date().getDate() &&
                // new Date(obj.date).getMonth() >= new Date().getMonth()&&
                // new Date(obj.data).getFullYear() >= new Date().getFullYear()
                // new Date(obj.date) >= new Date()
            // ){
            // }else {
                // return <div>-</div>
            // }
        // }else {
            // return <></>
        // }
            return (
                <div 
                    className="dr-754-button"
                    style={{
                        color :  !obj.diagnosa ? "#DC4242" : "#3be641",
                        cursor: 'pointer'
                    }}
                    onClick={()=> obj.diagnosa && history.push('/riwayat-diagnosa/'+id) }
                >
                    {
                        new Date(obj.date).getDate() + '-'  + new Date(obj.date).getMonth() + "-" + new Date(obj.date).getFullYear()
                    }
                </div>
            )
        }
    }

    return (
        <div className="beranda-container">

            <div className="view-individu-filter-container" style={{marginTop : "29px"}}>
                <div className="search-guest-content-first-1" >
                    <input className="input-search-first-guest-1" placeholder="Cari Nama" onChange={(e)=>setSearch(e.target.value)} />
                    <div className="container-for-search-icon-first-1">
                        <SearchIcon className={classes.searchIcon}/>
                    </div>
                </div>
                {/* <div className="view-individu-filterBtn-container" >
                    <Button
                        onClick={toggleDrawer('right', true)}
                        type="submit" size="small" className={classes.filterBtn}
                    >
                        <span className="view-individu-filterBtn-text">
                            Filter
                        </span>
                        <FilterListIcon className={classes.filterIcon} />
                    </Button>
                </div> */}
            </div>

            <div className="table-alert-list">
                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead} >
                            <TableRow className={classes.tableHead} >
                                <TableCell className={classes.headerTable1} >
                                    <div className="dr-title-754-small">NO</div>
                                </TableCell>
                                <TableCell className={classes.headerTable2} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754-big">Nama lengkap</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-1</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-2</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-3</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-4</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-5</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-6</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-7</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-8</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-9</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }} >
                                    <div className="dr-title-754">Hari-10</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-11</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-12</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} >
                                    <div className="dr-title-754">Hari-13</div>
                                </TableCell>
                                <TableCell align="left" className={classes.headerTable3} style={{ backgroundColor: "#FAFAFA" }}>
                                    <div className="dr-title-754">Hari-14</div>
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { console.log(dataPatient, 'asasasasa') }
                        {
                        dataPatient.length > 0 &&
                            dataPatient
                                .filter(a=> searchValue ? ((a.namadiKtp).toLowerCase()).includes((searchValue).toLowerCase()) && a.isolasiMandiri === "Ya"  : a.isolasiMandiri === "Ya" )
                                .sort((a,b) => (a.nama < b.nama) ? 1 : ((b.nama < a.nama) ? -1 : 0))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((patient, index) => (
                                <TableRow key={index} className={classes.insideRow}>
                                    <TableCell component="th" scope="row" align="left" className={classes.tableCell1} >
                                        { index+=1 }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        { patient.namadiKtp }
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay1, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay2, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay3, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay4, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay5, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay6, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay7, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay8, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay9, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay10, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay11, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay12, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell}>
                                        {renderPerButton(patient.dateDay13, patient._id)}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableCell} style={{ backgroundColor: "#FAFAFA" }}>
                                        {renderPerButton(patient.dateDay14, patient._id)}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                        <TableFooter >
                            <TableRow >
                                <TablePagination
                                    labelRowsPerPage='Tampilan per halaman:'
                                    rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
                                    colSpan={9}
                                    count={dataPatient.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} dari ${count}` }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    style={{textAlign : "right"}}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>

            <Drawer anchor="right" open={drawerState.right} onClose={toggleDrawer('right', false)}>
                <div className="view-individu-drawer-container">
                    <div className="view-individu-drawer-container-top">
                        <span className="view-individu-drawer-text-top">
                            FILTER
                        </span>
                        <span className="view-individu-drawer-text-top-reset" onClick={()=>{handleRadioReset()}}>
                            Hapus Filter
                        </span>
                    </div>
                    <div className="view-individu-drawer-container-middle">
                        <span className="view-individu-drawer-text-middle">
                            Pilih Status
                        </span>
                        <form onSubmit={handleSubmit}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                                    <FormControlLabel className={classes.controlLabel} value="pelakuPerjalanan" control={<RadioCustom size="small"/>} label="Pelaku Perjalanan" />
                                    <FormControlLabel className={classes.controlLabel} value="OTG" control={<RadioCustom size="small"/>} label="Orang Tanpa Gejala (OTG)" />
                                    <FormControlLabel className={classes.controlLabel} value="ODP" control={<RadioCustom size="small"/>} label="Orang Dalam Pemantauan(ODP)" />
                                    <FormControlLabel className={classes.controlLabel} value="PDP" control={<RadioCustom size="small"/>} label="Pasien Dalam Pengawasan (PDP)" />
                                    <FormControlLabel className={classes.controlLabel} value="Konfirmasi Positif" control={<RadioCustom size="small"/>} label="Konfirmasi Positif" />
                                    <FormControlLabel className={classes.controlLabel} value="Meninggal Dunia" control={<RadioCustom size="small"/>} label="Meninggal Dunia" />
                                    <FormControlLabel className={classes.controlLabel} value="Pasien Sembuh" control={<RadioCustom size="small"/>} label="Pasien Sembuh" />
                                </RadioGroup>
                            </FormControl>
                        </form>
                    </div>
                    <div className="radio-button-submit-container">
                        <Button onClick={(e)=>[handleSubmit(e)]} className={classes.btnSubmit}>
                            Terapkan
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}