import React, { useState, useEffect } from 'react'
import axios from 'axios'
import swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles';

// COMPONENT
import Navbar from '../../../components/NavbarMobile'
import FooterContent from '../../../components/FooterContent1'
import Header from '../../LandingPageKabupaten/Header'

// STYLE
import './style.scss'

// ENDPOINT
import SERVER from '../../../endPoint'

const useStyles = makeStyles((theme) => ({
    btnSimpan: {
        width: 100,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#0185FF',
        borderRadius: 6,
        marginTop: 15,
    },
    btnSimpanWeb : {
        width: "160px",
        height: "38px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'white',
        textTransform: 'none',
        backgroundColor: '#0185FF',
        borderRadius: 6,
        marginTop: 15,
    },
    btnBack: {
        width: 200,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#0185FF',
        textTransform: 'none',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 15,
        border: "1px solid #0185FF"
    }
}));

export default function Diagnosa() {
    const classes = useStyles();
    const history = useHistory()
    let [count, setCount] = useState(1)
    const [hariKe, setHariKe] = useState(0)
    const [valueInput, setValueInput] = useState([])
    const [exercises, setExercise] = useState([])
    const [finish, setFinish] = useState(false)
    const [notDay, setNotDay] = useState(false)
    const [triggered, setTrigger] = useState(false)
    const [countExercise] = useState(
        [
            "",
            "Pertama",
            "Kedua",
            "Ketiga",
            "Keempat",
            "Kelima",
            "Keenam",
            "Ketujuh",
            "Kedelapan",
            "Kesembilan",
            "Kesepuluh",
            "Kesebelas",
            "Keduabelas",
            "Ketigabelas",
            "Keempatbelas",
            "Ketujuhbelas",
            "Kedelapanbelas",
            "Kesembilanbelas",
            "Keduapuluh",
            "Keduapuluhsatu",
            "Keduapuluhdua",
            "Keduapuluhtiga",
            "Keduapuluhduaempat",
            "Keduapuluhdualima",
        ]
    )
    const [waktu, setWaktu] = useState(null)
    const [key, setKey] = useState(null)
    const [flag, setFlag] = useState(null)
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const [location, setLocation] = useState(false)
    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month + ' ' + year
    }

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${SERVER}/diagnosa`,
            headers: {
                token: localStorage.getItem('token_access')
            },
        })
            .then(({ data }) => {
                let exerTemp = ["", ...data[0].diagnosisData]
                setExercise(exerTemp)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${SERVER}/patient/getme`,
            headers: {
                token: localStorage.getItem('token_access')
            },
        })
        .then(({ data }) => {
            const swalWithBootstrapButtons = swal.mixin({
                customClass: {
                    confirmButton: 'btn-style-angga-confirm-008',
                    cancelButton: 'btn-style-angga-cancel'
                },
                buttonsStyling: false
            })
            if (data.isolasiMandiri === 'Tidak') {
                swalWithBootstrapButtons.fire({
                    html: `Anda Tidak Memiliki Akses Mengisi Diagnosa Mandiri`,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    reverseButtons: true,
                    showCancelButton: false,
                })
                .then((result) => {
                    if (result.value) {
                        history.push('/landing-page-kabupaten')
                    } else if ( result.dismiss === swal.DismissReason.cancel ) {
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
            } else {
                if (new Date() < new Date(data.dateDay1.date)) {
                    swalWithBootstrapButtons.fire({
                        html: `Jadwal Pengisian Diagnosa Mandiri Anda <br> Dimulai ${showDate(data.dateDay1.date)}`,
                        icon: 'info',
                        confirmButtonText: 'OK',
                        reverseButtons: true,
                        showCancelButton: false,
                    })
                    .then((result) => {
                        if (result.value) {
                            history.push('/landing-page-kabupaten')
                        } else if ( result.dismiss === swal.DismissReason.cancel ) {
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                    setNotDay(true)
                } else {
                    let today = new Date()
                    if (showDate(data.dateDay1.date) === showDate(today)) {
                        setHariKe(1)
                        if (data.dateDay1.diagnosa) {
                            swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay1.date)))
                            setKey("dateDay1")
                        }
                    } else if (showDate(data.dateDay2.date) === showDate(today)){
                        setHariKe(2)
                        if (data.dateDay2.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay2.date)))
                            setKey("dateDay2")
                        }
                    } else if (showDate(data.dateDay3.date) === showDate(today)){
                        setHariKe(3)
                        if (data.dateDay3.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay3.date)))
                            setKey("dateDay3")
                        }
                    } else if (showDate(data.dateDay4.date) === showDate(today)){
                        setHariKe(4)
                        if (data.dateDay4.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay4.date)))
                            setKey("dateDay4")
                        }
                    } else if (showDate(data.dateDay5.date) === showDate(today)){
                        setHariKe(5)
                        if (data.dateDay5.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay5.date)))
                            setKey("dateDay5")
                        }
                    } else if (showDate(data.dateDay6.date) === showDate(today)){
                        setHariKe(6)
                        if (data.dateDay6.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay6.date)))
                            setKey("dateDay6")
                        }
                    } else if (showDate(data.dateDay7.date) === showDate(today)){
                        setHariKe(7)
                        if (data.dateDay7.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay7.date)))
                            setKey("dateDay7")
                        }
                    } else if (showDate(data.dateDay8.date) === showDate(today)){
                        setHariKe(8)
                        if (data.dateDay8.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay8.date)))
                            setKey("dateDay8")
                        }
                    } else if (showDate(data.dateDay9.date) === showDate(today)){
                        setHariKe(9)
                        if (data.dateDay9.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay9.date)))
                            setKey("dateDay9")
                        }
                    } else if (showDate(data.dateDay10.date) === showDate(today)){
                        setHariKe(10)
                        if (data.dateDay10.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay10.date)))
                            setKey("dateDay10")
                        }
                    } else if (showDate(data.dateDay11.date) === showDate(today)){
                        setHariKe(11)
                        if (data.dateDay11.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay11.date)))
                            setKey("dateDay11")
                        }
                    } else if (showDate(data.dateDay12.date) === showDate(today)){
                        setHariKe(12)
                        if (data.dateDay12.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay12.date)))
                            setKey("dateDay12")
                        }
                    } else if (showDate(data.dateDay13.date) === showDate(today)){
                        setHariKe(13)
                        if (data.dateDay13.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay13.date)))
                            setKey("dateDay13")
                        }
                    } else if (showDate(data.dateDay14.date) === showDate(today)){
                        setHariKe(14)
                        if (data.dateDay14.diagnosa) {
                            setFlag(true)
                             swalWithBootstrapButtons.fire({
                                html: `Anda Sudah Mengisi Diagnosa Mandiri Hari ini!`,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                reverseButtons: true,
                                showCancelButton: false,
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push('/landing-page-kabupaten')
                                } else if ( result.dismiss === swal.DismissReason.cancel ) {
                                }
                            })
                            .catch(err=>{
                                console.log(err)
                            })
                            setFinish(true)
                        } else {
                            setWaktu(showDate(new Date(data.dateDay14.date)))
                            setKey("dateDay14")
                        }
                    }
                }
            }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    if (localStorage.getItem('token_access')) {
                        axios({
                            method: 'patch',
                            url: `${SERVER}/patient/location`,
                            headers: {
                                token: localStorage.getItem('token_access')
                            },
                            data: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        })
                        .then(({ data }) => {
                            location ? setLocation(false) : setLocation(true)
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                    }
                });
            } else {
                console.log("Not Available");
            }
        }, 3000);
    }, [location, localStorage.getItem('token_access')])

    useEffect(() => {
        if (key === 'dateDay1') {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    if (localStorage.getItem('token_access')) {
                        axios({
                            method: 'patch',
                            url: `${SERVER}/patient/location/getone`,
                            headers: {
                                token: localStorage.getItem('token_access')
                            },
                            data: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        })
                        .then(({ data }) => {
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                    }
                });
            } else {
                console.log("Not Available");
            }
        }
    }, [key === 'dateDay1'])

    const handleClick = (id) => {
        let temp = []
        if (valueInput.length === 0) {
            temp.push(id)
            setValueInput(temp)
        } else {
            let flag = false
            valueInput.map(val => {
                if (val == id) {
                    flag = true
                } else {
                    temp.push(val)
                }
            })
            if (!flag) {
                temp.push(id)
            }
            setValueInput(temp)
        }
    }

    const handleSimpan = () => {
        if(valueInput.length === 0){
            swal.fire({
                html: 'Jawaban Tidak Boleh Kosong',
                icon: 'warning',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            let last = false
            if (count == exercises.length - 1) {
                last = true
            }
            exercises[count].answer.map((ans, i) => {
                valueInput.map(val => {
                    if (i != exercises[count].answer.length - 1) {
                        if (val == ans.value) {
                            setTrigger(true)
                        }
                    }
                })
            })
            axios({
                method: 'PATCH',
                url: `${SERVER}/patient/getme`,
                headers: {
                    token: localStorage.getItem('token_access')
                },
                data: {
                    jawaban: valueInput,
                    pertanyaan: exercises[count].question,
                    hariKe: key,
                    last
                }
            })
            .then(({ data }) => {
                setCount(count += 1)
                setValueInput([])
                if (last) {
                    setFinish(true)
                }
                if(exercises[count]){
                    if(exercises[count].answer){
                        for (let i = 0; i < exercises[count].answer.length; i++) {
                            if (document.getElementById("checkBox" + i)) {
                                if(document.getElementById("checkBox" + i).checked){
                                    document.getElementById("checkBox" + i).checked = false;
                                }
                            }
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    return (
        <div className="mobile-diagnosa-container-001">
            {
                isMobile ?
                <div className="diagnosa-mobile-navbar" >
                    <Navbar /> :
                </div> 
                :
                <Header/>
            } 
            {/* <div className="diagnosa-mobile-navbar" >
                <Navbar />
            </div> */}

            {
                !isMobile ?
                <div className="diagnosa-mobile-navbar" >
                     <div className="diagnosa-title-1">
                        Diagnosa Mandiri > 
                    </div>
                </div>
                : <></>

            }

            {
                finish ?
                    triggered ?
                        <>
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Perhatian
                        </span>
                            </div>
                            <div className="diagnosa-mobile-container-grey-002" >
                                <div className="diagnosa-mobile-container-grey-001">
                                    <span className="diagnosa-mobile-text012">
                                        Sebagai tindakan pencegahan, Pemkab Banggai meminta siapa saja yang mengalami gejala (demam, batuk, bersin, sakit tenggorokan, atau sulit bernapas) untuk tinggal di rumah selama 14 hari untuk mencegah penyebaran. Anda disarankan untuk tidak keluar ke tempat publik, tinggal dirumah saja dan tidak boleh ada tamu. Anda disarankan untuk menelepon call center Pemkab Banggai
                            </span>
                                </div>
                            </div>
                            <div className="btn-containet-lgou">
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: 'white' }}
                                    size="medium"
                                    className={classes.btnBack}
                                    onClick={() => history.replace('/landing-page-kabupaten')}
                                >
                                    Kembali ke Beranda
                                </Button>
                            </div>
                        </>
                        :
                        <>
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Terimakasih Telah Mengisi Diagnosa Mandiri untuk Hari ini
                        </span>
                            </div>
                            <div className="diagnosa-mobile-container-grey-002" >
                                <div className="diagnosa-mobile-container-grey-001">
                                    <span className="diagnosa-mobile-text012">
                                        Jangan lupa untuk melakukan diagnosa mandiri secara rutin sesuai dengan jadwal yang ditentukan. Tetap jaga kesehatan dan senantiasa menerapkan phisical distancing
                                    </span>
                                </div>
                            </div>
                            <div className="btn-containet-lgou">
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: 'white' }}
                                    size="medium"
                                    className={classes.btnBack}
                                    onClick={() => history.replace('/landing-page-kabupaten')}
                                >
                                    Kembali ke Beranda
                        </Button>
                            </div>
                        </>
                    :
                    <>
                        {
                            isMobile ?
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" >
                                    Diagnosa Mandiri
                                </span>
                                <span className="diagnosa-mobile-text001" >
                                    Dengan Status
                                </span>
                            </div> :
                            <div className="diagnosa-mobile-navbar-004" >
                                <span className="diagnosa-mobile-text001" style={{marginTop : !isMobile ? "15px" : null}} >
                                    Diagnosa Mandiri Dengan Status
                                </span>
                            </div>
                            
                        }
                        <div className="diagnosa-mobile-container-grey-002" >
                            <div className="diagnosa-mobile-container-grey-001">
                                <span className="diagnosa-mobile-text002">
                                    Untuk Mendapatkan Hasil Diagnosis Akurat, Anda Diharuskan Mengisi Pertanyaan Berikut dengan Jawaban yang Jujur dan Rutin Mengisinya Selama 14 Hari
                            </span>
                            </div>
                        </div>
                        <div className="diagnosa-mobile-container-grey-007" >
                            <div className="diagnosa-mobile-text005">
                                Hari Ke - {hariKe}
                            </div>
                            <div className="diagnosa-mobile-text006">
                                Pertanyaan {countExercise[count]}
                            </div>
                        </div>

                        <div 
                            className="diagnosa-mobile-container-grey-007" 
                            style={{alignItems : !isMobile ? "center": null}}
                        >
                            <div className="pertanyaan-diagnosa">
                                {
                                    exercises[count] &&
                                    exercises[count].question
                                }
                            </div>
                            <div className="container-jawaban-diagnosa" >
                                {
                                    exercises[count] &&
                                    exercises[count].answer &&
                                    exercises[count].answer.map((ans, i) => (
                                        <label className="jawaban-diagnosa" key={i}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: 'row',
                                                fontSize: isMobile ? 12 : 16,
                                                alignItems: "center",
                                            }}>
                                                <input type="checkbox"
                                                    id={"checkBox" + i}
                                                    onChange={(e) => handleClick(ans.value)}
                                                />
                                                <span style={{ marginLeft: !isMobile ? 11 : '' }}   >
                                                    {ans.value}
                                                </span>
                                            </div>
                                        </label>
                                    ))
                                }
                                <div className="btn-simpan-container" >
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: '#0185FF' }}
                                        size="medium"
                                        // className={classes.btnSimpan}
                                        className={ !isMobile ? classes.btnSimpanWeb :  classes.btnSimpan}
                                        onClick={() => handleSimpan()}
                                    >
                                        Simpan
                                </Button>
                                </div>
                            </div>
                        </div>
                    </>
            }

            {
                !isMobile &&
                <FooterContent style={{marginTop : "130px",width : "100%"}}/>
            }
        </div>
    )

    // return (
    //     isMobile ?
    //         <div className="mobile-diagnosa-container-001">
    //             <div className="diagnosa-mobile-navbar" >
    //                 <Navbar />
    //             </div>

    //             {
    //                 finish ?
    //                     triggered ?
    //                         <>
    //                             <div className="diagnosa-mobile-navbar-004" >
    //                                 <span className="diagnosa-mobile-text001" >
    //                                     Perhatian
    //                         </span>
    //                             </div>
    //                             <div className="diagnosa-mobile-container-grey-002" >
    //                                 <div className="diagnosa-mobile-container-grey-001">
    //                                     <span className="diagnosa-mobile-text012">
    //                                         Sebagai tindakan pencegahan, Pemkab Banggai meminta siapa saja yang mengalami gejala (demam, batuk, bersin, sakit tenggorokan, atau sulit bernapas) untuk tinggal di rumah selama 14 hari untuk mencegah penyebaran. Anda disarankan untuk tidak keluar ke tempat publik, tinggal dirumah saja dan tidak boleh ada tamu. Anda disarankan untuk menelepon call center Pemkab Banggai
    //                             </span>
    //                                 </div>
    //                             </div>
    //                             <div className="btn-containet-lgou">
    //                                 <Button
    //                                     type="submit"
    //                                     style={{ backgroundColor: 'white' }}
    //                                     size="medium"
    //                                     className={classes.btnBack}
    //                                     onClick={() => history.replace('/landing-page-kabupaten')}
    //                                 >
    //                                     Kembali ke Beranda
    //                                 </Button>
    //                             </div>
    //                         </>
    //                         :
    //                         <>
    //                             <div className="diagnosa-mobile-navbar-004" >
    //                                 <span className="diagnosa-mobile-text011" >
    //                                     Terimaksih Telah Mengisi Diagnosa Mandiri untuk Hari ini
    //                         </span>
    //                             </div>
    //                             <div className="diagnosa-mobile-container-grey-002" >
    //                                 <div className="diagnosa-mobile-container-grey-001">
    //                                     <span className="diagnosa-mobile-text012">
    //                                         Jangan lupa untuk melakukan diagnosa mandiri secara rutin sesuai dengan jadwal yang ditentukan. Tetap jaga kesehatan dan senantiasa menerapkan phisical distancing
    //                                     </span>
    //                                 </div>
    //                             </div>
    //                             <div className="btn-containet-lgou">
    //                                 <Button
    //                                     type="submit"
    //                                     style={{ backgroundColor: 'white' }}
    //                                     size="medium"
    //                                     className={classes.btnBack}
    //                                     onClick={() => history.replace('/landing-page-kabupaten')}
    //                                 >
    //                                     Kembali ke Beranda
    //                         </Button>
    //                             </div>
    //                         </>
    //                     :
    //                     <>
    //                         <div className="diagnosa-mobile-navbar-004" >
    //                             <span className="diagnosa-mobile-text001" >
    //                                 Diagnosa Mandiri
    //                         </span>
    //                             <span className="diagnosa-mobile-text001" >
    //                                 Dengan Status
    //                         </span>
    //                         </div>
    //                         <div className="diagnosa-mobile-container-grey-002" >
    //                             <div className="diagnosa-mobile-container-grey-001">
    //                                 <span className="diagnosa-mobile-text002">
    //                                     Untuk Mendapatkan Hasil Diagnosis Akurat, Anda Diharuskan Mengisi Pertanyaan Berikut dengan Jawaban yang Jujur dan Rutin Mengisinya Selama 14 Hari
    //                             </span>
    //                             </div>
    //                         </div>
    //                         <div className="diagnosa-mobile-container-grey-007" >
    //                             <div className="diagnosa-mobile-text005">
    //                                 Hari Ke - {hariKe}
    //                             </div>
    //                             <div className="diagnosa-mobile-text006">
    //                                 Pertanyaan {countExercise[count]}
    //                             </div>
    //                         </div>

    //                         <div className="diagnosa-mobile-container-grey-007" >
    //                             <div className="pertanyaan-diagnosa">
    //                                 {
    //                                     exercises[count] &&
    //                                     exercises[count].question
    //                                 }
    //                             </div>
    //                             <div className="container-jawaban-diagnosa" >
    //                                 {
    //                                     exercises[count] &&
    //                                     exercises[count].answer &&
    //                                     exercises[count].answer.map((ans, i) => (
    //                                         <label className="jawaban-diagnosa" key={i}>
    //                                             <div style={{
    //                                                 display: "flex",
    //                                                 flexDirection: 'row'
    //                                             }}>
    //                                                 <input type="checkbox"
    //                                                     id={"checkBox" + i}
    //                                                     onChange={(e) => handleClick(ans.value)}
    //                                                 />
    //                                                 <span>
    //                                                     {ans.value}
    //                                                 </span>
    //                                             </div>
    //                                         </label>
    //                                     ))
    //                                 }
    //                                 <div className="btn-simpan-container" >
    //                                     <Button
    //                                         type="submit"
    //                                         style={{ backgroundColor: '#0185FF' }}
    //                                         size="medium"
    //                                         className={classes.btnSimpan}
    //                                         onClick={() => handleSimpan()}
    //                                     >
    //                                         Simpan
    //                                 </Button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </>
    //             }
    //         </div>
    //         :
    //         <div>
    //         </div>
    // )
}
