import React from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

function BoxContent () {
    const history = useHistory()
    return (
        <div className="box-content-dc-456-container">
            
            <div
                className={history.location.pathname === '/diagnosis-input' ? "bc-190-item" : "bc-190-item-disable" }
                onClick={()=> history.location.pathname !== '/diagnosis-input' ? history.push('/diagnosis-input') : '' }
            >
                Input Pertanyaan
            </div>

            <div
                style={{ width: 180 }}
                className={history.location.pathname === '/laporan-harian' ? "bc-190-item" : "bc-190-item-disable" }
                onClick={()=> history.location.pathname !== '/laporan-harian' ? history.push('/laporan-harian') : '' }
            >
                Lihat Laporan Harian
            </div>
            
        </div>
    )

}

export default BoxContent