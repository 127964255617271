import React from 'react'

function Paragraph10 () {

    return (
        <div className="dpc-word-content-254">
            <p style={{marginBottom : "0", marginTop : "0"}}>
                Belum ada. Hingga kini, belum ada vaksin dan obat melawan virus tertentu untuk mencegah atau mengobati COVID-19. 
                Namun, orang-orang yang sakit perlu mendapatkan perawatan untuk meredakan gejala-gejalanya. Orang-orang yang sakit serius harus dibawa ke rumah sakit. Sebagian besar pasien sembuh karena perawatan untuk gejala yang dialami. Ada beberapa kandidat vaksin dan obat tertentu yang masih diteliti melalui uji klinis. WHO mengkoordinasikan upaya menyediakan vaksin dan obat yang mencegah dan mengobati COVID-19.
            </p>
            <p style={{marginTop : "0", marginBottom : "0"}}>
                Cara paling efektif melindungi diri dan orang lain dari COVID-19 adalah sering mencuci tangan, menutup mulut saat batuk dengan siku yang terlipat atau tisu, dan menjaga jarak setidaknya 1 meter dari orang yang batuk-batuk atau bersin-bersin. (Lihat Langkah-langkah perlindungan dasar terhadap coronavirus baru).
            </p>
        </div>
    )

}

export default Paragraph10