import React, { Component } from 'react'
import {Map ,InfoWindow, Marker, GoogleApiWrapper, Circle} from 'google-maps-react';
import MarkOne from '../../images/icons/Pointer.png'
import MarkTwo from '../../images/icons/pointerWarning.png'
import axios from 'axios'
import SERVER from '../../endPoint'
import History from './history'
const geolib = require('geolib');

export class MapContainer extends Component {
    state = {
        showingInfoWindow : false,
        activeMarker : {},
        selectedPlace : {},
        clickMarker : false,
        allPosition : [],
        flag : true,
        flagger : true
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    mapClicked = (mapProps, map, clickEvent) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: true,
                activeMarker: null
            })
        }
    };

    isPointWithinRadius = ( point, centerPoint, radius, id ) =>{
        this.state.flagger = false
        let test = geolib.isPointWithinRadius(
            { latitude: point.lat, longitude: point.lng },
            { latitude: centerPoint.lat, longitude: centerPoint.lng },
            radius
        );
        if (!test && id) {
            console.log(test, id,'test')
            this.state.flag = false
            axios({
                method: 'POST',
                url: `${SERVER}/maps/${id}`,
                headers : {
                    token : localStorage.getItem('token_access')
                }
            })
            .then(data=>{
                this.state.flagger = true
            })
            .catch(err=>{
                this.state.flagger = true
                console.log(err)
            })
        } else {
            this.state.flagger = true
            this.state.flag = true
        }
    }

    componentDidMount = () => {
        if(localStorage.getItem('token_access')){
            axios({
                method: 'GET',
                url: `${SERVER}/patient/location`,
                headers : {
                    token : localStorage.getItem('token_access')
                }
            })
            .then(({ data })=>{
                data.map(position=>{
                    if (position.latitudeUpdate && position.mainAlamatLatitude) {
                        this.isPointWithinRadius({ lat: Number(position.mainAlamatLatitude), lng: Number(position.mainAlamatLongitude) }, { lat: Number(position.latitudeUpdate), lng: Number(position.longitudeUpdate) }, 50, position._id)
                    }
                })
                this.setState({
                    allPosition : data
                })
            })
            .catch(err=>{
                console.log(err.response)
            })
        }
    }

    render() {
        const google = window.google
        return (
            <Map
                id="map"
                google={this.props.google}
                zoom={11}
                maxZoom={17}
                // minZoom={5}
                containerStyle={{
                    width: '83.5%',
                    height: '91%'
                }}
                initialCenter={{ lat: -0.8306993, lng: 122.8698003 }}
                // onClick={this.mapClicked}
                visible={true}
            >
                {
                    this.state &&
                    this.state.allPosition &&
                    this.state.allPosition.map((position,i)=>{
                        return (
                            position.latitudeUpdate &&
                            this.state.flag ?
                            <Marker
                                key={i}
                                name={position.namadiKtp}
                                status={position.status}
                                subStatus={position.subStatus}
                                noHp={position.noHp}
                                onClick={this.onMarkerClick}
                                id={position._id}
                                position={{ lat: parseFloat(position.latitudeUpdate) , lng: parseFloat(position.longitudeUpdate) }}
                                icon={{
                                    url: MarkOne ,
                                    scaledSize: new google.maps.Size(30,45)
                                }}
                            />
                            :
                            <Marker
                                key={i}
                                name={position.namadiKtp}
                                status={position.status}
                                subStatus={position.subStatus}
                                noHp={position.noHp}
                                onClick={this.onMarkerClick}
                                id={position._id}
                                onClick={this.onMarkerClick}
                                position={{ lat: parseFloat(position.latitudeUpdate) , lng: parseFloat(position.longitudeUpdate) }}
                                icon={{
                                    url: MarkTwo ,
                                    scaledSize: new google.maps.Size(30,45)
                                }}
                            />
                        )
                    })
                }
                {
                    this.state &&
                    this.state.allPosition &&
                    this.state.allPosition.map((pos,i)=>{
                        return(
                            pos.mainAlamatLatitude &&
                            <Circle
                                key={i}
                                // name={'Your position'}
                                radius={50}
                                center={{ lat: Number(pos.mainAlamatLatitude), lng: Number(pos.mainAlamatLongitude) }}
                                // onMouseover={() => console.log('mouseover')}
                                // onMouseout={() => console.log('mouseout')}
                                // onClick={this.onMarkerClick}
                                strokeColor='transparent'
                                strokeOpacity={0}
                                strokeWeight={5}
                                fillColor='#FF0000'
                                fillOpacity={0.2}
                            />
                        )
                    })
                }

                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                        <div>
                            <div className="maps-container-text" >
                                <span className="text-maps" >
                                    Nama :
                                </span>
                                <span className="text-maps-bold" style={{ fontWeight: 'bold' }} >
                                    {' '+this.state.selectedPlace.name}
                                </span>
                            </div>
                            <div className="maps-container-text" >
                                <span className="text-maps" >
                                    Status :
                                </span>
                                <span className="text-maps-bold" style={{ fontWeight: 'bold' }} >
                                    {' '+this.state.selectedPlace.status}
                                </span>
                            </div>
                            <div className="maps-container-text" >
                                <span className="text-maps" >
                                    SubStatus :
                                </span>
                                <span className="text-maps-bold" style={{ fontWeight: 'bold' }} >
                                    {' '+this.state.selectedPlace.subStatus}
                                </span>
                            </div>
                            <div className="maps-container-text" >
                                <span className="text-maps" >
                                    No Telepon :
                                </span>
                                <span className="text-maps-bold" style={{ fontWeight: 'bold' }} >
                                    {' '+this.state.selectedPlace.noHp}
                                </span>
                            </div>
                        </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBQgfMPbNAEExq7_vbU0yAU8jKis7tgX_E")
})(MapContainer)