import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.scss'

function BoxContent () {

    const history = useHistory()
    const [id,setId] = useState(null)

    useEffect(()=>{
        const split = (history.location.pathname).split('/')
        if (split[2]) {
            setId(split[2])
        }
    },[history.location.pathname])

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    // useEffect(()=>{
    //     if (dataUser) {
    //         if (dataUser.role === 'petugas' ) {
    //             history.replace('/data-individu')
    //         }
    //     }
    // },[dataUser])

    return (
        <div className="box-content-id-190-container">
            {
                <>
                    <div
                        className={history.location.pathname === '/input-berita' ? "bc-190-item" : "bc-190-item-disable" }
                        onClick={()=> history.location.pathname !== '/input-berita' ? history.push('/input-berita') : '' }
                    >
                        Input Berita & Tips
                    </div>
                    
                    <div
                        className={history.location.pathname === '/view-berita' ? "bc-190-item-2" : "bc-190-item-2-disable" }
                        onClick={()=> history.location.pathname !== '/view-berita' && history.push('/view-berita') }
                    >
                        Sudah Terbit
                    </div>
                </>
                // :
                // <>
                //     <div
                //         className={history.location.pathname === '/view-berita/'+id ? "bc-190-item" : "bc-190-item-disable" }
                //         onClick={()=> history.location.pathname !== '/view-berita/'+id ? history.push('/view-berita/'+id) : '' }
                //     >
                //         Detail Individu
                //     </div>
                    
                //     <div
                //         className={"bc-190-item-2-disable"}
                //     >
                //         Riwayat Diagnosis Mandiri
                //     </div>
                // </>
            }

        </div>
    )

}

export default BoxContent