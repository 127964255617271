import React, { useEffect } from 'react'

// COMPONENT
import Header from './Header'
import Content1 from './Content1'
import Content2 from './Content2'

// STYLE
import './style.scss'

function LandingPageDesa ({ match }) {

    return (
        <div className="lp-desa-869">
            
            <Header/>

            {/* Data DESA per hari ini */}
            <Content1
                matchId={ match.params.id }
            />

            {/* Total Data DESA */}
            {/* <Content2/> */}

        </div>
    )

}

export default LandingPageDesa