import React , { useEffect , useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

// ENDPOINT
import SERVER from '../../endPoint/index'

// MATERIAL ICON
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// COMPONENT 
import BoxContent from '../IndividualData/BoxContent'

// STYLING
import './style.scss'

function RiwayatDiagnosa (props) {

    const history = useHistory()
    const [patient, setPatient] = useState([])
    const [countExercise] = useState(
        [
            "",
            "Pertama",
            "Kedua",
            "Ketiga",
            "Keempat",
            "Kelima",
            "Keenam",
            "Ketujuh",
            "Kedelapan",
            "Kesembilan",
            "Kesepuluh",
            "Kesebelas",
            "Keduabelas",
            "Ketigabelas",
            "Keempatbelas",
            "Ketujuhbelas",
            "Kedelapanbelas",
            "Kesembilanbelas",
            "Keduapuluh",
            "Keduapuluhsatu",
            "Keduapuluhdua",
            "Keduapuluhtiga",
            "Keduapuluhduaempat",
            "Keduapuluhdualima",
        ]
    )

    useEffect(()=>{
        console.log()
        axios({
            method : "GET",
            headers : {
                token : localStorage.getItem('token_access')
            },
            url : `${SERVER}/patient/${props.match.params.id}`
        })
        .then(({data})=>{
            let temp = []
            for(let i in data.patientId){
                if (((i).toLocaleLowerCase()).includes(('dateDay').toLowerCase())) {
                    temp.push(data.patientId[i])
                }
            }
            setPatient(temp)
        })
        .catch(err=>{
            console.log(err , ' <<<')
        })
    },[])

    const [dataDummy,setDataDummy] = useState([
        {
            hariKe : 1,
            list : [
                {
                    pertanyaan : "Apakah Anda Merasa Pusing",
                    jawaban : "Iya"
                },
                {
                    pertanyaan : "Apakah Anda Masih Batuk",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Apakah Pernapasan Anda Sesak",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Makanan Apa Yang Anda Makan Hari ini",
                    jawaban : "sayur-sayuran"
                },
                {
                    pertanyaan : "Bagaimana Kah peran kedua orang tua Anda",
                    jawaban : "Sangat Baik"
                },
                {
                    pertanyaan : "Dimana Anda terakhir kali makan?",
                    jawaban : "Selain di rumah"
                },
                {
                    pertanyaan : "Apakah Anda masih sering Keluar",
                    jawaban : "Jarang"
                }
            ]
        },
        {
            hariKe : 2,
            list : [
                {
                    pertanyaan : "Apakah Anda Merasa Pusing",
                    jawaban : "Iya"
                },
                {
                    pertanyaan : "Apakah Anda Masih Batuk",
                    jawaban : "Iya"
                },
                {
                    pertanyaan : "Apakah Pernapasan Anda Sesak",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Makanan Apa Yang Anda Makan Hari ini",
                    jawaban : "sayur-sayuran"
                },
                {
                    pertanyaan : "Bagaimana Kah peran kedua orang tua Anda",
                    jawaban : "Sangat Baik"
                },
                {
                    pertanyaan : "Dimana Anda terakhir kali makan?",
                    jawaban : "Selain di rumah"
                },
                {
                    pertanyaan : "Apakah Anda masih sering Keluar",
                    jawaban : "Jarang"
                }
            ]
        },
        {
            hariKe : 3,
            list : [
                {
                    pertanyaan : "Apakah Anda Merasa Pusing",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Apakah Anda Masih Batuk",
                    jawaban : "Iya"
                },
                {
                    pertanyaan : "Apakah Pernapasan Anda Sesak",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Makanan Apa Yang Anda Makan Hari ini",
                    jawaban : "sayur-sayuran"
                },
                {
                    pertanyaan : "Bagaimana Kah peran kedua orang tua Anda",
                    jawaban : "Sangat Baik"
                },
                {
                    pertanyaan : "Dimana Anda terakhir kali makan?",
                    jawaban : "Selain di rumah"
                },
                {
                    pertanyaan : "Apakah Anda masih sering Keluar",
                    jawaban : "Jarang"
                }
            ]
        },
        {
            hariKe : 4,
            list : [
                {
                    pertanyaan : "Apakah Anda Merasa Pusing",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Apakah Anda Masih Batuk",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Apakah Pernapasan Anda Sesak",
                    jawaban : "Tidak"
                },
                {
                    pertanyaan : "Makanan Apa Yang Anda Makan Hari ini",
                    jawaban : "sayur-sayuran"
                },
                {
                    pertanyaan : "Bagaimana Kah peran kedua orang tua Anda",
                    jawaban : "Sangat Baik"
                },
                {
                    pertanyaan : "Dimana Anda terakhir kali makan?",
                    jawaban : "Selain di rumah"
                },
                {
                    pertanyaan : "Apakah Anda masih sering Keluar",
                    jawaban : "Jarang"
                }
            ]
        }
    ])

    const showDate = (dateParams) => {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = 'hehe'
                break;
        }
        return date + ' ' + month  + ' ' + year
    }

    return (
        <div className="riwayat-diagnosa-container">
            <div className="back-container" >
                <ArrowBackIosIcon className="back-icon" onClick={()=>history.goBack()}/>
                <span className="back-text" onClick={()=>history.push('/data-individu-list')}>
                    Kembali
                </span>
            </div>
            <BoxContent/>

            <div className="rd-765-content">
                { console.log(patient) }
                {
                    patient &&
                    patient
                    .sort((a,b)=> new Date(a.date) - new Date(b.date) )
                    .map((el,index)=>{
                       return( <div className="rd-765-per-content">
                            <div className="rd-765-first-content" style={{ backgroundColor: el.diagnosa ? '#3c9640' : "#b82424", color: "white", fontWeight: 'bold', fontSize: 15 }}>
                                HARI KE - {index+=1}
                                <div style={{ marginLeft: 'auto', marginRight: 15 }} >
                                    {showDate(el.date)}
                                </div>
                            </div>
                            <div className="rd-765-second-content">
                                <div
                                    className="rd-765-second-content-for-container" 
                                    style={
                                        {
                                            marginTop : index !== 0 ? "0px" : null
                                        }
                                    }
                                >
                                    {
                                        el.exercise ?
                                        el.exercise.map((exer,index)=>(
                                            <div style={{ marginBottom: 10 }} >
                                                <div className="rd-765-second-content-question" style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    {'Pertanyaan ' + countExercise[index+1] }
                                                </div>
                                                <div className="rd-765-second-content-question" style={{ fontSize: 12, fontWeight: 'normal' }}>
                                                    {exer.pertanyaan}
                                                </div>
                                                {
                                                    exer.jawaban.map(exel=>(
                                                        <ul className="rd-765-second-content-answer" style={{ marginLeft: -10 }}>
                                                            <li>{exel}</li>
                                                        </ul>  
                                                    ))
                                                }
                                            </div>
                                        ))
                                        :
                                        new Date() > new Date(el.date) ?
                                        <div >
                                            Diagnosa Tidak Diisi
                                        </div>
                                        :
                                        <div style={{ display: 'flex', justifyContent: 'center', height: "100%", fontFamily: "Poppins", color: "#444444" }}>
                                            Diagnosa Belum Diisi
                                        </div>
                                    }
                                </div>
                            </div>
                        </div> )
                    })
                }
     

            </div>

        </div>
    )

}

export default RiwayatDiagnosa