import React, {useState, useEffect} from 'react'
import {  useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert2'

// COMPONENT
import TitleContent from '../TitleContent'
import MainContent from '../MainContent'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

// STYLE
import './style.scss'
import axios from 'axios'
import SERVER from '../../../../endPoint'

function LeftContent (props) {
    const [editStatus, setEditStatus] = useState(false)
    const [editSubStatus, setEditSubStatus] = useState(false)
    const [editIsolasi, setEditIsolasi] = useState(false)
    const [isolasi] = useState(["Ya", 'Tidak'])
    const [isolasiValue,setIsolasiValue] = useState(null)
    const [statusValue, setStatusValue] = useState(null)
    const [subStatusValue, setSubStatusValue] = useState(null)
    const [status] = useState([
        "Konfirmasi Positif",
        "Pasien Dalam Pemantauan (PDP)",
        "Orang Dalam Pemantauan(ODP)",
        "Orang Tanpa Gejala (OTG)",
        "Pelaku Perjalanan (ODR)",
    ])
    const [subStatus, setSubStatus] = useState([])

    useEffect(()=>{
        setEditSubStatus(false)
    },[editStatus===true])

    useEffect(()=>{
        swal.showLoading()
        if(statusValue === "Konfirmasi Positif"){
            setEditSubStatus(true)
            setSubStatus([
                "Dalam Perawatan",
                "Di Ruang Isolasi",
                "Sembuh",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Pasien Dalam Pemantauan (PDP)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sembuh",
                "Negatif",
                "Bukan Covid 19",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Orang Dalam Pemantauan(ODP)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Naik Status PDP",
                "Sembuh",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Orang Tanpa Gejala (OTG)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
            swal.close()
        } else if (statusValue === "Pelaku Perjalanan (ODR)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
            swal.close()
        } else {
            setEditSubStatus(false)
            setSubStatus([])
            swal.close()
        }
    },[statusValue])

    const HandleSubsEdit = (statusValue) => {
        swal.showLoading()
        if(statusValue === "Konfirmasi Positif"){
            setEditSubStatus(true)
            setSubStatus([
                "Dalam Perawatan",
                "Di Ruang Isolasi",
                "Sembuh",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Pasien Dalam Pemantauan (PDP)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sembuh",
                "Negatif",
                "Bukan Covid 19",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Orang Dalam Pemantauan(ODP)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Naik Status PDP",
                "Sembuh",
                "Meninggal"
            ])
            swal.close()
        } else if (statusValue === "Orang Tanpa Gejala (OTG)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
            swal.close()
        } else if (statusValue === "Pelaku Perjalanan (ODR)"){
            setEditSubStatus(true)
            setSubStatus([
                "Sedang Dipantau",
                "Selesai",
            ])
            swal.close()
        } else {
            setEditSubStatus(false)
            setSubStatus([])
            swal.close()
        }
    }

    const SaveSubStatus = () =>{
        if (!subStatusValue) {
            swal.fire({
                html: "Silahkan Pilih Sub Status",
                icon: 'info',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "PATCH",
                url : `${SERVER}/patient/substatus/${props.ID}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data: { subStatus : subStatusValue }
            })
            .then(_=>{
                swal.close()
                props.refreshData(true)
                setEditStatus(false)
                setEditSubStatus(false)
            })
            .catch(err=>{
                swal.close()
                console.log(err)
            })
        }
    }

    const history = useHistory()

    // GLOBAL STATE
    const dataUser = useSelector(state=>state.UserReducer.dataUser)

    useEffect(()=>{
        if (dataUser) {
            if (dataUser.role === 'petugas' ) {
                history.replace('/data-individu')
            }
        }
    },[dataUser])

    const SaveIsolasiStatus = () => {
        if (!isolasiValue) {
            swal.fire({
                html: "Silahkan Pilih Status Isolasi Status",
                icon: 'info',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "PATCH",
                url : `${SERVER}/patient/${props.ID}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data: { status : isolasiValue, statusUtama : props.patient.status, subStatusUtama : props.patient.subStatus  }
            })
            .then(({data})=>{
                swal.close()
                props.refreshData(true)
                setEditIsolasi(false)
            })
            .catch(err=>{
                swal.close()
                console.log(err)
            })
        }
    }

    const SaveStatus = () => {
        if (!statusValue || !subStatusValue) {
            swal.fire({
                html: "Silahkan Pilih Status Dan Sub Status",
                icon: 'info',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        } else {
            swal.showLoading()
            axios({
                method : "PATCH",
                url : `${SERVER}/patient/status/${props.ID}`,
                headers : {
                    token : localStorage.getItem('token_access')
                },
                data: { status : statusValue, subStatus : subStatusValue }
            })
            .then(({data})=>{
                swal.close()
                props.refreshData(true)
                setEditStatus(false)
                setEditSubStatus(false)
            })
            .catch(err=>{
                swal.close()
                console.log(err)
            })
        }
    }

    const handleEditStatus = () => {
        if(editStatus) setEditSubStatus(false)
        !editStatus ? setEditStatus(true) : setEditStatus(false)
    }

    const handleEditIsolasi = (flag) => {
        if (flag) {
            !editIsolasi ? setEditIsolasi(true) : setEditIsolasi(false)
        } else {
            swal.fire({
                html: "Silahkan Pilih Status Dan Sub Status Terlebih Dahulu",
                icon: 'info',
                confirmButtonText: 'OK',
                confirmButtonColor: '#0280A2',
            })
        }
    }

    return (
        <div className="di-879-per-item-left">
            
            <TitleContent
                title={"Riwayat Pribadi"}
            />

            <MainContent
                title={"Nama Sesuai KTP"}
                value={props.patient.namadiKtp ? props.patient.namadiKtp : '-'}
                // value={"Sultan Rayhan Habib"}
            />

            <MainContent
                title={"Nama Panggilan"}
                value={props.patient.namaPanggilan ? props.patient.namaPanggilan : '-'}
            /> 

            <div className="isolasi-mandiri-container-edit-status">
                <div className="input-style-980">
                    {
                        !editStatus ?
                        <div className="di-main-content-879-container" style={{width : "100%"}}>
                            <div 
                                className="di-main-content-879-content"
                                style={{
                                    borderBottom : "none",
                                    width : "100%"
                                }}
                            >
                                <div className="di-m-title-879" >
                                    Status
                                </div>
                                <div className={"di-m-value-881"}>
                                    { props.patient.status ? props.patient.status : "Belum Ada Status" }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="itt-title-container">
                            <div className="itt-title-679">
                                Status
                            </div>
                            <select
                                className="itt-input-679-edit-status"
                                onChange={e=> setStatusValue(e.target.value)}
                            >
                                <option disabled selected>{props.patient.status ? props.patient.status : "Pilih Status"}</option>
                                {
                                    status.map((status,index)=>(
                                        status !== props.patient.status &&
                                        <option key={index} value={status} >{status}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                </div>
                {
                    !editStatus ?
                    <div className="left-btn-isoma" onClick={()=>dataUser.role !== 'master'? ""  : handleEditStatus()}>
                        Edit
                    </div>
                    :
                    <>
                        <div className="left-btn-isoma-2" onClick={()=>dataUser.role !== 'master'? ""  : handleEditStatus()}>
                            <CancelIcon/>
                        </div>
                        <div className="left-btn-isoma-1" onClick={()=>{SaveStatus()}}>
                            <SaveIcon />
                        </div>
                    </>
                }
            </div>

            <div className="isolasi-mandiri-container-edit-status">
                <div className="input-style-980">
                    {
                        !editSubStatus ?
                        <div className="di-main-content-879-container" style={{width : "100%"}}>
                            <div 
                                className="di-main-content-879-content"
                                style={{
                                    borderBottom : "none",
                                    width : "100%",
                                    // backgroundColor : "pink"
                                }}
                            >
                                <div style={{width : "40%"}} className="di-m-title-879">
                                    Sub Status
                                </div>
                                <div 
                                    className={"di-m-value-881"} 
                                    style={{width : "60%"}}
                                    // className={"di-m-value-879"} 
                                    // className={"di-m-value-879"}
                                    // className="di-m-value-880"
                                    // style={{width : "90%" , marginLeft : "36%"}}
                                    // style={{ marginLeft: !props.patient.subStatus ? 40 : '' }} 
                                >
                                    { props.patient.subStatus ? props.patient.subStatus : "Pilih Status Untuk Memilih Sub Status" }
                                </div>
                                <div></div>
                            </div>
                        </div>
                        :
                        <div className="itt-title-container">
                            <div className="itt-title-679">
                                Sub Status
                            </div>
                            <select
                                className="itt-input-678"
                                onChange={e=> setSubStatusValue(e.target.value)}
                            >
                                {
                                    props.patient &&
                                    !props.patient.subStatus &&
                                    <option selected>Pilih Sub Status</option>
                                }
                                {
                                    subStatus.map((status,index)=>(
                                        <>
                                            {
                                                index === 0 &&
                                                <option value={null} selected>Pilih Sub Status</option>
                                            }
                                            <option key={index} value={status} >{status}</option>
                                        </>
                                    ))
                                }
                            </select>
                        </div>
                    }
                </div>
                {
                    props.patient.subStatus ?
                    !editSubStatus ?
                    <div style={{ width: editStatus && 100 , opacity: editStatus && 0, cursor: editStatus && "default" }} className="left-btn-isoma" onClick={()=>dataUser.role !== 'master'? ""  : HandleSubsEdit(statusValue ? statusValue : props.patient.status)}>
                        Edit
                    </div>
                    :
                    <>
                        <div className="left-btn-isoma-2" style={{ width: editStatus && 40 , opacity: editStatus && 0, cursor: editStatus && "default" }} onClick={()=>dataUser.role !== 'master'? ""  : HandleSubsEdit(false)}>
                            <CancelIcon/>
                        </div>
                        <div className="left-btn-isoma-1" style={{ width: editStatus && 40 , opacity: editStatus && 0, cursor: editStatus && "default" }} onClick={()=>{SaveSubStatus()}}>
                            <SaveIcon />
                        </div>
                    </>
                    :
                    <div className="left-btn-isoma" style={{ width: 50 ,opacity: 0, cursor: "default" }}>
                        Edit
                    </div>
                }
            </div>

            <div className="isolasi-mandiri-container-edit-status">
                <div 
                    // className={props.patient.isolasiMandiri === "Tidak" ? "input-style-980" : "input-style-981"}
                    className="input-style-980"
                >
                    {
                        !editIsolasi ?
                        <div className="di-main-content-879-container" style={{width : "100%"}}>
                            <div 
                                className="di-main-content-879-content"
                                style={{
                                    borderBottom : "none",
                                    width : "100%"
                                }}
                            >
                                <div className="di-m-title-879"  >
                                    Isolasi Mandiri
                                </div>
                                <div 
                                    // className={props.patient.isolasiMandiri ? props.patient.isolasiMandiri === 'Tidak' ? "di-m-value-881" : "di-m-value-880" : "di-m-value-882" }
                                    className="di-m-value-881"
                                >
                                    { props.patient.isolasiMandiri ? props.patient.isolasiMandiri : "Belum Ada Status" }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="itt-title-container">
                            <div className="itt-title-679">
                                Isolasi Mandiri
                            </div>
                            <select
                                className="itt-input-680"
                                onChange={e=>setIsolasiValue(e.target.value)}
                            >
                                <option disabled selected>{props.patient.isolasiMandiri ? props.patient.isolasiMandiri : "Pilih Status"}</option>
                                {
                                    isolasi.map(status=>(
                                        status !== props.patient.isolasiMandiri &&
                                        <option value={status} >{status}</option>
                                    ))
                                }
                            </select>
                        </div>
                    }
                </div>
                {
                    !editIsolasi ?
                    (props.patient.isolasiMandiri === "Tidak" || !props.patient.isolasiMandiri) &&
                    <div className="left-btn-isoma" onClick={()=> (!props.patient.status || !props.patient.subStatus) ? handleEditIsolasi(false) : handleEditIsolasi(true) }>
                        Edit Status
                    </div>
                    :
                    <>
                        <div className="left-btn-isoma-3" onClick={()=>{handleEditIsolasi()}}>
                            <CancelIcon/>
                        </div>
                        <div className="left-btn-isoma-1" onClick={()=>{SaveIsolasiStatus()}}>
                            <SaveIcon />
                        </div>
                    </>
                }
            </div>

            <MainContent
                title={"No. HP"}
                value={props.patient.noHp ? props.patient.noHp : '-'}
            />  
            
            <MainContent
                title={"NIK/No. Passport"}
                value={props.patient.NIKDanPassport ? props.patient.NIKDanPassport : '-'}
            />  

            <MainContent
                title={"Usia"}
                value={props.patient.usia ? props.patient.usia : '-'}
            />

            <MainContent
                title={"Jenis Kelamin"}
                value={props.patient.jenisKelamin ? props.patient.jenisKelamin : '-'}
            />

            <MainContent
                title={"Alamat sesuai KTP"}
                value={props.patient.alamatDiKtp ? props.patient.alamatDiKtp : '-'}
            />

            <MainContent
                title={"Alamat Saat ini"}
                value={props.patient.alamatSaatIni ? props.patient.alamatSaatIni : '-'}
            />

            <MainContent
                title={"Propinsi"}
                value={props.patient.propinsi ? props.patient.propinsi : '-'}
            />

            <MainContent
                title={"Kabupaten/Kota"}
                value={props.patient.kabupaten ? props.patient.kabupaten : '-'}
            />

            <MainContent
                title={"Kecamatan"}
                value={props.patient.kecamatan ? props.patient.kecamatan : '-'}
            />

            <MainContent
                title={"Kelurahan/Desa"}
                value={props.patient.kelurahan ? props.patient.kelurahan : '-'}
            />

            <MainContent
                title={"Pekerjaan"}
                value={props.patient.pekerjaan ? props.patient.pekerjaan : '-'}
                lastContent={true}
            />


        </div>
    )

}

export default LeftContent