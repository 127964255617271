import React from "react";
import { useState, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useTheme } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from '@material-ui/icons/Settings';
import ContactsIcon from '@material-ui/icons/Contacts';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import HistoryIcon from '@material-ui/icons/History';
// import Healthy from '../../images/'
import logo from '../../images/kab-banggai-logo.png'
import PelanggarZona from '../../images/icons/PelanggarZona.svg'
import PelanggarZonaBlue from '../../images/icons/pelanggarZonaBlue.svg'
import absoluteAct from '../../images/data_absolute_act.svg'
import absoluteNon from '../../images/data_absolute_non.svg'

// STYLING
import "../../fonts/style.css";
import useStyles from './style'
import './style.scss'

// COMPONENTS
import AddDataAbsolute from '../../components/DataAbsolute/Add'
import Navbar from '../../components/navbar/index'
import GeneralSettings from '../../components/GeneralSetting'
import IndividualData from '../../components/IndividualData'
import Maps from '../../components/maps'
import Beranda from '../../components/beranda'
import DataAbsolute from '../../components/DataAbsolute'
import DesaKelurahan from '../../components/desaKelurahan'
import Kecamatan from '../../components/kecamatan'
import DetailIndividu from '../../components/IndividualData/DetailIndividu'
import DiagnosisComponent from '../../components/DiagnosisComponent'
import PelanggarZonaComponent from '../../components/PelanggarZona'
import InputPostLapangan from '../../components/InputPostLapangan'
import Berita from '../../components/InputBerita'
import ViewBerita from '../../components/ViewsBerita'
import editBerita from '../../components/editBerita'
import RiwayatDiagnosa from '../../components/RiwayatDiagnosa'
import CreateNewHistory from '../../components/CreateNewHistory'
import { SET_RESET_INDIVIDUAL_ACTION } from '../../store/actions/individual'

import axios from 'axios'
import SERVER from '../../endPoint'

const Home = (props) => {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [detail, setDetail] = useState(false)
    const [dataRouter, setDataRouter] = useState(['/buat-undangan', '/lihat-daftar-undangan', '/pengaturan-umum', '/data-individu', '/diagnosis-input', '/laporan-harian', '/input-post-lapangan'])

    const [isActive, setIsActive] = useState('1')
    const [roleUser, setRoleUser] = useState('petugas')

    const history = useHistory()
    const dispatch = useDispatch()
    const dataUser = useSelector(state => state.UserReducer.dataUser)

    const isMobile = useMediaQuery({ maxWidth: 768 })

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (history.location.pathname == '/') {
            axios({
                method: "GET",
                headers: {
                    token: localStorage.getItem('token_access')
                },
                url: `${SERVER}/user/setting`
            })
                .then(({ data }) => {
                    if (data.role === 'master' || data.role === 'admin' || data.profile.role === 'super-master') {
                        history.replace('/beranda')
                    } else if (data.role === 'petugas') {
                        history.replace('/data-individu')
                    } else if (data.role === 'admin-berita') {
                        history.replace('/input-berita')
                    } else {
                        history.replace('/landing-page-kabupaten')
                    }
                })
                .catch(err => {
                    console.log(err, ' << ERROR')
                })
        }
    }, [history.location.pathname])

    useEffect(() => {
        console.log(dataUser, ' <<<< VALUE DATA USER')
        if (dataUser) {
            setRoleUser(dataUser.role)
        }
    }, [dataUser])

    const handleClick = (id) => {
        // history.replace(`${id}`)
        history.push(`${id}`)
        dispatch(SET_RESET_INDIVIDUAL_ACTION())
        setIsActive(id)
    }

    const [locationKeys, setLocationKeys] = useState([])

    const Handlelogout = () => {
        localStorage.clear()
        history.replace('/home')
        console.clear()
        // dispatch(logout())
    }

    const cekPathname = () => {
        if (history.location.pathname === dataRouter[1] || detail) {
            return true
        }
    }

    // ********************************************************************************************************************************

    useEffect(() => {
        if (!localStorage.getItem('token_access')) {
            history.replace('/landing-page-kabupaten')
        }
    }, [])

    // ********************************************************************************************************************************


    const drawer = (
        <div >
            <div className={classes.toolbar} />

            <div className={classes.imgContainer}>
                {/* <img src={logoSgu} className={classes.logosgu} alt="img" /> */}
                <div className="logo-acaraku-img-1">
                    <img className="logo-styling-87" src={logo} style={{ width: 150, cursor: "pointer" }} alt="logo" />
                </div>
            </div>

            <div className="container-for-sidebar-title-48">
                <div>
                    Pemerintah Kabupaten Banggai
                </div>
                <div>
                    Gugus Tugas Covid 19
                </div>

            </div>

            <List>
                {
                    // itsMe && itsMe.eventType != "Single" &&
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: (history.location.pathname).includes('/beranda') ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => [history.push('/beranda'), dispatch(SET_RESET_INDIVIDUAL_ACTION())]}
                        >
                            <ListItemIcon>
                                <DashboardIcon style={{ color: (history.location.pathname).includes('/beranda') ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: (history.location.pathname).includes('/beranda') ? '#0185ff' : "#888888" }}
                            >
                                Beranda
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor:  (history.location.pathname).includes('/data-absolute') ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => handleClick('/data-absolute')}
                        >
                            <ListItemIcon>
                                {
                                    (history.location.pathname).includes('/data-absolute') ?
                                        <img src={absoluteAct} style={{ marginLeft: "9px", marginRight: "8px", height: 21 }} alt="absolute" />
                                        :
                                        <img src={absoluteNon} style={{ marginLeft: "9px", marginRight: "8px", height: 21 }} alt="absolute" />
                                }
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color:  (history.location.pathname).includes('/data-absolute') ? '#0185ff' : "#888888" }}
                            >
                                Data Absolute
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === '/lacak' ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => handleClick('/lacak')}
                        >
                            <ListItemIcon>
                                <MyLocationIcon style={{ color: history.location.pathname === '/lacak' ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === '/lacak' ? '#0185ff' : "#888888" }}
                            >
                                Lacak
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === '/pelanggar-zona' ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => handleClick('/pelanggar-zona')}
                        >
                            <ListItemIcon>
                                {
                                    history.location.pathname === '/pelanggar-zona' ?
                                        <img src={PelanggarZonaBlue} style={{ marginLeft: "9px", marginRight: "8px", height: 21 }} alt="langgarZona" />
                                        :
                                        <img src={PelanggarZona} style={{ marginLeft: "9px", marginRight: "8px", height: 21 }} alt="langgarZona" />
                                }
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === '/pelanggar-zona' ? '#0185ff' : "#888888" }}
                            >
                                Pelanggar Zona
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === dataRouter[2] ? '#eff7ff' : '',
                                    // borderLeft : history.location.pathname === dataRouter[2] ?"4px solid #0185ff" : ""
                                }
                            }
                            onClick={() => [handleClick(dataRouter[2])]}
                        >
                            <ListItemIcon>
                                <SettingsIcon style={{ color: history.location.pathname === dataRouter[2] ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === dataRouter[2] ? '#0185ff' : "#888888" }}
                            >
                                Pengaturan Umum
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === dataRouter[3] || (history.location.pathname).includes('/data-individu-list') ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => [handleClick(dataRouter[3])]}
                        >
                            <ListItemIcon>
                                <PersonAddIcon style={{ color: history.location.pathname === dataRouter[3] || (history.location.pathname).includes('/data-individu-list') ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === dataRouter[3] || (history.location.pathname).includes('/data-individu-list') ? '#0185ff' : "#888888" }}
                            >
                                Data Individu
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === dataRouter[4] || history.location.pathname === dataRouter[5] ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => [handleClick(dataRouter[4])]}
                        >
                            <ListItemIcon>
                                <AssignmentIcon
                                    style={{ color: history.location.pathname === dataRouter[4] || history.location.pathname === dataRouter[5] ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === dataRouter[4] || history.location.pathname === dataRouter[5] ? '#0185ff' : "#888888" }}
                            >
                                Diagnosa Mandiri
                        </p>
                        </ListItem>
                        :
                        <></>
                }

                {
                    roleUser !== 'petugas' && roleUser !== 'admin-berita' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === dataRouter[6] ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => [handleClick(dataRouter[6])]}
                        >
                            <ListItemIcon>
                                <ViewHeadlineIcon
                                    style={{ color: history.location.pathname === dataRouter[6] ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{ color: history.location.pathname === dataRouter[6] ? '#0185ff' : "#888888" }}
                            >
                                Input Post Lapangan
                        </p>
                        </ListItem>
                        :
                        <></>
                }
                {
                    roleUser == 'super-master' &&
                    <ListItem
                        button
                        className={classes.sidebar}
                        style={
                            {
                                backgroundColor: history.location.pathname === '/history' ? '#eff7ff' : '',
                            }
                        }
                        onClick={() => [handleClick('/history')]}
                    >
                        <ListItemIcon>
                            <HistoryIcon
                                style={{ color: history.location.pathname === '/history' ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px" }} />
                        </ListItemIcon>
                        <p
                            className={classes.sidebarText}
                            style={{ color: history.location.pathname === '/history' ? '#0185ff' : "#888888" }}
                        >
                            Buat Data History Harian
                        </p>
                    </ListItem>
                }
                {
                    roleUser == 'master' || roleUser == 'admin-berita' || roleUser == 'super-master' ?
                        <ListItem
                            button
                            className={classes.sidebar}
                            style={
                                {
                                    backgroundColor: history.location.pathname === '/input-berita' ||
                                        (history.location.pathname).includes("/edit-berita") ||
                                        history.location.pathname === '/view-berita' ? '#eff7ff' : '',
                                }
                            }
                            onClick={() => [handleClick('/input-berita')]}
                        >
                            <ListItemIcon>
                                <i className="fas fa-hand-holding-heart"
                                    style={{
                                        fontSize: 20, marginTop: -5, color: history.location.pathname === '/input-berita' ||
                                            (history.location.pathname).includes("/edit-berita") ||
                                            history.location.pathname === '/view-berita' ? "#0185ff" : "", marginLeft: "5px", marginRight: "8px"
                                    }}
                                />
                            </ListItemIcon>
                            <p
                                className={classes.sidebarText}
                                style={{
                                    color: history.location.pathname === '/input-berita' ||
                                        (history.location.pathname).includes("/edit-berita") ||
                                        history.location.pathname === '/view-berita'
                                        ? '#0185ff' : "#888888"
                                }}
                            >
                                Input Berita & Tips
                        </p>
                        </ListItem>
                        :
                        <></>
                }

            </List>
        </div>
    );

    return (
        <>
            {
                !isMobile ?
                    <div
                        className={classes.root}
                    >
                        <CssBaseline />
                        <AppBar position="fixed" className={classes.appBar} style={{ boxShadow: 'none' }}>
                            <Navbar />
                        </AppBar>
                        <nav className={classes.drawer} aria-label="mailbox folders" >
                            <Hidden smUp implementation="css">
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    anchor={theme.direction === "rtl" ? "right" : "left"}
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper
                                    }}
                                    ModalProps={{
                                        keepMounted: true // Better open performance on mobile.
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                            <Hidden xsDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper
                                    }}
                                    variant="permanent"
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Hidden>
                        </nav>
                        <main className={history.location.pathname == '/lacak' ? classes.contentMap : classes.content}>
                            <div id="parent-content-sidebar" className={classes.toolbar} />
                            <Switch>
                                <Route path="/history" component={CreateNewHistory} />
                                <Route path="/beranda/kecamatan/:id" component={DesaKelurahan} />
                                <Route path="/beranda/kecamatan" component={Kecamatan} />
                                <Route path="/beranda" component={Beranda} />
                                <Route path="/data-absolute/add" component={AddDataAbsolute} />
                                <Route path="/data-absolute" component={DataAbsolute} />
                                <Route path="/lacak" component={Maps} />
                                <Route path="/pelanggar-zona" component={PelanggarZonaComponent} />
                                <Route path="/pengaturan-umum" exact component={GeneralSettings} />
                                <Route path="/data-individu-list/:id" component={DetailIndividu} />
                                <Route path="/data-individu-list" exact component={IndividualData} />
                                <Route path="/data-individu" component={IndividualData} />
                                <Route path="/diagnosis-input" component={DiagnosisComponent} />
                                <Route path="/laporan-harian" component={DiagnosisComponent} />
                                <Route path="/input-post-lapangan" component={InputPostLapangan} />
                                <Route path="/input-berita" component={Berita} />
                                <Route path="/view-berita" component={ViewBerita} />
                                <Route path="/edit-berita/:id" component={editBerita} />
                                <Route path="/riwayat-diagnosa/:id" component={RiwayatDiagnosa} />
                            </Switch>
                        </main>
                    </div>
                    :
                    <Container maxWidth="xl" style={{ padding: 0, backgroundColor: 'white', height: '100vh', paddingLeft: '5%', overflow: 'hidden' }}>
                        <center>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
                                {/* <img src={screenOnly} style={{paddingTop: 0}} alt="404" className="screenOnly-style"/> */}
                                <div style={{ color: '#0185ff', fontFamily: 'SFUIDisplay' }} className="text-back-home">
                                    <span>  Access Screen Only </span>
                                </div>
                                <div style={{ marginTop: '-8%' }}>
                                    <Button onClick={() => { Handlelogout() }} className="btn-login-back-home">Kembali Ke Halaman Login</Button>
                                </div>
                            </div>
                        </center>
                    </Container>
            }
        </>
    );
}

Home.propTypes = {
    container: PropTypes.any
};

export default Home