import React from 'react'

// IMAGE 
import optimus1 from '../../../../images/landing-page-utama/optimus-logo.svg'
import optimus2 from '../../../../images/landing-page-utama/logo-2-optimus.svg'
import BanggaiLogo from '../../../../images/kab-banggai-logo.png'


import './style.scss'

function LeftContent (props) {   
    
    return (
        <div className="mlp-345-fc-lc-container" >

            <div className="fclc-one-345">
                <img className="optimus-logo-1-345" src={optimus1}/>
                <img className="optimus-logo-2-345" src={optimus2}/>
            </div>

            <div className="fclc-two-345">
                <div className="fclc-two-345-">
                    Pantau Covid 19
                </div>
                <div className="fclc-two-345-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </div>
            </div>


            <div className="fclc-three-345">
                Lihat Fitur
            </div>

            <div className="fclc-four-345">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </div>

            <div className="fclc-five-345">
                Mitra Kami   
            </div>

            <div className="fclc-six-345">

                <div className="fclc-six-percontent-345" style={{marginLeft : "0"}}>

                    <div className="fclc-bundar-345">
                        <img
                            className="fclc-logo-kota-345"
                            src={BanggaiLogo}
                        />
                    </div>

                    <div className="fclc-logo-kota-title-345">
                        Pemerintah Kabupaten Banggai
                    </div>

                    <div className="fclc-logo-kota-button-345 {">
                        Lihat di Sini
                    </div>

                </div>

                <div className="fclc-six-percontent-345" >
                    
                    <div className="fclc-bundar-345">
                        <img
                            className="fclc-logo-kota-345"
                            src={BanggaiLogo}
                        />
                    </div>

                    <div className="fclc-logo-kota-title-345">
                        Pemerintah Kabupaten Banggai
                    </div>

                    <div className="fclc-logo-kota-button-345 {">
                        Lihat di Sini
                    </div>

                </div>

                <div className="fclc-six-percontent-345" >
                    
                    <div className="fclc-bundar-345">
                        <img
                            className="fclc-logo-kota-345"
                            src={BanggaiLogo}
                        />
                    </div>

                    <div className="fclc-logo-kota-title-345">
                        Pemerintah Kabupaten Banggai
                    </div>

                    <div className="fclc-logo-kota-button-345 {">
                        Lihat di Sini
                    </div>

                </div>


            </div>

        </div>

    )

}

export default LeftContent