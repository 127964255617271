import {
    USER_LOGIN,
    USER_LOGOUT,
    SET_DATA_USER
} from '../constanta'

const intialState = {
    profile: null,
    dataUser : null
}

function userState(state = intialState, action) {
    switch (action.type) {
        case USER_LOGIN :
            console.log(action,'akutyua')
            return {
                ...state,
                profile : action.payload
            }
        case USER_LOGOUT:
            return { ...state , profile: null }
        case SET_DATA_USER:
            return {...state , dataUser : action.payload}
        default: {
            return state
        }
    }
}

export default userState